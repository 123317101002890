import React, {useState, useLayoutEffect} from 'react';
import ModalCancelOnly from './ModalCancelOnly';

import chimera from '../chimera';
import LoadingSpinner from './LoadingSpinner';
import FormFieldMicro from './FormFieldMicro';

const SetActiveModal = ({customers, modalContext, onClose, activeDefault}) => {
    const [active, setActive] = useState(activeDefault ? activeDefault : 'Inactive');
    const [isLoading, setIsLoading] = useState(false);
    const [isFinished, setIsFinished] = useState(false);
    const [problems, setProblems] = useState([]);
    const [controller] = useState(new AbortController());
    const [signal] = useState(controller.signal);

    useLayoutEffect(() => {
        return () => {
            controller.abort();
        }
    }, []);

    const handleChange = (event) => {
        event.preventDefault();
        
        if(event.target.name === 'active') {
            setActive(event.target.value);
        }
    }

    const run = async(event) => {
        event.preventDefault();
        setIsLoading(true);
        setIsFinished(false);

        let newProblems = [];
        let toggleIds = [];

        const hasQbIntegration = customers.find(customer => customer.integrationIds.quickbooks) ? true : false;
        if(active === "Inactive" && hasQbIntegration) {
            let batch;
            try {
                batch = await chimera.callQuickBooksAPI(signal, '/api/qb/recurringtransactionbatch', 'POST', {
                    tag: 'all',
                    qbCustomerIds: customers.filter(customer => customer.integrationIds.quickbooks).map(customer => customer.integrationIds.quickbooks)
                })
            }
            catch(err) {
                console.error(err);
                alert('ERROR: An unhandled error has occurred while trying to fetch Recurring Transactions from QuickBooks. The process cannot continue.');
                return;
            }
            for(const customer of customers) {
                if(!customer.integrationIds.quickbooks || batch[customer.integrationIds.quickbooks.toString()].length === 0) {
                    toggleIds.push(customer._id);
                }
                else {
                    newProblems.push({
                        customerName: customer.displayName,
                        nTxns: customer.integrationIds.quickbooks ? batch[customer.integrationIds.quickbooks.toString()].length : 0
                    })
                }
            }
        }
        else {
            toggleIds = customers.map(customer => customer._id);
        }

        try {
            await chimera.callAPI(signal, '/api/customers/setactive', 'POST', {
                ids: toggleIds,
                active: active === 'Active'
            })
            setProblems(newProblems);
            setIsFinished(true);
            setIsLoading(false);
        }
        catch(err) {
            console.error(err);
            alert(`ERROR: An unhandled error occurred when toggling activity. The process cannot continue.`);
        }
    }

    return(
        <ModalCancelOnly backtrack context={modalContext} onClose={onClose}>
            <h2>Mark Customers as {active}</h2>
            {!isLoading ?
            <>
            {!isFinished ? 
            <>
                <h3>Are you sure?</h3>
                <div className="row">
                    <div className="col">
                        <FormFieldMicro
                            type="select"
                            name="active"
                            label="Choose"
                            value={active}
                            handleChange={handleChange}
                            options={[
                                {id: 'Active', value: "Active"}, // `id` is the value and `value` is the label. this is bad, please fix later
                                {id: 'Inactive', value: "Inactive"},
                            ]}
                            excludeNoneSelected
                            fit
                        />
                    </div>
                </div>
                <div className="row mt-1 mb-2">
                    <div className="col">
                        <button className="btn btn-primary" onClick={run}>
                            <i className="fas fa-arrow-right"/>&nbsp;Yes, Mark {active}
                        </button>
                    </div>
                </div>
            </>
            :
            <>
            {problems.length === 0 ? 
                <>
                <h3 className="text-success">Done!</h3>
                <p>The process completed successfully.</p>
                </>
            :
                <>
                <h3 className="text-danger">Attention!</h3>
                <p>The following customers could not be made inactive because they have Recurring Transactions in QuickBooks:</p>
                <ol className="text-start">
                    {problems.map((problem, i) => <li key={i}><strong>{problem.customerName}:</strong>&nbsp;{problem.nTxns} Recurring Transaction(s)</li>)}
                </ol>
                </>
            }
            </>
            }
            </>
            :
            <LoadingSpinner size={75} label={`Marking ${customers.length} Customer(s) as ${active}`}/>
            }
        </ModalCancelOnly>
    )
}

export default SetActiveModal;