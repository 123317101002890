import React, {useState, useEffect, useLayoutEffect, useContext} from 'react';

import chimera from '../../chimera';
import LoadingSpinner from '../LoadingSpinner';
import BannerContext, {BannerLog} from '../BannerLogContext';
import ReportTable from '../ReportTable';

const MicrosoftProductAuditBody = props => {
    const [phase, setPhase] = useState('start');
    const [report, setReport] = useState(null);
    const [spinnerLabel, setSpinnerLabel] = useState("");
    const [saveBtnLabel, setSaveBtnLabel] = useState("Save to Chimera");
    const [saveBtnIcon, setSaveBtnIcon] = useState("fas fa-floppy-disk");
    const [saveBtnDisabled, setSaveBtnDisabled] = useState(false);
    const [controller] = useState(new AbortController());
    const [signal] = useState(controller.signal);
    const banners = useContext(BannerContext);

    useLayoutEffect(() => {
        return () => {
            controller.abort();
        }
    }, []);

    useEffect(() => {
        if(phase.toLowerCase() === 'start') {
            setReport(null);
            setSaveBtnDisabled(false);
            setSaveBtnLabel('Save to Chimera');
            setSaveBtnIcon('fas fa-floppy-disk');
        }
    }, [phase]);

    useEffect(() => {
        if(report !== null) {
            setPhase('results');
        }
    }, [report]);

    const qbItemsForProduct = (product, customerName) => {
        return new Promise((resolve, reject) => {
            chimera.callQuickBooksAPI(signal, `/api/qb/items/sku/${product.sku}`)
            .then(items => {
                if(items.length > 1) {
                    // Exact match is a warning, fuzzy match can be handled
                    const exactMatches = items.filter(item => item.Sku === product.sku && !item.Name.toLowerCase().includes('annual'));
                    if(exactMatches.length > 1) {
                        // Do they all have the same price?
                        const testMatches = exactMatches.filter(match => match.UnitPrice === exactMatches[0].UnitPrice);
                        if(testMatches.length === exactMatches.length) {
                            resolve(exactMatches);
                        }
                        else {
                            let message = `SKU ${product.sku} is ambiguous and present on multiple QB items with different prices (${items.length} total) - QB item for SKU ${product.sku} cannot be determined and this product will be skipped`;
                            console.warn(message);
                            console.log(items);
                            reject(message);
                        }
                    }
                    else if(exactMatches.length === 0) {
                        reject(`No non-annual QB items have the exact SKU ${product.sku} (Pax8 product name: ${product.name}) - This product will be skipped`);
                    }
                    else {
                        resolve(exactMatches);
                    }
                }
                else if(items.length === 0) {
                    reject(`No QB items have SKU ${product.sku} (Pax8 product name: ${product.name}) (Subscribed by ${customerName}) - This product will be skipped`);
                }
                else {
                    resolve(items);
                }
            })
            .catch(e => { 
                if(e.name !== "AbortError") {
                    console.error(e);
                    reject("QuickBooks error: Request failed");
                }
                else {
                    reject("QuickBooks error: Request aborted");
                }
            });
        })
    }

    const quantitySold = (item, transactions) => {
        // TODO: This does not account for paused transactions. If they're paused, they're not being billed, unless
        // they are being used as a template. Perhaps another solution for templates may be in order someday.
        let qty = 0;
        for(const txn of transactions) {
            for(const line of txn.doc.Line) {
                if(line.DetailType === "SalesItemLineDetail") {
                    if(line.SalesItemLineDetail.ItemRef.value === item.Id) {
                        qty += line.SalesItemLineDetail.Qty;
                    }
                }
            }
        }
        return qty;
    }

    const difference = (collection) => {
        const qbTotal = collection.qty * collection.price;
        const pax8Total = collection.subscription.quantity * collection.subscription.price;
        return qbTotal - pax8Total;
    }

    const run = async(event) => {
        event.preventDefault();
        try {
            setPhase('loading');

            let collections = [];
            let alerts = [];
            setSpinnerLabel('Reading customers from Chimera...');
            const wheelersAccountNumbers = ['188092', '825050', '600178', '239584'];
            const allCustomers = await chimera.CommercialCustomer.getAll(signal);
            const customersWithoutQB = allCustomers.filter(c => c.integrationIds.pax8 && !c.integrationIds.quickbooks);
            const customers = allCustomers.filter(c => (c.integrationIds.pax8 && c.integrationIds.quickbooks) || (wheelersAccountNumbers.includes(c.accountNumber)));
            for(const customer of customersWithoutQB) {
                alerts.push({type: 'danger', header: 'O365 Subscriptions', message: `${customer.displayName} has a Pax8 integration, but no QB integration. They cannot be included in this report.`});
            }
            setSpinnerLabel('Reading Pax8 subscriptions...');
            const subscriptions = await chimera.callAPI(signal, '/api/pax8/subscriptions');
            setSpinnerLabel('Reading Recurring Transactions from QuickBooks...');
            const recurringTransactionsByCustomerID = await chimera.callAPI(signal, '/api/qb/recurringtransactionbatch', 'POST', {type: 'all', tag: 'all', qbCustomerIds: customers.map(c => c.integrationIds.quickbooks)});
            for(const sub of subscriptions) {
                if(sub.status === "Active") {
                    setSpinnerLabel(`Fetching Pax8 product with ID: ${sub.productId}`)
                    const product = await chimera.callAPI(signal, `/api/pax8/products/${sub.productId}`);
                    if(product.vendorName === "Microsoft") {
                        try {
                            if(sub.companyId.toString() === '4134b531-3b70-4a47-9d1b-522f2aa83e55') { // Wheeler's workaround
                                setSpinnerLabel(`Fetching QB item for product: ${product.name}`);
                                const qbItems = await qbItemsForProduct(product, 'Wheeler Enterprises');
                                setSpinnerLabel(`Finding items with SKU ${product.sku} in recurring transactions for Wheeler Enterprises...`);
                                for(const accountNumber of wheelersAccountNumbers) {
                                    const customer = customers.find(c => c.accountNumber === accountNumber);
                                    if(customer) {
                                        let collection = {
                                            subscription: sub,
                                            product: product,
                                            customer: customer,
                                            qty: 0,
                                            price: 0,
                                        }
                                        if(customer.integrationIds.quickbooks && recurringTransactionsByCustomerID[customer.integrationIds.quickbooks]) {
                                            for(const item of qbItems) {
                                                const quantity = quantitySold(item, recurringTransactionsByCustomerID[customer.integrationIds.quickbooks]);
                                                collection.qty += quantity;
                                            }
                                        }
                                        else {
                                            if(!customer.integrationIds.quickbooks) {
                                                alerts.push({type: 'warning', header: "0365 Subscriptions (Wheeler's Workaround)", message: `No QB integration for ${customer.displayName}`});
                                            }
                                            else {
                                                alerts.push({type: 'warning', header: "0365 Subscriptions (Wheeler's Workaround)", message: `No recurring transactions detected for ${customer.displayName}`});
                                            }
                                            continue;
                                        }
                                        collection.price = qbItems[0].UnitPrice;
                                        collections.push(collection);
                                    }
                                    else {
                                        alerts.push({type: 'warning', header: "O365 Subscriptions (Wheeler's Workaround)", message: `No Chimera Customer found with account number = ${accountNumber}; this is part of a hardcoded process and is unexpected. Please notify the developer.`});
                                    }
                                }
                            }
                            else { // Normal process
                                let customer = customers.find(c => c.integrationIds.pax8 === sub.companyId);
                                if(customer) {
                                    setSpinnerLabel(`Fetching QB item for product: ${product.name}`);
                                    const qbItems = await qbItemsForProduct(product, customer.displayName);
                                    let collection = {
                                        subscription: sub,
                                        product: product,
                                        customer: customer,
                                        qty: 0,
                                        price: 0,
                                    }
                                    setSpinnerLabel(`Finding items with SKU ${product.sku} in recurring transactions for ${customer.displayName}...`);
                                    for(const item of qbItems) {
                                        const quantity = quantitySold(item, recurringTransactionsByCustomerID[customer.integrationIds.quickbooks]);
                                        collection.qty += quantity;
                                    }
                                    collection.price = qbItems[0].UnitPrice;
                                    collections.push(collection);
                                }
                                else {
                                    try {
                                        setSpinnerLabel(`Reading Pax8 customer with ID: ${sub.companyId}`);
                                        const company = await chimera.callAPI(signal, `/api/pax8/companies/${sub.companyId}`);
                                        alerts.push({type: 'warning', header: 'O365 Subscriptions', message: `Pax8 Company ID ${sub.companyId} not found in any Chimera MSP customer (Pax8 company name: ${company.name})`});
                                    }
                                    catch(e) {
                                        if(e.name !== "AbortError") {
                                            console.error(e);
                                            alerts.push({type: 'warning', header: 'O365 Subscriptions', message: `Pax8 Company ID ${sub.companyId} not found in any Chimera MSP customer`});
                                        }
                                    }
                                }
                            }
                        }
                        catch(e) {
                            const message = e.toString();
                            let type = 'warning';
                            if(message.startsWith('No QB items have SKU')) {
                                type = 'danger';
                            }
                            alerts.push({type, header: 'O365 Subscriptions', message});
                        }
                    }
                }
            }

            let bannerAlerts = [];
            for(const alert of alerts) {
                if(!bannerAlerts.find(a => a.message === alert.message)) {
                    bannerAlerts.push(alert);
                }
            }
            bannerAlerts.sort((a, b) => a.message < b.message ? -1 : 1);
            

            const cols = [
                "Customer Name",
                "Product",
                "SKU",
                "Pax8",
                "QB",
                "Difference",
            ]
            let rows = [];
    
            setSpinnerLabel("Compiling report...");

            collections.sort((a, b) => a.customer.displayName < b.customer.displayName ? -1 : 1);
            for(const collection of collections) {
                rows.push({
                    "Customer Name": collection.customer.displayName,
                    "Product": collection.product.name,
                    "SKU": collection.product.sku,
                    "Pax8": `${collection.subscription.quantity} @ ${chimera.dollarStr(collection.subscription.price)}`,
                    "QB": `${collection.qty} @ ${chimera.dollarStr(collection.price)}`,
                    "Difference": difference(collection)
                })
            }

            setReport({type: "MicrosoftProductAudit", cols: cols, rows: rows, etc: bannerAlerts.length > 1 ? bannerAlerts : null});
        }
        catch(err) {
            if(err.name !== "AbortError") {
                console.error(err);
                banners.addBanner('danger', `An unhandled exception occurred. The report cannot continue.`, 'Error');
            }
        }
    }

    const saveReport = (event) => {
        event.preventDefault();
        setSaveBtnDisabled(true);
        setSaveBtnIcon("fas fa-spinner");
        setSaveBtnLabel("Saving...");
        chimera.callAPI(signal, '/api/reporttables', 'POST', report)
        .then(_ => {
            setSaveBtnIcon("fas fa-check");
            setSaveBtnLabel("Saved!");
        })
        .catch(err => {
            if(err.name !== "AbortError") {
                console.error(err);
                banners.addBanner('danger', 'An error occurred and the report could not be saved', 'Error');
            }
            setSaveBtnDisabled(false);
            setSaveBtnIcon("fas fa-floppy-disk");
            setSaveBtnLabel("Save to Chimera");
        })
    }

    const phaseStart = () => {
        return <button className="btn btn-primary" onClick={run}>
            <i className="fas fa-play"/>&nbsp;Run Report
        </button>;
    }

    const phaseLoading = () => {
        return <LoadingSpinner size={75} label={spinnerLabel}/>;
    }

    const phaseResults = () => {
        return <>
            <button className="btn btn-primary mb-2" onClick={saveReport} disabled={saveBtnDisabled}>
                <i className={saveBtnIcon}/>
                &nbsp;{saveBtnLabel}
            </button>
            <ReportTable report={report}/>
        </>;
    }

    const presentPhase = (phase) => {
        switch(phase.toLowerCase()) {
            case 'start':
                return phaseStart();
            case 'loading':
                return phaseLoading();
            case 'results':
                return phaseResults();
            default:
                return null;
        }
    }

    return (
        <div>
            {presentPhase(phase)}
        </div>
    )
}

const MicrosoftProductAudit = props => {
    return (
        <BannerLog>
            <MicrosoftProductAuditBody/>
        </BannerLog>
    )
}

export default MicrosoftProductAudit;