import React, {useState, useRef, useLayoutEffect, useContext} from "react";
import BannerContext from './BannerLogContext';
import { RenderAlert } from "./BannerLogContext";
import chimera from "../chimera";

const writeReportTableCell = (type) => {
    if(type === "EndpointsLastSeen") {
        const func = (row, col, i) => {
            if(col.includes("Last Seen")) {
                const date = new Date(row[col]);
                return <td key={i}>{date.toLocaleString()}</td>
            }
            else if(col === "Computer Name") {
                const url = `https://gocbit.syncromsp.com/customer_assets/${row.syncroId}`;
                return <td key={i}><a href={url} target="_blank" rel="noopener noreferrer">{row[col]}</a></td>
            }
            else {
                return <td key={i}>{row[col]}</td>
            }
        }
        return func;
    }
    else if(type === "CustomersAdded") {
        const func = (row, col, i) => {
            if(col === "Customer Name") {
                const url = `https://bms.gocbit.com/customers/${row.acctNum}`;
                return <td key={i}><a href={url} target="_blank" rel="noopener noreferrer">{row[col]}</a></td>
            }
            else if(col === "Date Created") {
                const date = new Date(row[col]);
                return <td key={i}>{date.toLocaleString()}</td>
            }
            else {
                return <td key={i}>{row[col]}</td>
            }
        }
        return func;
    }
    else if(type === "VoIPDiscrepancy") {
        const func = (row, col, i) => {
            if(col.endsWith('?')) {
                return <td key={i}><i className={`fas fa-${row[col] ? 'check' : 'ban'} text-${row[col] ? 'success' : 'danger'}`}/></td>
            }
            else {
                return <td key={i}>{row[col]}</td>
            }
        }
        return func;
    }
    else if(type === "MicrosoftProductAudit") {
        const func = (row, col, i) => {
            if(col === "Difference") {
                return <td key={i} className={row[col] > 0 ? "text-success" : "text-danger"}>{chimera.dollarStr(row[col])}</td>
            }
            else {
                return <td key={i}>{row[col]}</td>
            }
        }
        return func;
    }
    else if(type === "Win11Compatibility") {
        const func = (row, col, i) => {
            if(col === "Link") {
                return <td key={i}><a href={`${row[col]}`} target="_blank" rel="noopener noreferrer"><i className="fas fa-up-right-from-square"/></a></td>
            }
            else {
                return <td key={i}>{row[col]}</td>
            }
        }
        return func;
    }
    else return undefined;
}

const reportTableEtcPos = (type) => {
    switch(type) {
        default:
            return 'top';
    }
}

const MicrosoftProductAuditTotalRow = ({report}) => {
    let total = 0;
    for(const row of report.rows) {
        total += row["Difference"];
    }
    return <tr>
        <td colSpan={5} className="text-end"><strong>Total Difference:</strong></td>
        <td className={total > 0 ? "text-success" : "text-danger"}>{chimera.dollarStr(total)}</td>
    </tr>
}

const reportTableTotalRow = (type) => {
    switch(type) {
        case "MicrosoftProductAudit":
            return (report) => 
                <>
                {report ? 
                    <MicrosoftProductAuditTotalRow report={report}/>
                :null}
                </>
        default:
            return null
    }
}

const EndpointsLastSeenEtc = props => {
    const [btnIcon, setBtnIcon] = useState("fas fa-download");
    const [btnLabel, setBtnLabel] = useState("Download as .xlsx");
    const [btnDisabled, setBtnDisabled] = useState(false);

    const downloadEtc = async(event) => {
        event.preventDefault();
        setBtnIcon("fas fa-spinner");
        setBtnDisabled(true);
        setBtnLabel("Downloading...");
        try {
            const headers = [
                {
                    string: "Computer Name",
                    key: "computerName"
                },
                {
                    string: "Customer",
                    key: "customer"
                },
                {
                    string: "In Syncro?",
                    key: "inSyncro"
                },
                {
                    string: "In Bitdefender?",
                    key: "inBitdefender"
                }
            ]
            const data = props.etc.map(item => {
                return {
                    computerName: (item.vendor === "syncro" ? {link: {href: `https://gocbit.syncromsp.com/customer_assets/${item.endpointId}`, text: item.computerName}} : {string: item.computerName}),
                    customer: {string: item.customerName},
                    inSyncro: {bool: item.vendor === "syncro"},
                    inBitdefender: {bool: item.vendor === "bitdefender"}
                }
            })
            const blob = await chimera.callAPI(undefined, '/api/xlsx', 'POST', {
                dataSets: [
                    {
                        worksheet: "EndpointsLastSeen",
                        headers: headers,
                        data: data
                    }
                ]
            }, 'blob');
            const link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = `EndpointsLastSeen_NoMatches.xlsx`;
            link.click();
            setBtnIcon("fas fa-download");
            setBtnDisabled(false);
            setBtnLabel("Done!");
        }
        catch(e) {
            console.error(e);
            setBtnIcon("fas fa-download");
            setBtnDisabled(false);
            setBtnLabel("Error");
        }
    }

    return (
        <div id="etcAccordion">
            <div className="card mb-2">
                <a data-bs-toggle="collapse" href="#reportTableEtc">
                    <div className="card-header bg-warning">
                        <span className="btn">More</span>
                    </div>
                </a>
                <div id="reportTableEtc" className="collapse" data-bs-parent="etcAccordion">
                    <div className="card-body text-start">
                        <button className="btn btn-success" onClick={downloadEtc} disabled={btnDisabled}>
                            <i className={btnIcon}/>
                            &nbsp;{btnLabel}
                        </button>
                        <ol>
                            {props.etc.map(item => <li>
                                <span>No match was found for {item.vendor[0].toUpperCase() + item.vendor.substring(1)} endpoint "{item.vendor === "syncro" ? <a href={`https://gocbit.syncromsp.com/customer_assets/${item.endpointId}`} target="_blank" rel="noopener noreferrer">{item.computerName}</a> : item.computerName}" for <a href={`/customers/${item.customerAcctNumber}`} target="_blank">{item.customerName}</a></span>
                            </li>)}
                        </ol>
                    </div>
                </div>
            </div>
        </div>
    )
}

const CustomersAddedEtc = props => {
    return (
        <div id="etcAccordion">
            <div className="card mb-2">
                <a data-bs-toggle="collapse" href="#reportTableEtc">
                    <div className="card-header bg-warning">
                        <span className="btn">More Info</span>
                    </div>
                </a>
                <div id="reportTableEtc" className="collapse" data-bs-parent="etcAccordion">
                    <div className="card-body text-start">
                        <ol>
                            {props.etc.map(item => <li>
                                {item}
                            </li>)}
                        </ol>
                    </div>
                </div>
            </div>
        </div>
    )
}

const MicrosoftProductAuditEtc = props => {
    return(
        <div id="etcAccordion">
            <div className="card mb-2">
                <a data-bs-toggle="collapse" href="#reportTableEtc">
                    <div className="card-header bg-warning">
                        <span className="btn">Errors</span>
                    </div>
                </a>
                <div id="reportTableEtc" className="collapse" data-bs-parent="etcAccordion">
                    <div className="card-body text-start">
                        {props.etc.map((alert, i) => <RenderAlert type={alert.type} header={alert.header} message={alert.message} key={i}/>)}
                    </div>
                </div>
            </div>
        </div>
    )
}

const WorkstationEndpointAuditEtc = props => {
    return(
        <div id="etcAccordion">
            <div className="card mb-2">
                <a data-bs-toggle="collapse" href="#reportTableEtc">
                    <div className="card-header bg-warning">
                        <span className="btn">Warnings</span>
                    </div>
                </a>
                <div id="reportTableEtc" className="collapse" data-bs-parent="etcAccordion">
                    <div className="card-body text-start">
                        {props.etc.map((alert, i) => <RenderAlert type="warning" header="Warning" message={alert} key={i}/>)}
                    </div>
                </div>
            </div>
        </div>
    )
}

const reportTableWriteEtc = (type) => {
    switch(type) {
        case "EndpointsLastSeen":
            return (etc) => 
                <>
                {etc ? 
                    <EndpointsLastSeenEtc etc={etc}/>
                :null}
                </>
        case "CustomersAdded":
            return (etc) => 
                <>
                {etc ?
                    <CustomersAddedEtc etc={etc}/>
                :null}
                </>
        case "MicrosoftProductAudit":
            return (etc) => 
                <>
                {etc ? 
                    <MicrosoftProductAuditEtc etc={etc}/>
                :null}
                </>
        case "WorkstationEndpointAudit":
            return (etc) => 
                <>
                {etc ? 
                    <WorkstationEndpointAuditEtc etc={etc}/>
                :null}
                </>
        default:
            return undefined;
    }
}

const ReportTable = ({report}) => {
    const [downloadBtnIcon, setDownloadBtnIcon] = useState("fas fa-download");
    const [downloadBtnLabel, setDownloadBtnLabel] = useState("Download as .xlsx");
    const [downloadBtnDisabled, setDownloadBtnDisabled] = useState(false);
    const [controller] = useState(new AbortController());
    const [signal] = useState(controller.signal);
    const tableRef = useRef();
    const banners = useContext(BannerContext);

    useLayoutEffect(() => {
        return () => {
            controller.abort();
        }
    }, []);

    const _writeCell = (row, col, i) => {
        if(writeReportTableCell(report.type)) return writeReportTableCell(report.type)(row, col, i);
        return <td key={i}>{row[col]}</td>
    }

    const _writeEtc = (etc) => {
        if(reportTableWriteEtc(report.type)) return reportTableWriteEtc(report.type)(etc);
        return null;
    }

    const download = async(event) => {
        event.preventDefault();
        setDownloadBtnIcon("fas fa-spinner");
        setDownloadBtnLabel("Downloading...");
        setDownloadBtnDisabled(true);
        try {
            const headers = report.cols.map(col => {
                return {
                    string: col,
                    key: col
                }
            })
            let data = [];
            const rows = tableRef.current.querySelectorAll("tbody tr");
            const validDate = (str) => {
                if(report.type === "Win11Compatibility") return false; // bandaid fix for Device Name values being turned into dates
                const date = new Date(str);
                return date.toString() !== "Invalid Date";
            }
            const dollarFormat = (str) => {
                const regex = /^(- )*\$[0-9\,]+\.[0-9]{2}$/g;
                return str.match(regex) ? true : false;
            }
            for(const row of rows) {
                const tds = row.querySelectorAll('td');
                let obj = {};
                if(tds.length < report.cols.length) {
                    // Assume this is a "totals" row
                    continue;
                }
                let colIndex = 0;
                for(const col of report.cols) {
                    obj[col] = {};
                    if(tds[colIndex].innerHTML.startsWith('<a') && tds[colIndex].innerHTML.endsWith('</a>')) {
                        // Contains a link.
                        const anchor = tds[colIndex].querySelector('a');
                        obj[col].link = {href: anchor.href, text: anchor.innerText ? anchor.innerText : '(Link)'};
                    }
                    else if(validDate(tds[colIndex].innerText)) {
                        // Contains a date.
                        const date = new Date(tds[colIndex].innerText);
                        obj[col].date = date;
                    }
                    else if(dollarFormat(tds[colIndex].innerText)) {
                        const num = parseFloat(tds[colIndex].innerText.replace(/[ \$\,]/g, ''));
                        obj[col].number = num;
                        obj[col].style = {
                            numberFormat: "$#,##0.00; ($#,##0.00); -"
                        }
                        if(num < 0) {
                            obj[col].style.font = {
                                color: '#FF0000'
                            }
                        }
                    }
                    else if(tds[colIndex].innerHTML.startsWith('<i') && tds[colIndex].innerHTML.endsWith('</i>')) {
                        // Contains an icon.
                        let tf = tds[colIndex].children[0].classList.contains('fa-check');
                        obj[col].bool = tf;
                        obj[col].style = {
                            font: {
                                color: tf ? '#006100' : '#9C0006'
                            },
                            fill: {
                                type: 'pattern',
                                patternType: 'solid',
                                fgColor: tf ? '#C6EFCE' : '#FFC7CE'
                            }
                        }
                    }
                    else {
                        obj[col].string = tds[colIndex].innerText;
                    }
                    colIndex++;
                }
                data.push(obj);
            }
            const blob = await chimera.callAPI(signal, '/api/xlsx', 'POST', {
                dataSets: [
                    {
                        worksheet: report.type,
                        headers: headers,
                        data: data
                    }
                ]
            }, 'blob');
            const link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            const date = report.createdAt ? new Date(report.createdAt) : new Date();
            link.download = `${report.type} ${date.toISOString().substring(0,10)}.xlsx`;
            link.click();
            setDownloadBtnIcon("fas fa-check");
            setDownloadBtnLabel("Done!");
            setDownloadBtnDisabled(false);
        }
        catch(e) {
            console.error(e);
            if(e.name !== "AbortError") {
                banners.addBanner('danger', 'Failed to generate the Excel download', 'Error');
                setDownloadBtnIcon("fas fa-download");
                setDownloadBtnLabel("Download as .xlsx");
                setDownloadBtnDisabled(false);
            }
        }
    }

    return (
        <div className="d-flex flex-column overflow-auto">
        {reportTableEtcPos(report.type) === "top" ? <>{_writeEtc(report.etc)}</> : null}
        <button className="btn btn-success w-fit mb-2" onClick={download} disabled={downloadBtnDisabled}>
            <i className={downloadBtnIcon}/>
            &nbsp;{downloadBtnLabel}
        </button>
        <table className="table table-striped table-bordered" ref={tableRef}>
            <thead>
                <tr>
                    {report.cols.map((col, i) => <th key={i}>{col}</th>)}
                </tr>
            </thead>
            <tbody>
                {report.rows.map((row, i) => <tr key={i}>
                    {report.cols.map((col, j) => _writeCell(row, col, j))}
                </tr>)}
                {reportTableTotalRow(report.type) ? reportTableTotalRow(report.type)(report) : null}
            </tbody>
        </table>
        {reportTableEtcPos(report.type) === "bottom" ? <>{_writeEtc(report.etc)}</> : null}
        </div>
    )
}

export default ReportTable;