import React, {useContext} from 'react';
import ModalContext from '../../ModalContext';
import ExportModal from '../../ExportModal';
import AlertsContext from '../../AlertsContext';
import Tooltip from '../../Tooltip';
import ObjectTable from '../../ObjectTable';
import ModalCancelOnly from '../../ModalCancelOnly';
import ButtonOptionList from '../../ButtonOptionList';
import ConvertTypeModal from '../ConvertTypeModal';
import VideoOrderFormModal from './VideoOrderFormModal';

/**
 * 
 * @param {Array} orders The list of orders to display
 * @param {Function} setPrders A function that sets the orders in the parent
 * @param {Function} clickedOrder A function that takes the clicked order as an argument
 * @param parentBanners The parent's `BannerContext`
 */
const VideoOrdersTable = ({orders, setOrders, clickedOrder, parentBanners}) => {
    const modaling = useContext(ModalContext);
    const alerts = useContext(AlertsContext);

    const openNewOrderForm = (type) => {
        modaling.setModal(null);
        modaling.setModal(<VideoOrderFormModal type={type} parentBanners={parentBanners} onClose={() => {setOrders(null); alerts.reload()}}/>)
    }

    const openNewOrderOptions = (_) => {
        const modal = <ModalCancelOnly context={modaling}>
            <ButtonOptionList options={[
                {
                    text: "New Customer",
                    icon: "fas fa-plus",
                    func: (e) => {
                        e.preventDefault();
                        openNewOrderForm('New Customer');
                    }
                }
            ]}/>
            <hr/>
            <div className="row mb-3 mt-3">
                <h4>
                    For existing customers:
                </h4>
            </div>
            <ButtonOptionList options={[
                {
                    text: "Modify",
                    icon: "fas fa-screwdriver-wrench",
                    func: (e) => {
                        e.preventDefault();
                        openNewOrderForm('Modify Service');
                    }
                },
                {
                    text: "Disconnect",
                    icon: "fas fa-minus",
                    func: (e) => {
                        e.preventDefault();
                        openNewOrderForm('Disconnect');
                    }
                }
            ]}/>
        </ModalCancelOnly>
        modaling.setModal(modal);
    }

    /** TODO: Implement exporting for VideoOrders */
    const openExportModal = (selectedObjects) => {
        modaling.setModal(<ExportModal objects={selectedObjects} model="videoOrder"/>);
    }

    const openConvertModal = (selectedObjects) => {
        const callback = () => {
            setOrders(null);
            modaling.setModal(null);
        }
        modaling.setModal(<ConvertTypeModal orderClass="video" orders={selectedObjects} modalContext={modaling} bannerContext={parentBanners} callback={callback}/>);
    }

    const statusColor = (status) => {
        switch(status) {
            case "New":
            case "Estimate":
                return "info";
            case "Ordered":
                return "success";//"warning";
            case "Backordered":
                return "dark";
            case "Ready to Pay Vendor":
            case "Pending":
                return "warning";//"secondary";
            case "Disconnect":
            case "Cancelled":
                return "danger";
            case "Received":
                return "primary";
            case "Completed":
                return "secondary";//"success";
            default:
                return "light";
        }
    }

    const estimateStatusColor = (status) => {
        switch(status) {
            case "Pending":
                return "info";
            case "Requested":
                return "primary";
            case "Sent":
                return "warning";
            case "Approved":
                return "success";
            case "Denied":
                return "danger";
            default:
                return "light";
        }
    }

    const typeColor = (type) => {
        switch(type) {
            case "New Customer":
                return "info";
            case "Modify Service":
                return "warning";
            case "Disconnect":
                return "danger";
            default:
                return "light";
        }
    }

    const abbreviateType = (type) => {
        switch(type) {
            case "New Customer":
                return "New";
            case "Modify Service":
                return "Mod.";
            case "Add Location":
                return "Order";
            case "Disconnect":
                return "Disco.";
            default:
                return "(None)";
        }
    }

    const renderFlag = (obj) => {
        // since the reason is not dynamic, we can load it faster by simply reading the notes
        let isFlagged = false;
        for(const note of obj.notes) {
            if(note.flagged) {
                isFlagged = true;
                break;
            }
        }
        return isFlagged ? <>&nbsp;<Tooltip text="(See Notes)"><i className="fas fa-flag text-danger"/></Tooltip></> : null;
    }

    return (
        <ObjectTable 
            id="videoOrdersTable"
            cols={[
                {
                    label: 'SO #', 
                    sort: (a, b) => a.number < b.number ? -1 : 1,
                    render: (obj) => <>{obj.number}{renderFlag(obj)}</>
                },
                {
                    label: 'Type', 
                    sort: (a, b) => {
                        const values = {
                            'New Customer': 0,
                            'Add Location': 1,
                            'Modify Service': 2,
                            'Disconnect': 3,
                        }
                        return values[`${a.type}`] < values[`${b.type}`] ? -1 : 1;
                    },
                    render: (obj) => abbreviateType(obj.type),
                    color: (obj) => typeColor(obj.type)
                },
                {
                    label: 'Estimate Status',
                    sort: (a, b) => {
                        const values = {
                            'NOT SET': 0,
                            'Pending': 1,
                            'Requested': 2,
                            'Sent': 3,
                            'Approved': 4,
                            'Denied': 5,
                        }
                        return values[`${a.estimateStatus}`] < values[`${b.estimateStatus}`] ? -1 : 1;
                    },
                    render: (obj) => obj.estimateStatus,
                    color: (obj) => estimateStatusColor(obj.estimateStatus)
                },
                {
                    label: 'Order Status',
                    sort: (a, b) => {
                        const values = {
                            'NOT SET': 0,
                            'New': 1,
                            'Estimate': 2,
                            'Ready to Pay Vendor': 3,
                            'Approved': 4,
                            'Ordered': 5,
                            'Pending': 6,
                            'Backordered': 7,
                            'Cancelled': 8,
                            'Received': 9,
                            'Completed': 10,
                            'Disconnect': 11
                        }
                        return values[`${a.status}`] < values[`${b.status}`] ? -1 : 1;
                    },
                    render: (obj) => obj.status,
                    color: (obj) => statusColor(obj.status)
                },
                {
                    label: 'Customer Name',
                    sort: (a, b) => a.customer.name < b.customer.name ? -1 : 1,
                    render: (obj) => obj.customer.name
                },
            ]}
            objects={orders}
            actions={[
                {label: 'Export', func: openExportModal},
                {label: 'Convert Type...', func: openConvertModal}
            ]}
            btns={[
                {label: 'Video Order', func: openNewOrderOptions}
            ]}
            filters={[
                {label: 'Default', value: 'Default', func: (order) => order.status !== "Completed" && order.status !== "Cancelled"},
                {label: 'Completed', value: 'Completed', func: (order) => order.status === "Completed"},
                {label: 'Cancelled', value: 'Cancelled', func: (order) => order.status === "Cancelled"},
            ]}
            onClicked={clickedOrder}
            search
            paginated
            defaultSortByColName="Status"
            defaultFilterSetting="Default"
            defaultSortAscending={true}
        />
    )
}

export default VideoOrdersTable