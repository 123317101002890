import React, {useState, useContext} from 'react';

import FormField from './FormField';
import PocDisplay from './PocDisplay';
import { NewCustomerBody } from './NewCustomer';
import BannerContext, { BannerLog } from './BannerLogContext';
import Modal from './Modal';
import ModalContext from './ModalContext';
import chimera from '../chimera';
import LoadingSpinner from './LoadingSpinner';
import AddressDisplay from './AddressDisplay';

const GenericLeadFormBody = props => {
    const [lead, setLead] = useState(props.lead ? props.lead : {
        displayName: "",
        businessName: "",
        poc: {
            firstName: "",
            lastName: "",
            email: "",
            phone: ""
        },
        serviceAddress: {
            street1: "",
            street2: "",
            city: "",
            county: "",
            state: "WA",
            zip: ""
        },
        shippingAddress: {
            street1: "",
            street2: "",
            city: "",
            county: "",
            state: "WA",
            zip: ""
        },
        billingAddress: {
            street1: "",
            street2: "",
            city: "",
            county: "",
            state: "WA",
            zip: ""
        },
        shippingSameAsService: false,
        billingSameAsService: false,
        status: "Open"
    });
    const [saveBtnLabel, setSaveBtnLabel] = useState("Save SO");
    const [saveBtnIcon, setSaveBtnIcon] = useState("fas fa-floppy-disk");
    const [saveBtnDisabled, setSaveBtnDisabled] = useState(true);
    const banners = useContext(BannerContext);
    const modaling = useContext(ModalContext);

    const handleChange = (event) => {
        if(event.target.type !== "checkbox") event.preventDefault();
        const name = event.target.name;
        const value = event.target.type === "checkbox" ? event.target.checked : event.target.value;
        
        let newLead = JSON.parse(JSON.stringify(lead));

        if(name === "shippingSameAsService") {
            if(event.target.checked) {
                const newAddress = newLead.serviceAddress;
                newLead.shippingAddress = newAddress;
                newLead.shippingSameAsService = true;
            }
            else {
                newLead.shippingSameAsService = false;
            }
        }
        else if(name === "billingSameAsService") {
            if(event.target.checked) {
                const newAddress = newLead.serviceAddress;
                newLead.billingAddress = newAddress;
                newLead.billingSameAsService = true;
            }
            else {
                newLead.billingSameAsService = false;
            }
        }
        else if(event.target.type === "number") {
            chimera.setAttr(newLead, name, parseFloat(value));
        }
        else {
            if(name.toLowerCase().includes("phone")) {
                chimera.setAttr(newLead, name, value.replace(/\D/g, ''));
            }
            else {
                chimera.setAttr(newLead, name, value);
            }
        }

        setLead(newLead);
        setSaveBtnDisabled(false);
        setSaveBtnIcon("fas fa-floppy-disk");
        setSaveBtnLabel("Save SO");
    }

    const trimOnBlur = (event) => {
        handleChange({
            target: {
                type: "string",
                name: event.target.name,
                value: event.target.value.trim()
            },
            preventDefault: () => {}
        })
    }

    const handleSubmit = async(event) => {
        event.preventDefault();

        // Validate lead as customer first
        const customer = {
            displayName: lead.displayName,
            businessName: lead.businessName,
            serviceTypes: {
                msp: false,
                internet: false,
                voip: false,
                video: false
            },
            serviceAddress: lead.serviceAddress,
            billingAddress: lead.billingAddress,
            shippingAddress: lead.shippingAddress,
            poc: lead.poc,
            taxExempt: false,
            endpointPackages: {
                servers: 0,
                workstations: {
                    nPlat: 0,
                    nGold: 0,
                    nSilv: 0
                }
            },
            billingPocs: [{firstName: "", lastName: "", email: "", phone: ""}],
            notificationEmails: [""],
            technical: {
                networkSummary: "",
                cids: [],
                ips: [],
                gateways: [],
                subnetMasks: [],
                cpeIps: [],
                cpes: [],
                cpes: [],
                pocs: [],
                pops: [],
                voipLines: 1,
                idrac: false,
                demarc: ""
            },
            standardRate: 75.0,
            afterHoursRate: 75.0,
            standing: "good"
        }

        const createdCustomerCallback = async(createdCustomer) => {
            modaling.setModal(null);
            const choices = [
                {
                    btnInner: <span><i className="fas fa-check"/>&nbsp;Close</span>,
                    btnColor: "secondary",
                    func: (event) => {
                        event.preventDefault();
                        modaling.setModal(null);
                        if(props.setLeads) props.setLeads(null);
                    }
                },
                {
                    btnInner: <span><i className="fas fa-arrow-right"/>&nbsp;Go to Customer</span>,
                    btnColor: "success",
                    func: (event) => {
                        event.preventDefault();
                        window.open(`/customers/${createdCustomer.accountNumber}`, '_self');
                    }
                }
            ]
            const modal = <Modal choices={choices} dismiss={choices[0].func}>
                <h3>Success!</h3>
                <p>The new customer <strong>{createdCustomer.displayName}</strong> has been created.</p>
            </Modal>
            modaling.setModal(modal);
        }

        // Load New Customer form with Lead info pre-loaded
        // TODO: Highlighted fields are hard-coded. That will probably need to change for future leads.
        const controller = new AbortController();
        const signal = controller.signal;
        const dismiss = (event) => {
            event.preventDefault();
            controller.abort();
            modaling.setModal(null);
        }
        const modal = <Modal choices={[]} dismiss={dismiss}>
            <BannerLog>
                <NewCustomerBody customer={customer} controller={controller} signal={signal} createdCustomerCallback={createdCustomerCallback}/>
            </BannerLog>
        </Modal>
        modaling.setModal(modal);
    }
    
    const handleSave = (event) => {
        event.preventDefault();
        setSaveBtnDisabled(true);
        setSaveBtnIcon("fas fa-spinner");
        setSaveBtnLabel("Saving...");
        if(lead._id) {
            chimera.callAPI(undefined, `/api/leads/type/generic/${lead._id}`, 'PUT', lead)
            .then(newLead => {
                banners.addBanner('info', 'Successfully saved changes!');
                setLead(newLead);
                setSaveBtnIcon('fas fa-check');
                setSaveBtnLabel("Saved!");
            })
            .catch(err => {
                console.log(err);
                banners.addBanner('danger', 'Failed to save changes', 'Error');
                setSaveBtnDisabled(false);
                setSaveBtnIcon("fas fa-floppy-disk");
                setSaveBtnLabel("Save SO");
            })
        }
        else {
            chimera.callAPI(undefined, `/api/leads/type/generic`, 'POST', lead)
            .then(newLead => {
                banners.addBanner('info', 'Successfully saved service order!');
                setLead(newLead);
                setSaveBtnIcon('fas fa-check');
                setSaveBtnLabel("Saved!");
            })
            .catch(err => {
                console.log(err);
                banners.addBanner('danger', 'Failed to save service order', 'Error');
                setSaveBtnDisabled(false);
                setSaveBtnIcon("fas fa-floppy-disk");
                setSaveBtnLabel("Save SO");
            })
        }
    }

    return (
        <>
        {lead === null ?
        <LoadingSpinner size={75}/>
        :
            <>
            <FormField
                type="select"
                options={[
                    {id: "Open", value: "Open"},
                    {id: "Closed", value: "Closed"},
                ]}
                name="status"
                label="Status"
                value={lead.status}
                description="Is this SO open or closed?"
                handleChange={handleChange}
                excludeNoneSelected
            />
            <FormField
                type="text"
                name="displayName"
                label="Display Name"
                value={lead.displayName}
                description="The name shown for all display purposes."
                handleChange={handleChange}
                onBlur={trimOnBlur}/>
            <FormField
                type="text"
                name="businessName"
                label="Business Name"
                value={lead.businessName}
                description="The name shown for legal purposes."
                handleChange={handleChange}
                onBlur={trimOnBlur}/>
            <FormField
                type="component"
                name="poc"
                label="POC"
                description="Name and contact details for the primary Point of Contact.">
                    <PocDisplay poc={lead.poc} basePath="poc" baseValue={lead} onChange={handleChange} isEditing onBlur={trimOnBlur}/>
            </FormField>
            <FormField
                type="component"
                name="serviceAddress"
                label="Service Address"
                description="The physical location of the customer's site.">
                    <AddressDisplay addr={lead.serviceAddress} basePath="serviceAddress" baseValue={lead} onChange={handleChange} isEditing onBlur={trimOnBlur}/>
            </FormField>
            <FormField
                type="component"
                name="shippingAddress"
                label="Shipping Address"
                description="The shipping/mailing address for the customer.">
                    <AddressDisplay addr={lead.shippingAddress} basePath="shippingAddress" baseValue={lead} onChange={handleChange} isEditing onBlur={trimOnBlur} sameAsLabel="Same As Service Address" sameAsPath="shippingSameAsService" sameAs={lead.shippingSameAsService}/>
            </FormField>
            <FormField
                type="component"
                name="billingAddress"
                label="Billing Address"
                description="The billing address for the customer.">
                    <AddressDisplay addr={lead.billingAddress} basePath="billingAddress" baseValue={lead} onChange={handleChange} isEditing onBlur={trimOnBlur} sameAsLabel="Same As Service Address" sameAsPath="billingSameAsService" sameAs={lead.billingSameAsService}/>
            </FormField>
            <FormField
                type="textarea"
                name="description"
                label="Description"
                value={lead.description}
                description="Anything else that needs to be noted about the customer."
                handleChange={handleChange}
                onBlur={trimOnBlur}
            />
            <div className="row mt-1">
                <div className="col">
                    <button className="btn btn-success" onClick={handleSave} disabled={saveBtnDisabled}>
                        <i className={saveBtnIcon}/>&nbsp;{saveBtnLabel}
                    </button>
                </div>
            </div>
            <div className="row mt-1">
                <div className="col">
                    <button className="btn btn-primary" onClick={handleSubmit}>
                        <i className="fas fa-arrow-right"/>&nbsp;Create Customer
                    </button>
                </div>
            </div>
            </>
        }
        </>
    )
}

const GenericLeadForm = props => {
    return (
        <div className="container pb-5">
            <h1>{props.lead ? "Edit" : "Add"} Service Order</h1>
            <BannerLog>
                <GenericLeadFormBody {...props}/>
            </BannerLog>
        </div>
    )
}

export default GenericLeadForm;