import {BannerLog} from '../../BannerLogContext';
import Modal from '../../Modal';
import VideoOrderForm from './VideoOrderForm';
import useFormModal from '../../../hooks/useFormModal';

const VideoOrderFormModal = ({type, order, parentBanners, parentSetSavedObj, onClose, removeOrderById}) => {
    const {workingObj, setWorkingObj, setModified, choices, ref} = useFormModal({
        initialState: order,
        apiBase: '/api/videoorders',
        noun: 'Video Order',
        removeById: removeOrderById,
        onClose,
        parentBanners
    })

    return (
        <Modal choices={choices} dismiss={choices[0].func}>
            <BannerLog>
                <VideoOrderForm ref={ref} type={type} order={workingObj} parentSetModified={setModified} parentSetProject={setWorkingObj} parentSetSavedObj={parentSetSavedObj}/>
            </BannerLog>
        </Modal>
    )
}

export default VideoOrderFormModal;