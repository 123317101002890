import React, {useState, useEffect, useContext} from 'react';

import chimera from '../../chimera';
import BannerContext, { BannerLog } from '../BannerLogContext';
import ObjectTable from '../ObjectTable';
import ModalContext from '../ModalContext';
import ProjectFormModal from './ProjectFormModal';
import AlertsContext from '../AlertsContext';
import Tooltip from '../Tooltip';
import useSmoothFormModal from '../../hooks/useSmoothFormModal';
import UserContext from '../../UserContext';

const ProjectsPageBody = ({}) => {
    const [projects, setProjects] = useState(null);
    const {setSavedObj: setSavedProject, removeById} = useSmoothFormModal(projects, setProjects);
    const banners = useContext(BannerContext);
    const modaling = useContext(ModalContext);
    const alerts = useContext(AlertsContext);
    const userContext = useContext(UserContext);

    useEffect(() => {
        if(projects === null) {
            chimera.callAPI(undefined, '/api/projects')
            .then(newProjects => setProjects(newProjects))
            .catch(err => {
                console.error(err);
                banners.addBanner('danger', 'Failed to read Projects', 'Error');
            })
        }
    }, [projects]);

    const openNewForm = (_) => {
        openProjectInModal(null);
    }

    const openProjectInModal = (project) => {
        const onClose = () => {
            alerts.reload();
            if(!project) setProjects(null);
        }
        modaling.setModal(<ProjectFormModal project={project} parentBanners={banners} parentSetSavedProject={setSavedProject} onClose={onClose} removeProjectById={removeById}/>);
    }

    const statusColor = (status) => {
        switch(status) {
            case "New":
                return "info";
            case "In-Progress":
            case "Waiting on Parts":
            case "Waiting on Customer":
                return "warning";
            case "Ready to Bill":
                return "primary";
            case "Billed":
                return "secondary";
            default:
                return "light";
        }
    }

    const renderFlag = (project) => {
        // since the reason is not dynamic, we can load it faster by simply reading the notes
        let isFlagged = false;
        for(const note of project.notes) {
            if(note.flagged) {
                isFlagged = true;
                break;
            }
        }
        return isFlagged ? <>&nbsp;<Tooltip text="(See Notes)"><i className="fas fa-flag text-danger"/></Tooltip></> : null;
    }

    return (
        <>
        <ObjectTable
            id="projectsTable"
            cols={[
                {
                    label: 'Project #',
                    sort: (a,b) => a.number < b.number ? -1 : 1,
                    render: (obj) => <>{obj.number}{renderFlag(obj)}</>
                },
                {
                    label: 'Status',
                    sort: (a, b) => {
                        const values = {
                            'New': 0,
                            'In-Progress': 1,
                            'Scheduled': 2,
                            'Waiting on Parts': 3,
                            'Waiting on Customer': 4,
                            'Ready to Bill': 5,
                            'Billed': 6
                        }
                        return values[`${a.status}`] < values[`${b.status}`] ? -1 : 1;
                    },
                    render: (obj) => obj.status,
                    color: (obj) => statusColor(obj.status)
                },
                ObjectTable.simpleCol('Customer', 'customer.name'),
                ObjectTable.simpleCol('Title', 'title'),
                {
                    label: 'Assignee',
                    sort: (a,b) => {
                        return `${a.assignee.first} ${a.assignee.last}` < `${b.assignee.first} ${b.assignee.last}` ? -1 : 1
                    },
                    render: (obj) => `${obj.assignee.first} ${obj.assignee.last}`
                },
                ObjectTable.simpleCol('Timeline', 'timeline'),
                {
                    label: 'Date Created',
                    sort: (a,b) => a.createdAt < b.createdAt ? -1 : 1,
                    render: (obj) => (new Date(obj.createdAt)).toLocaleString()
                },
            ]}
            objects={projects}
            filters={[
                {label: 'Show All', value: 'All', func: (_) => true},
                {label: 'Assigned to Me', value: 'Mine', func: (p) => p.assignee.email === userContext.user.email},
            ]}
            btns={[
                {label: 'Project', func: openNewForm}
            ]}
            onClicked={openProjectInModal}
            search
            paginated
            defaultSortByColName="Project #"
            defaultSortAscending={true}
            defaultFilterSetting={userContext.groups.includes('Engineer') ? 'Mine' : 'All'}
        />
        </>
    )
}

const ProjectsPage = ({}) => {
    return (
        <div className="container pb-5">
            <h1>Projects</h1>
            <BannerLog>
                <ProjectsPageBody />
            </BannerLog>
        </div>
    )
}

export default ProjectsPage;