import React from 'react';
import {BannerLog} from '../BannerLogContext';
import Modal from '../Modal';
import ProjectForm from './ProjectForm';
import useFormModal from '../../hooks/useFormModal';

const ProjectFormModal = ({project, parentBanners, parentSetSavedProject, onClose, removeProjectById}) => {
    const {workingObj, setWorkingObj, setModified, choices, ref} = useFormModal({
        initialState: project,
        apiBase: '/api/projects',
        noun: 'Project',
        removeById: removeProjectById,
        onClose,
        parentBanners
    })

    return (
        <Modal choices={choices} dismiss={choices[0].func}>
            <BannerLog>
                <ProjectForm ref={ref} project={workingObj} parentSetModified={setModified} parentSetProject={setWorkingObj} parentSetSavedProject={parentSetSavedProject}/>
            </BannerLog>
        </Modal>
    )
}

export default ProjectFormModal;