import { useState, useEffect } from "react";

// Not to be confused with `useFormModal` - `useFormModal` is for easily defining Form Modals, and `useSmoothFormModal` is for re-using logic that makes interfacing with tables smoother
function useSmoothFormModal(objs, setObjs) {
    const [savedObj, setSavedObj] = useState(null);

    useEffect(() => {
        if(savedObj !== null) {
            const newObjs = JSON.parse(JSON.stringify(objs));
            const index = newObjs.findIndex(obj => obj._id === savedObj._id);
            if(index !== -1) {
                newObjs[index] = savedObj;
                setObjs(newObjs);
            }
            setSavedObj(null);
        }
    }, [savedObj]);

    const removeById = (id) => {
        setObjs(objs.filter(o => o._id !== id));
    }

    return {
        setSavedObj,
        removeById
    }
}

export default useSmoothFormModal;