import React, {useState, useEffect, useLayoutEffect, useContext} from 'react';

import {useParams} from 'react-router-dom';
import LoadingSpinner from './LoadingSpinner';
import chimera from '../chimera';
import PocDisplay from './PocDisplay';
import AddressDisplay from './AddressDisplay';

import UserContext from '../UserContext';
import BannerContext, { BannerLog } from "./BannerLogContext";
import Modal from './Modal';
import ModalContext from './ModalContext';
import { NavLink } from "react-router-dom";
import IntegrationsDisplay from './CustomerPage/IntegrationsDisplay';
import PushSync from './PushSync';
import NotesSection from './CustomerPage/NotesSection';

const UnsentInvoices = props => {
    const [invoices, setInvoices] = useState(null);
    const [showError, setShowError] = useState(false);
    const [showMore, setShowMore] = useState(false);

    useEffect(() => {
        const controller = new AbortController();
        const signal = controller.signal;
        if(invoices === null) {
            chimera.callQuickBooksAPI(signal, '/api/qb/invoice/status/0', 'GET', null, {residential: true})
            .then(allInvoices => {
                let newInvoices = [];
                for(const invoice of allInvoices) {
                    if(invoice.CustomerRef.value === props.id) {
                        newInvoices.push(invoice);
                    }
                }
                setInvoices(newInvoices);
            })
            .catch(e => {
                if(e.name !== "AbortError") {
                    console.error(e);
                    setShowError(true);
                }
            })
        }
        return () => {
            controller.abort();
        }
    }, [invoices]);

    if(showError) {
        return (
            <p className="text-danger text-start">An error occurred and the list could not be determined.</p>
        )
    }
    else {
        return (
            <>
            {invoices !== null ? 
                <>
                {invoices.length > 0 ?
                    <>
                    <table className="table table-striped table-bordered">
                        <thead>
                            <tr>
                                <th>Date</th>
                                <th>Invoice Number</th>
                                <th>Link</th>
                            </tr>
                        </thead>
                        <tbody>
                            <>
                            {invoices.slice(0, showMore ? invoices.length : 3).map(invoice => <tr>
                                <td>{invoice.TxnDate}</td>
                                <td>{invoice.DocNumber}</td>
                                <td><a href={`https://app.qbo.intuit.com/app/invoice?txnId=${invoice.Id}`} target="_blank" rel="noopener noreferrer"><i className="fas fa-up-right-from-square"/></a></td>
                            </tr>)}
                            </>
                        </tbody>
                    </table>
                    {invoices.length > 3 ?
                        <>
                        {showMore ? 
                            <button className="btn btn-secondary btn-sm" onClick={(event) => {event.preventDefault(); setShowMore(false)}}>
                                <i className="fas fa-angle-up"/>&nbsp;
                                Show Less
                            </button>
                        :
                            <button className="btn btn-secondary btn-sm" onClick={(event) => {event.preventDefault(); setShowMore(true)}}>
                                <i className="fas fa-angle-down"/>&nbsp;
                                Show More
                            </button>
                        }
                        </>
                    :null}
                    </>
                :
                    <p className="text-success text-start">All invoices for this customer have been sent!</p>
                }
                </>
            :
                <LoadingSpinner size={50}/>
            }
            </>
        )
    }
}

const OpenBalancePastDue = props => {
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [openBalance, setOpenBalance] = useState(0.0);
    const [pastDue, setPastDue] = useState(0.0);

    useEffect(() => {
        const controller = new AbortController();
        const signal = controller.signal;
        const now = new Date();
        const today = now.toISOString().substring(0,10);
        chimera.callQuickBooksAPI(signal, `/api/qb/customer/${props.id}`, 'GET', null, {residential: true})
        .then(qbCustomer => {
            setOpenBalance(qbCustomer.Balance);
            chimera.callQuickBooksAPI(signal, `/api/qb/customer/${props.id}/invoices`, 'GET', null, {residential: true})
            .then(invoices => {
                let newPastDue = 0.0;
                for(const invoice of invoices) {
                    if(invoice.DueDate > today) {
                        newPastDue += invoice.TotalAmt;
                    }
                }
                setPastDue(newPastDue);
                setLoading(false);
            })
            .catch(e => {
                if(e.name !== "AbortError") {
                    console.error(e);
                    setError(e);
                    setLoading(false);
                }
            })
        })
        .catch(e => {
            if(e.name !== "AbortError") {
                console.error(e);
                setError(e);
                setLoading(false);
            }
        })
        return () => {
            controller.abort();
        }
    }, []);

    return (
        <div className="d-flex flex-column text-start">
        {loading ?
            <>
            <b>Open Balance / Past Due:</b>
            <LoadingSpinner size={50}/>
            </>
        :
            <>
            {error ? 
                <p className="text-danger text-start">
                    An error occurred and the amounts could not be calculated.
                </p>
            :
                <>
                <div className="row text-start">
                    <div className="col-3">
                        <b>Open Balance:</b>
                    </div>
                    <div className="col-9">
                        <span className={openBalance > 0 ? "text-danger" : "text-body"}>{chimera.dollarStr(openBalance)}</span>
                    </div>
                </div>
                <div className="row text-start">
                    <div className="col-3">
                        <b>Past Due:</b>
                    </div>
                    <div className="col-9">
                        <span className={pastDue > 0 ? "text-danger" : "text-body"}>{chimera.dollarStr(pastDue)}</span>
                    </div>
                </div>
                </>
            }
            </>
        }
        </div>
    )
}

const UnityDevicesCounter = props => {
    const [count, setCount] = useState(0);
    const [loading, setLoading] = useState(true);
    const banners = useContext(BannerContext);

    useEffect(() => {
        const controller = new AbortController();
        const signal = controller.signal;

        chimera.callAPI(signal, `/api/unity/domains/${props.domain}/devices/total`)
        .then(total => {
            setCount(total);
            setLoading(false);
        })
        .catch(e => {
            if(e.name !== "AbortError") {
                console.error(e);
                banners.addBanner('danger', 'Failed to get Unity device count', 'Error');
            }
        })

        return () => {
            controller.abort();
        }
    }, []);

    return(
        <>
        {loading ?
            <i className="fas fa-spinner"/>
        :
            <>{count}</>
        }
        </>
    )
}

const UnityDIDList = props => {
    const [numbers, setNumbers] = useState([]);
    const [loading, setLoading] = useState(true);
    const banners = useContext(BannerContext);

    useEffect(() => {
        const controller = new AbortController();
        const signal = controller.signal;

        chimera.callAPI(signal, '/api/unity/dialplan/DID Table/phonenumbers')
        .then(dids => {
            setNumbers(dids.filter(did => did.domain === props.domain))
            setLoading(false);
        })
        .catch(e => {
            if(e.name !== "AbortError") {
                console.error(e);
                banners.addBanner('danger', 'Failed to get DIDs from Unity', 'Error');
            }
        })

        return () => {
            controller.abort();
        }
    }, []);

    return (
        <>
        {loading ?
            <LoadingSpinner size={50}/>
        :
            <ol style={{paddingLeft: "1rem"}}>
                {numbers.map(did => <li>{chimera.phoneNumberStr(did.number)} ({did.description}){did.enabled !== "yes" ? ' (DISABLED)' : ''}</li>)}
            </ol>
        }
        </>
    )
}

const UnityFaxList = props => {
    const [pangeaNumbers, setPangeaNumbers] = useState(null);
    const [phaxioNumbers, setPhaxioNumbers] = useState(null);
    const [loading, setLoading] = useState(true);
    const banners = useContext(BannerContext);

    useEffect(() => {
        const controller = new AbortController();
        const signal = controller.signal;

        if(pangeaNumbers === null) {
            chimera.callAPI(signal, '/api/unity/dialplan/DID Table/fax')
            .then(numbers => setPangeaNumbers(numbers.filter(number => number.domain === props.domain)))
            .catch(e => {
                if(e.name !== "AbortError") {
                    console.error(e);
                    banners.addBanner('danger', 'Cannot read Pangea fax numbers', 'Error');
                }
            })
        }
        if(phaxioNumbers === null) {
            chimera.callAPI(signal, '/api/phaxio/numbers')
            .then(numbers => setPhaxioNumbers(numbers.filter(number => number.domain === props.domain)))
            .catch(e => {
                if(e.name !== "AbortError") {
                    console.error(e);
                    banners.addBanner('danger', 'Cannot read Phaxio fax numbers', 'Error');
                }
            })
        }
        if(pangeaNumbers !== null && phaxioNumbers !== null) {
            setLoading(false);
        }

        return () => {
            controller.abort();
        }
    }, [pangeaNumbers, phaxioNumbers]);

    return (
        <>
        {loading ?
            <LoadingSpinner size={50}/>
        :
            <div className="text-start">
                <u>Pangea:</u>
                <ol>
                    <>
                    {pangeaNumbers.length > 0 ? 
                        <>{pangeaNumbers.map(num => <li>{chimera.phoneNumberStr(num.number)}{num.enabled !== "yes" ? ' (DISABLED)' : ''}</li>)}</>
                    :
                        <i className="text-muted">There are no Pangea numbers for this customer.</i>
                    }
                    </>
                </ol>
                <u>Phaxio:</u>
                <ol>
                    <>
                    {phaxioNumbers.length > 0 ? 
                        <>{phaxioNumbers.map(num => <li>{chimera.phoneNumberStr(num.number)}</li>)}</>    
                    :
                        <i className="text-muted">There are no Phaxio numbers for this customer.</i>
                    }
                    </>
                </ol>
            </div>
        }
        </>
    )
}

const ResidentialCustomerPageBody = props => {
    const { acct } = useParams();
    const [customer, setCustomer] = useState(null);
    const [isEditing, setIsEditing] = useState(false);
    const [workingCustomer, setWorkingCustomer] = useState(null);
    const [saveBtnIcon, setSaveBtnIcon] = useState("fas fa-bookmark");
    const [saveBtnLabel, setSaveBtnLabel] = useState("Save");
    const [isSaving, setIsSaving] = useState(false);
    const [notes, setNotes] = useState(null);
    const [controller, setController] = useState(new AbortController());
    const [signal, setSignal] = useState(controller.signal);

    const context = useContext(UserContext);
    const banners = useContext(BannerContext);
    const modalContext = useContext(ModalContext);

    useLayoutEffect(() => {
        return () => {
            controller.abort();
        }
    }, []);

    useEffect(() => {
        setWorkingCustomer(JSON.parse(JSON.stringify(customer)));
    }, [customer]);

    useEffect(() => {
        if(acct) {
            chimera.callAPI(signal, `/api/residentialcustomers/accountnumber/${acct}`)
            .then(customer => {
                setCustomer(customer);
            })
            .catch(err => {
                if(err.name !== "AbortError") {
                    console.error(err);
                    banners.addBanner('danger', 'Failed to read Customer', 'Error');
                }
            })
        }
    }, [acct]);

    useEffect(() => {
        if(customer && notes === null) {
            const sortByStarred = (arr) => {
                let starredNotes = [];
                let unstarredNotes = [];
                for(const note of arr) {
                    if(note.starred) starredNotes.push(note);
                    else unstarredNotes.push(note);
                }
                return [].concat(starredNotes, unstarredNotes);
            }
            chimera.callAPI(signal, `/api/notes/refId/${customer._id}`)
            .then(newNotes => {setNotes(sortByStarred(newNotes))})
            .catch(e => {
                if(e.name !== "AbortError") {
                    banners.addBanner('danger', 'Could not read notes', 'Error');
                    console.error(e);
                }
            });
        }
    }, [customer, notes]);

    const handleChange = event => {
        if(event.target.type !== "checkbox") event.preventDefault();
        const name = event.target.name;
        const value = event.target.value;

        let newWorkingCustomer = JSON.parse(JSON.stringify(workingCustomer));

        if(event.target.type === "checkbox") {
            if(name === "standing") {
                newWorkingCustomer.standing = event.target.checked ? "good" : "bad";
            }
            else {
                chimera.setAttr(newWorkingCustomer, name, event.target.checked);
            }
        }
        else if(event.target.type === "number" && name.includes("voipLines")) {
            chimera.setAttr(newWorkingCustomer, name, parseInt(value));
        }
        else if(event.target.type === "number") {
            chimera.setAttr(newWorkingCustomer, name, parseFloat(value));
        }
        else {
            if(name.toLowerCase().includes("phone")) {
                chimera.setAttr(newWorkingCustomer, name, value.replace(/\D/g, ''));
            }
            else if(name.includes("integrationIds")) {
                chimera.setAttr(newWorkingCustomer, name, value.replace(/[^a-zA-Z0-9]/g, ''));
            }
            else {
                chimera.setAttr(newWorkingCustomer, name, value);
            }
        }

        if(newWorkingCustomer.mailSameAsHome) {
            newWorkingCustomer.mailAddress = JSON.parse(JSON.stringify(newWorkingCustomer.homeAddress));
        }

        if(newWorkingCustomer.technical.ipType !== "Static IP") {
            newWorkingCustomer.technical.ips = [];
            newWorkingCustomer.technical.subnetMasks = [];
            newWorkingCustomer.technical.gateways = [];
        }

        setWorkingCustomer(newWorkingCustomer);
    }

    const confirmDelete = event => {
        event.preventDefault();
        const loadingModal = 
            <Modal choices={[]} dismiss={(event) => {event.preventDefault(); modalContext.setModal(null)}}>
                <LoadingSpinner size={75}/>
            </Modal>;
        const choices = [
            {
                btnColor: 'danger',
                btnInner: <span><i className="fas fa-times"/>&nbsp;Delete From Chimera Only</span>,
                func: async(e) => {
                    e.preventDefault();
                    modalContext.setModal(loadingModal);
                    let bannersCopy = [];
                    if(await chimera.deleteCustomer(customer, {chimera: true, residential: true}, banners, bannersCopy)) {
                        bannersCopy.push({
                            type: 'info',
                            message: 'Successfully deleted the customer from Chimera.'
                        });
                        modalContext.setModal(null);
                        sessionStorage.setItem("banners", JSON.stringify(bannersCopy));
                        window.open(`/residential`, '_self');
                    }
                }
            },
            {
                btnColor: 'danger',
                btnInner: <span><i className="fas fa-times"/>&nbsp;Delete Everywhere</span>,
                func: async(e) => {
                    e.preventDefault();
                    modalContext.setModal(loadingModal);
                    let bannersCopy = [];
                    if(await chimera.deleteCustomer(customer, {everywhere: true, residential: true}, banners, bannersCopy)) {
                        bannersCopy.push({
                            type: 'info',
                            message: 'Successfully deleted the customer from Chimera.'
                        });
                        modalContext.setModal(null);
                        sessionStorage.setItem("banners", JSON.stringify(bannersCopy));
                        window.open(`/customers`, '_self');
                    }
                }
            },
            {
                btnColor: 'secondary',
                btnInner: <span><i className="fas fa-arrow-left"/>&nbsp;No, Cancel</span>,
                func: (e) => {
                    e.preventDefault();
                    modalContext.setModal(null);
                }
            }
        ]
        const modal = <Modal choices={choices} dismiss={choices[1].func}>
            <h3>Are you sure?</h3>
            <p><strong>
                Are you sure you want to delete {customer.firstName} {customer.lastName}? <span className="text-danger">This operation cannot be undone.</span>
            </strong></p>
        </Modal>
        modalContext.setModal(modal);
    }

    const startEditing = event => {
        event.preventDefault();
        setIsEditing(true);
    }

    const cancelEditing = event => {
        event.preventDefault();
        setWorkingCustomer(JSON.parse(JSON.stringify(customer)));
        setIsEditing(false);
    }

    const saveChanges = async event => {
        event.preventDefault();
        setIsSaving(true);
        setSaveBtnIcon("fas fa-spinner");
        setSaveBtnLabel("Saving...");
        banners.clearBanners();

        const trimCustomer = (cust, func) => {
            let ret = {};
            const excludeKeys = [
                "_id", "__v", "createdAt", "createdBy",
                "modifiedBy", "updatedAt", "accountNumber"
            ];
            for(const key in cust) {
                if(!excludeKeys.includes(key) && (func === undefined || func(key))) {
                    ret[key] = cust[key];
                }
            }
            return ret;
        }

        const newCustomer = trimCustomer(workingCustomer, (key) => !chimera.deepEqual(customer[key], workingCustomer[key]));

        const restoreCustomer = async(id, platform) => {
            try {
                await chimera.callAPI(signal, `/api/residentialcustomers/${id}`, 'PUT', trimCustomer(customer));
                return true;
            }
            catch(e) {
                if(e.name !== "AbortError") {
                    console.error(e);
                    banners.addBanner('danger', <span><b>Desync Error:</b> The Chimera changes stuck and could not be reversed, but {platform} failed to update. The records are now desynchronized.</span>);
                }
                return false;
            }
        }

        try {
            const customerResponse = await chimera.callAPI(signal, `/api/residentialcustomers/${workingCustomer._id}`, 'PUT', newCustomer);
            // At this point, the user is confirmed to have permissions to make the changes.

            // Propagate to Syncro
            if(customerResponse.integrationIds.syncro) {
                try {
                    await chimera.pushToSyncro(customerResponse, null, null, null, true);
                }
                catch(e) {
                    console.error(e);
                    const restored = await restoreCustomer(customerResponse._id, 'Syncro');
                    if(restored) {
                        try {
                            if(e.status === 422 && e.details && e.details.data.message && e.details.data.message[0] === "Email has already been taken") {
                                banners.addBanner('danger', <span><b>Syncro Error:</b> You can't use that POC Email since it is already taken for another Syncro customer.</span>);
                            }
                            else {
                                banners.addBanner('danger', <span><b>Syncro Error:</b> Something went wrong and the Syncro customer could not be updated. The Chimera customer will not update.{e.status ? ` (status ${e.status})` : null}</span>);
                            }
                        }
                        catch(e) {
                            banners.addBanner('danger', <span><b>Syncro Error:</b> Something went wrong and the Syncro customer could not be updated. The Chimera customer will not update.</span>);
                        }
                    }
                    setIsSaving(false);
                    setSaveBtnIcon("fas fa-bookmark");
                    setSaveBtnLabel("Save");
                    return;
                }
            }

            // Propagate to QuickBooks
            if(customerResponse.integrationIds.quickbooks) {
                try {
                    // Unlike Syncro, we don't send the whole Customer over no matter what.
                    // Instead, we will check for changes field by field.
                    // This way, we are less likely to overwrite something critial since QB is more sensitive than Syncro.
                    const qbCustomer = {};
                    if(`${customerResponse.firstName} ${customerResponse.lastName}` !== `${customer.firstName} ${customer.lastName}`) {
                        qbCustomer.DisplayName = `${customerResponse.firstName} ${customerResponse.lastName}`;
                    }
                    if(!chimera.deepEqual(customerResponse.mailAddress, customer.mailAddress)) {
                        qbCustomer.BillAddr = {
                            CountrySubDivisionCode: customerResponse.mailAddress.state,
                            City: customerResponse.mailAddress.city,
                            PostalCode: customerResponse.mailAddress.zip,
                            Line1: customerResponse.mailAddress.street1,
                            Country: "USA"
                        }
                        qbCustomer.ShipAddr = {
                            CountrySubDivisionCode: customerResponse.mailAddress.state,
                            City: customerResponse.mailAddress.city,
                            PostalCode: customerResponse.mailAddress.zip,
                            Line1: customerResponse.mailAddress.street1,
                            Country: "USA"
                        }
                        if(customerResponse.mailAddress.street2) {
                            qbCustomer.BillAddr.Line2 = customerResponse.mailAddress.street2;
                            qbCustomer.ShipAddr.Line2 = customerResponse.mailAddress.street2;
                        }
                    }
                    if(customerResponse.email !== customer.email) {
                        qbCustomer.PrimaryEmailAddr = {
                            Address: customerResponse.email
                        }
                    }
                    if(!customerResponse.phone !== customer.phone) {
                        qbCustomer.PrimaryPhone = {
                            FreeFormNumber: chimera.phoneNumberStr(customerResponse.phone)
                        }
                    }

                    if(!chimera.deepEqual(qbCustomer, {})) {
                        try {
                            await chimera.callQuickBooksAPI(signal, `/api/qb/customer/${customerResponse.integrationIds.quickbooks}`, 'PUT', qbCustomer, {residential: true});
                        }
                        catch(e) {
                            if(e.name !== "AbortError") {
                                console.error(e);
                                const restored = await restoreCustomer(customerResponse._id, 'QuickBooks');
                                if(restored) {
                                    banners.addBanner('danger', <span><b>QuickBooks Error:</b> Something went wrong and the QuickBooks customer could not be updated. The Chimera customer will not be updated.</span>);
                                }
                            }
                            setIsSaving(false);
                            setSaveBtnIcon("fas fa-bookmark");
                            setSaveBtnLabel("Save");
                            return;
                        }
                    }
                }
                catch(e) {
                    console.error(e);
                    banners.addBanner('danger', <span><b>Error:</b> An unhandled error occurred when updating the QuickBooks customer. Neither the QB customer nor the Chimera customer have been updated.</span>);
                    setIsSaving(false);
                    setSaveBtnIcon("fas fa-bookmark");
                    setSaveBtnLabel("Save");
                    return;
                }
            }
            setCustomer(customerResponse);
            setIsEditing(false);
        }
        catch(e) {
            if(e.name !== "AbortError") {
                console.error(e);
                if(e.status === 403) {
                    let problemKey = e.details.problemKey;
                    let cantModify = "";
                    switch(problemKey) {
                        case "firstName":
                            cantModify = "the First Name";
                            break;
                        case "lastName":
                            cantModify = "the Last Name";
                            break;
                        case "email":
                            cantModify = "the Email Address";
                            break;
                        case "phone":
                            cantModify = "the Phone Number";
                            break;
                        case "type":
                            cantModify = "the Residential Zone";
                            break;
                        case "homeAddress":
                            cantModify = "the Home Address";
                            break;
                        case "mailAddress":
                            cantModify = "the Mail Address";
                            break;
                        case "authorizedUsers":
                            cantModify = "the Authorized Users";
                            break;
                        case "serviceTypes":
                            cantModify = "the Service Types";
                            break;
                        case "integrationIds":
                            cantModify = "the Integration IDs";
                            break;
                        case "technical":
                            cantModify = "Technical Details";
                            break;
                        default:
                            cantModify = "one or more fields";
                            break;
                    }
                    banners.addBanner('danger', <span><b>Forbidden:</b> You do not have permission to modify {cantModify}.</span>);
                }
                else if(e.status === 400) {
                    const json = e.details;
                    if(json.name === "ValidationError") {
                        for(const key in json.errors) {
                            const obj = json.errors[key];
                            banners.addBanner('danger', <span><b>Validation Error:</b> {obj.message}</span>);
                        }
                    }
                    else {
                        banners.addBanner('danger', <span><b>Error:</b> An unknown error has occurred. The Customer could not be updated.</span>);
                    }
                }
                else {
                    banners.addBanner('danger', <span><b>Error:</b> Failed to update the customer.</span>);
                }
            }
        }

        setIsSaving(false);
        setSaveBtnIcon("fas fa-bookmark");
        setSaveBtnLabel("Save");
    }

    const addAuthorizedUser = event => {
        event.preventDefault();
        let newUsers = [];
        for(const poc of workingCustomer.authorizedUsers) {
            newUsers.push(poc);
        }
        newUsers.push({
            firstName: "",
            lastName: "",
            email: "",
            phone: ""
        });

        handleChange({
            target: {
                type: "string",
                name: "authorizedUsers",
                value: newUsers
            },
            preventDefault: () => {}
        })
    }

    const deleteAuthorizedUser = index => {
        let newUsers = [];
        for(let i = 0; i < workingCustomer.authorizedUsers.length; i++) {
            if(i !== index) {
                newUsers.push(workingCustomer.authorizedUsers[i]);
            }
        }

        handleChange({
            target: {
                type: "string",
                name: "authorizedUsers",
                value: newUsers
            },
            preventDefault: () => {}
        })
    }

    const addTechString = (to, str) => {
        let newStrings = [];
        for(let i = 0; i < workingCustomer.technical[to].length; i++) {
            newStrings.push(workingCustomer.technical[to][i]);
        }
        newStrings.push(str);

        handleChange({
            target: {
                type: "string",
                name: `technical.${to}`,
                value: newStrings
            },
            preventDefault: () => {}
        })
    }

    const deleteTechString = (from, index) => {
        let newStrings = [];
        for(let i = 0; i < workingCustomer.technical[from].length; i++) {
            if(i !== index) {
                newStrings.push(workingCustomer.technical[from][i]);
            }
        }

        handleChange({
            target: {
                type: "string",
                name: `technical.${from}`,
                value: newStrings
            },
            preventDefault: () => {}
        })
    }

    const trimOnBlur = (event) => {
        handleChange({
            target: {
                type: "string",
                name: event.target.name,
                value: event.target.value.trim()
            },
            preventDefault: () => {}
        })
    }

    const techStringEditing = (field, placeholder) => {
        return (
            <>
            {workingCustomer.technical[field].length > 0 ? 
            <ol className="ps-3 mb-1">
                {workingCustomer.technical[field].map((str, i) => <li>
                    <div className={i === 0 ? "row" : "row mt-1"}>
                        <div className="col d-flex flex-row">
                            <input className="form-control w-auto" type="text" placeholder={placeholder} name={`technical.${field}[${i}]`} value={str} onChange={handleChange} disabled={isSaving || !hasWritePerm('technical') || workingCustomer.technical.ipType !== "Static IP"} onBlur={trimOnBlur}/>
                            <button className="btn btn-sm btn-danger w-auto ms-1" onClick={(event) => {event.preventDefault(); deleteTechString(field, i)}} disabled={isSaving || !hasWritePerm('technical')}>
                                <i className="fas fa-minus"/>
                            </button>
                        </div>
                    </div>
                </li>)}
            </ol>
            :null}
            <button className="btn btn-sm btn-success" onClick={(event) => {event.preventDefault(); addTechString(field, '')}} disabled={isSaving || !hasWritePerm('technical') || workingCustomer.technical.ipType !== "Static IP"}>
                <i className="fas fa-plus"/>
            </button>
            </>
        )
    }

    const hasWritePerm = type => {
        return context.permissions[type].write;
    }

    const openPushModal = event => {
        event.preventDefault();
        const choices = [
            {
                btnInner: <span><i className="fas fa-arrow-left"/>&nbsp;Close</span>,
                btnColor: "secondary",
                func: (event) => {
                    event.preventDefault();
                    modalContext.setModal(null);
                }
            }
        ]
        const modal = <Modal choices={choices} dismiss={choices[0].func}>
            <PushSync customers={[customer]} residential/>
        </Modal>
        modalContext.setModal(modal);
    }

    const standing = () => {
        const tooltip = customer.standing === "good" ? "This customer is in good financial standing with CBIT!" : "This customer has been marked as in bad financial standing with CBIT by a manager.";
        const icon = customer.standing === "good" ? "text-success fas fa-circle-check" : "text-danger fas fa-circle-xmark";
        return (
            <div className="tooltip-container">
                <span className="tooltip-text-bottom fs-5">{tooltip}</span>
                <i className={icon}/>
            </div>
        )
    }

    return (
        <>
            {workingCustomer ? 
                <>
                <div className="row">
                    <div className="col-2 px-0 d-flex align-items-center justify-content-start">
                        <NavLink className="btn btn-secondary w-auto" to="/residential">
                            <i className="fas fa-arrow-left"/>
                        </NavLink>
                    </div>
                    <div className="col-8">
                        {!isEditing ? 
                            <>
                            <h1 className="my-0">{customer.firstName}&nbsp;{customer.lastName}&nbsp;{standing()}</h1>
                            <span className="fs-5">
                                # {customer.accountNumber}
                            </span>
                            </>
                        :
                            <>
                            <div className="row mb-1 mt-2">
                                <div className="col">
                                    <input className="form-control form-control-lg" type="text" name="firstName" placeholder="First Name" value={workingCustomer.firstName} onChange={handleChange} onBlur={trimOnBlur}/>
                                </div>
                                <div className="col">
                                    <input className="form-control form-control-lg" type="text" name="lastName" placeholder="Last Name" value={workingCustomer.lastName} onChange={handleChange} onBlur={trimOnBlur}/>
                                </div>
                            </div>
                            <div className="row mb-2">
                                <div className="col">
                                    <div className="form-check text-start">
                                        <input className="form-check-input" type="checkbox" name="standing" id="standing" checked={workingCustomer.standing === "good"} onChange={handleChange} disabled={isSaving || !hasWritePerm('financial')}/>
                                        <label className="form-check-label" for="standing">
                                            In Good Standing?
                                        </label>
                                    </div>
                                </div>
                            </div>
                            </>
                        }
                    </div>
                    {!isEditing ?
                    <div className="col-2 px-0 d-flex align-items-center justify-content-end">
                        <div className="dropdown">
                            <button className="btn btn-secondary dropdown-toggle" type="button" id="actionsBtn" data-bs-toggle="dropdown" aria-expanded="false">
                                Actions
                            </button>
                            <ul className="dropdown-menu" aria-labelledby="actionsBtn">
                                <li>
                                    <button className="btn dropdown-item" onClick={startEditing}>
                                        Edit
                                    </button>
                                </li>
                                <li>
                                    <button className="btn dropdown-item" onClick={openPushModal}>
                                        Push
                                    </button>
                                </li>
                                <li>
                                    <button className="btn text-danger dropdown-item" onClick={confirmDelete}>
                                        Delete
                                    </button>
                                </li>
                            </ul>
                        </div>
                    </div>
                    :
                    <div className="col-2 px-0">
                        <div className="row h-100">
                            <div className="col h-100 d-flex flex-row align-items-center justify-content-end">
                                <button className="btn btn-secondary me-2 w-auto" onClick={cancelEditing} disabled={isSaving}>
                                    <i className="fas fa-arrow-left"/>&nbsp;Cancel
                                </button>
                                <button className="btn btn-warning w-auto" onClick={saveChanges} disabled={isSaving}>
                                    <i className={saveBtnIcon}/>&nbsp;{saveBtnLabel}
                                </button>
                            </div>
                        </div>
                    </div>
                    }
                </div>
                <div className="row">
                    <div className="col-lg-3">
                        <div className="row">
                            <div className="section-outline">
                                <h5><b>Phone</b></h5>
                                {!isEditing ? 
                                <div className="row text-start">
                                    <span><i className="fas fa-phone"/>&nbsp; {chimera.phoneNumberStr(customer.phone)}</span>
                                </div>
                                :
                                <div className="row">
                                    <div className="col">
                                        <input type="text" className="form-control" name="phone" placeholder="15551234567" value={workingCustomer.phone} onChange={handleChange} maxLength={11} disabled={isSaving || !hasWritePerm('managerial')} onBlur={trimOnBlur}/>
                                    </div>
                                </div>
                                }
                            </div>
                        </div>
                        <div className="row mt-1">
                            <div className="section-outline">
                                <h5><b>Email</b></h5>
                                {!isEditing ? 
                                <div className="row text-start">
                                    <span><i className="fas fa-envelope"/>&nbsp; {customer.email}</span>
                                </div>
                                :
                                <div className="row">
                                    <div className="col">
                                        <input type="text" className="form-control" name="email" placeholder="email@example.com" value={workingCustomer.email} onChange={handleChange} disabled={isSaving || !hasWritePerm('managerial')} onBlur={trimOnBlur}/>
                                    </div>
                                </div>
                                }
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="section-outline">
                            <div className="row">
                                <div className="col">
                                    <h5 style={isEditing ? {marginBottom: "2.1rem"} : {}}><b>Home Address</b></h5>
                                    <AddressDisplay addr={customer.homeAddress} isEditing={isEditing} basePath="homeAddress" baseValue={workingCustomer} onChange={handleChange} disabled={isSaving || !hasWritePerm('managerial')} onBlur={trimOnBlur}/>
                                </div>
                                <div className="col">
                                    <h5><b>Mail Address</b></h5>
                                    <AddressDisplay addr={customer.mailAddress} isEditing={isEditing} basePath="mailAddress" baseValue={workingCustomer} onChange={handleChange} disabled={isSaving || !hasWritePerm('managerial')} onBlur={trimOnBlur} sameAsLabel="Same As Home Address" sameAsPath="mailSameAsHome" sameAs={workingCustomer.mailSameAsHome}/>
                                </div>
                            </div>
                        </div>
                        <div className="section-outline mt-1">
                            <div className="row">
                                <div className="col">
                                    <NotesSection header="Notes" setNotes={setNotes} notes={notes} technical={false} refId={customer._id}/>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3">
                        <div className="row">
                            <div className="section-outline">
                                <h5><b>Service Types</b></h5>
                                {!isEditing ? 
                                <b>
                                    Internet:&nbsp;<i className={`fas fa-${customer.serviceTypes.internet ? 'check' : 'ban'}`}/>&nbsp;
                                    VoIP:&nbsp;<i className={`fas fa-${customer.serviceTypes.voip ? 'check' : 'ban'}`}/>
                                </b>
                                :
                                <div className="text-start">
                                    <div className="form-check">
                                        <input className="form-check-input" type="checkbox" name="serviceTypes.internet" id="internetService" checked={workingCustomer.serviceTypes.internet} onChange={handleChange} disabled={isSaving || !hasWritePerm('managerial')}/>
                                        <label className="form-check-label" for="internetService">
                                            Internet
                                        </label>
                                    </div>
                                    <div className="form-check">
                                        <input className="form-check-input" type="checkbox" name="serviceTypes.voip" id="voipService" checked={workingCustomer.serviceTypes.voip} onChange={handleChange} disabled={isSaving || !hasWritePerm('managerial')}/>
                                        <label className="form-check-label" for="voipService">
                                            VoIP
                                        </label>
                                    </div>
                                </div>
                                }
                            </div>
                        </div>
                        <div className="row mt-1">
                            <div className="section-outline">
                                <h5><b>Zone</b></h5>
                                {!isEditing ? 
                                <span>{customer.type}</span>
                                :
                                <div className="row">
                                    <div className="col">
                                        <select className="form-select w-100" name="type" onChange={handleChange} disabled={isSaving || !hasWritePerm("managerial")}>
                                            <option value="Ederra" selected={workingCustomer.type === "Ederra"}>Ederra</option>
                                            <option value="Timberline" selected={workingCustomer.type === "Timberline"}>Timberline</option>
                                            <option value="Skyline Ridge" selected={workingCustomer.type === "Skyline Ridge"}>Skyline Ridge</option>
                                        </select>
                                    </div>
                                </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row mt-1">
                    <div className="section-outline">
                        <h5><b>Additional Authorized Users</b></h5>
                        <div className={isEditing ? "row mt-4" : "row"}>
                            <div className={isEditing ? "col" : "col d-flex flex-row"}>
                                {isEditing ? 
                                <>
                                    {workingCustomer.authorizedUsers.length > 0 ? 
                                    <>
                                    {workingCustomer.authorizedUsers.map((poc, i) => <div className={i === workingCustomer.authorizedUsers.length - 1 ? "row mb-1" : "row mb-4"}><div className="col d-flex justify-content-start"><div className="section-outline me-1 w-fit"><PocDisplay poc={poc} isEditing={isEditing} basePath={`authorizedUsers[${i}]`} baseValue={workingCustomer} onChange={handleChange} handleDelete={(event) => {event.preventDefault(); deleteAuthorizedUser(i)}} series disabled={isSaving || !hasWritePerm('financial')} onBlur={trimOnBlur}/></div></div></div>)}
                                    <div className="row">
                                        <div className="col d-flex justify-content-start">
                                            <button className="btn btn-success" onClick={addAuthorizedUser} disabled={isSaving || !hasWritePerm('financial')}>
                                                <i className="fas fa-plus"/>
                                            </button>
                                        </div>
                                    </div>
                                    </>
                                    :
                                    <i className="text-muted">There are no additional authorized users under this account.</i>
                                    }
                                </>
                                :
                                    <>{customer.authorizedUsers.map((poc, i) => <div className="section-outline me-1 w-fit"><PocDisplay poc={poc} series disabled={isSaving || !hasWritePerm('financial')} onBlur={trimOnBlur}/></div>)}</>
                                }
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row mt-1">
                    <div className="section-outline">
                        <h5><b>Integrations</b></h5>
                        <IntegrationsDisplay customer={customer} isEditing={isEditing} baseValue={workingCustomer} basePath={"integrationIds"} onChange={handleChange} disabled={isSaving || !hasWritePerm('managerial')} onBlur={trimOnBlur} residential/>
                    </div>
                </div>
                {context.permissions.financial.read ?
                <div className="row mt-1">
                    <div className="section-outline">
                        <h5><b>Financial</b></h5>
                        <div className="row">
                            <div className="col-6">
                                <div className="row">
                                    <div className="col-3 text-end">
                                        <b>Plan:</b>
                                    </div>
                                    <div className="col-9 text-start">
                                        {isEditing ? 
                                            <select className="form-select w-fit" name="plan" onChange={handleChange} disabled={isSaving || !hasWritePerm("financial")}>
                                                <option value="Plan A" selected={workingCustomer.plan === "Plan A"}>Plan A (Dynamic IP)</option>
                                                <option value="Plan B" selected={workingCustomer.plan === "Plan B"}>Plan B (Static IP)</option>
                                            </select>
                                        :
                                            <>{customer.plan === "Plan A" ? "Plan A (Dynamic IP)" : "Plan B (Static IP)"}</>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                        {customer.integrationIds.quickbooks ? 
                        <div className="row">
                            <div className="col-6">
                                {customer.integrationIds.quickbooks && !isEditing ? 
                                    <div className="row mb-1">
                                        <OpenBalancePastDue id={customer.integrationIds.quickbooks}/>
                                    </div>
                                :null}
                            </div>
                            <div className="col-6">
                                {customer.integrationIds.quickbooks && !isEditing ? 
                                    <div className="row mb-1">
                                        <div className="col d-flex flex-column">
                                            <b className="text-start">Unsent Invoices:</b>
                                            <UnsentInvoices id={customer.integrationIds.quickbooks}/>
                                        </div>
                                    </div>
                                :null}
                            </div>
                        </div>
                        :null}
                    </div>
                </div>
                : null}
                {context.permissions.technical.read ? 
                <>
                <div className="row mt-1">
                    <div className="section-outline">
                        <h4><b>Technical Details</b></h4>
                        {!isEditing ? 
                            <div className="row mb-2">
                                <div className="col-3"/>
                                <div className="col-6 section-outline">
                                    <NotesSection header="Technical Notes" setNotes={setNotes} notes={notes} technical={true} refId={customer._id}/>
                                </div>
                                <div className="col-3"/>
                            </div>
                        :null}
                        {workingCustomer.serviceTypes.internet || workingCustomer.serviceTypes.voip ?
                            <div className="row border-bottom border-1"/>
                        :null}
                        {workingCustomer.serviceTypes.internet ?
                            <div className="row mt-1">
                                <div className={`${workingCustomer.serviceTypes.voip ? 'border-bottom border-1 ' : ''}py-3`}>
                                    <h5><b>Internet Details</b></h5>
                                    <div className="row">
                                        <div className="col-6">
                                            <div className="row mt-1">
                                                <div className="col-3 text-end">
                                                    <b>Bandwidth:</b>
                                                </div>
                                                <div className="col-9 text-start">
                                                    {!isEditing ? 
                                                        <>{customer.technical.bandwidth}</>
                                                    :
                                                    <select className="form-select w-100" name="technical.bandwidth" onChange={handleChange} disabled={isSaving || !hasWritePerm("technical")}>
                                                        <option value="500 Mbps" selected={workingCustomer.technical.bandwidth === "500 Mbps"}>500 Mbps</option>
                                                        <option value="1 Gbps" selected={workingCustomer.technical.bandwidth === "1 Gbps"}>1 Gbps</option>
                                                    </select>
                                                    }
                                                </div>
                                            </div>
                                            <div className="row mt-1">
                                                <div className="col-3 text-end">
                                                    <b>IP Configuration:</b>
                                                </div>
                                                <div className="col-9 text-start">
                                                    {!isEditing ? 
                                                        <>{customer.technical.ipType}</>
                                                    :
                                                    <select className="form-select w-100" name="technical.ipType" onChange={handleChange} disabled={isSaving || !hasWritePerm("technical")}>
                                                        <option value="DHCP" selected={workingCustomer.technical.ipType === "DHCP"}>DHCP</option>
                                                        <option value="Static IP" selected={workingCustomer.technical.ipType === "Static IP"}>Static IP</option>
                                                    </select>
                                                    }
                                                </div>
                                            </div>
                                            {workingCustomer.technical.ipType === "DHCP" ? 
                                            <div className="row mt-1">
                                                <div className="col-3 text-end">
                                                    <b>DHCP Distinction:</b>
                                                </div>
                                                <div className="col-9 text-start">
                                                    {!isEditing ? 
                                                        <>{customer.technical.dhcp}</>
                                                    :
                                                    <select className="form-select w-100" name="technical.dhcp" onChange={handleChange} disabled={isSaving || !hasWritePerm("technical")}>
                                                        <option value="DHCP Routable/Registered" selected={workingCustomer.technical.dhcp === "DHCP Routable/Registered"}>DHCP Routable/Registered</option>
                                                        <option value="DHCP Nat'd" selected={workingCustomer.technical.dhcp === "DHCP Nat'd"}>DHCP Nat'd</option>
                                                    </select>
                                                    }
                                                </div>
                                            </div>
                                            :null}
                                            <div className="row mt-1">
                                                <div className="col-3 text-end">
                                                    <b>Switch Port:</b>
                                                </div>
                                                <div className="col-9 text-start">
                                                    {!isEditing ? 
                                                        <>{customer.technical.switchPort}</>
                                                    :
                                                        <input className="form-control" type="text" name="technical.switchPort" placeholder="Switch Port" value={workingCustomer.technical.switchPort} onChange={handleChange} onBlur={trimOnBlur}/>
                                                    }
                                                </div>
                                            </div>
                                            <div className="row mt-1">
                                                <div className="col-3 text-end">
                                                    <b>Patch Panel Port:</b>
                                                </div>
                                                <div className="col-9 text-start">
                                                    {!isEditing ? 
                                                        <>{customer.technical.patchPanelPort}</>
                                                    :
                                                        <input className="form-control" type="text" name="technical.patchPanelPort" placeholder="Patch Panel Port" value={workingCustomer.technical.patchPanelPort} onChange={handleChange} onBlur={trimOnBlur}/>
                                                    }
                                                </div>
                                            </div>
                                            <div className="row mt-1">
                                                <div className="col-3 text-end">
                                                    <b>Demarc Location:</b>
                                                </div>
                                                <div className="col-9 text-start">
                                                    {!isEditing ? 
                                                        <p>{customer.technical.demarc ? customer.technical.demarc : "(None)"}</p>
                                                    :
                                                        <>
                                                        <input className="form-control" type="text" name="technical.demarc" placeholder="Demarc" value={workingCustomer.technical.demarc} onChange={handleChange} onBlur={trimOnBlur}/>
                                                        </>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-6">
                                            <div className="row mt-1">
                                                <div className="col-3 text-end">
                                                    <b>IPs:</b>
                                                </div>
                                                <div className="col-9 text-start">
                                                    {!isEditing ? 
                                                        <>
                                                        {customer.technical.ips.length > 0 ?
                                                        <ol className="ps-3">
                                                            {customer.technical.ips.map(ip => <li><code>{ip}</code></li>)}
                                                        </ol>
                                                        :
                                                        <p>
                                                            (None)
                                                        </p>
                                                        }
                                                        </>
                                                    :
                                                        <>
                                                        {techStringEditing('ips', 'IP Address')}
                                                        </>
                                                    }
                                                </div>
                                            </div>
                                            <div className="row mt-1">
                                                <div className="col-3 text-end">
                                                    <b>Gateways:</b>
                                                </div>
                                                <div className="col-9 text-start">
                                                    {!isEditing ? 
                                                        <>
                                                        {customer.technical.gateways.length > 0 ?
                                                        <ol className="ps-3">
                                                            {customer.technical.gateways.map(gateway => <li><code>{gateway}</code></li>)}
                                                        </ol>
                                                        :
                                                        <p>
                                                            (None)
                                                        </p>
                                                        }
                                                        </>
                                                    :
                                                        <>
                                                        {techStringEditing('gateways', 'Gateway')}
                                                        </>
                                                    }
                                                </div>
                                            </div>
                                            <div className="row mt-1">
                                                <div className="col-3 text-end">
                                                    <b>Subnet Masks:</b>
                                                </div>
                                                <div className="col-9 text-start">
                                                    {!isEditing ? 
                                                        <>
                                                        {customer.technical.subnetMasks.length > 0 ?
                                                        <ol className="ps-3">
                                                            {customer.technical.subnetMasks.map(mask => <li><code>{mask}</code></li>)}
                                                        </ol>
                                                        :
                                                        <p>
                                                            (None)
                                                        </p>
                                                        }
                                                        </>
                                                    :
                                                        <>
                                                        {techStringEditing('subnetMasks', 'Subnet Mask')}
                                                        </>
                                                    }
                                                </div>
                                            </div>
                                            <div className="row mt-1">
                                                <div className="col-3 text-end">
                                                    <b>CPE:</b>
                                                </div>
                                                <div className="col-9 text-start">
                                                    {!isEditing ? 
                                                        <p>{customer.technical.cpe ? customer.technical.cpe : "(None)"}</p>
                                                    :
                                                        <>
                                                        <input className="form-control" type="text" name="technical.cpe" placeholder="CPE" value={workingCustomer.technical.cpe} onChange={handleChange} onBlur={trimOnBlur}/>
                                                        </>
                                                    }
                                                </div>
                                            </div>
                                            <div className="row mt-1">
                                                <div className="col-3 text-end">
                                                    <b>CPE IP:</b>
                                                </div>
                                                <div className="col-9 text-start">
                                                    {!isEditing ? 
                                                        <p>{customer.technical.cpeIp ? customer.technical.cpeIp : "(None)"}</p>
                                                    :
                                                        <>
                                                        <input className="form-control" type="text" name="technical.cpeIp" placeholder="CPE IP" value={workingCustomer.technical.cpeIp} onChange={handleChange} onBlur={trimOnBlur}/>
                                                        </>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        :null}
                        {workingCustomer.serviceTypes.voip ? 
                            <div className="row mt-1">
                                <div className="py-3">
                                    <h5><b>VoIP Details</b></h5>
                                    <div className="row mt-1">
                                        <div className="col-6">
                                            <div className="row">
                                                <div className="col-3 text-end">
                                                    <span>
                                                        <b># of Lines</b>&nbsp;
                                                        <div className="tooltip-container">
                                                            <span className="tooltip-text-bottom">Maximum simultaneous calls (a parameter specifically needed for FCC 477 reports)</span>
                                                            <i className="fas fa-circle-question"/>
                                                        </div>
                                                        <b>:</b>
                                                    </span>
                                                </div>
                                                <div className="col-9 text-start">
                                                    {!isEditing ? 
                                                        <span>{workingCustomer.technical.voipLines}</span>
                                                    :
                                                        <input className="form-control" type="number" name="technical.voipLines" value={workingCustomer.technical.voipLines} onChange={handleChange} min={0} step={1} disabled={isSaving || !hasWritePerm('technical')}/>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-6">
                                            {!isEditing ? 
                                                <div className="row">
                                                    <div className="col-3 text-end">
                                                        <b># of Devices:</b>
                                                    </div>
                                                    <div className="col-9 text-start">
                                                        {customer.integrationIds.unity ? <UnityDevicesCounter domain={customer.integrationIds.unity}/> : <i className="text-muted">Unity integration is required.</i>}
                                                    </div>
                                                </div>
                                            :null}
                                        </div>
                                    </div>
                                    {!isEditing ? 
                                        <div className="row mt-1">
                                            <div className="col-6">
                                                <div className="row">
                                                    <div className="col-3 text-end">
                                                        <b>DIDs:</b>
                                                    </div>
                                                    <div className="col-9 text-start">
                                                        {customer.integrationIds.unity ? <UnityDIDList domain={customer.integrationIds.unity}/> : <i className="text-muted">Unity integration is required.</i>}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-6">
                                                <div className="row">
                                                    <div className="col-3 text-end">
                                                        <b>Fax Numbers:</b>
                                                    </div>
                                                    <div className="col-9 text-start">
                                                        {customer.integrationIds.unity ? <UnityFaxList domain={customer.integrationIds.unity}/> : <i className="text-muted">Unity integration is required.</i>}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    :null}
                                </div>
                            </div>
                        :null}
                    </div>
                </div>
                </>
                :null}
                <div className="row mt-1 text-start monospace">
                    <span>Created by {customer.createdBy} on {(new Date(customer.createdAt)).toLocaleString()}</span>
                    <span>Updated by {customer.modifiedBy} on {(new Date(customer.updatedAt)).toLocaleString()}</span>
                </div>
                </>
            : <LoadingSpinner size={75}/> }
        </>
    )
}

const ResidentialCustomerPage = props => {
    return (
        <div className="container pb-5">
            <BannerLog>
                <ResidentialCustomerPageBody />
            </BannerLog>
        </div>
    )
}

export default ResidentialCustomerPage;