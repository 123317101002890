import React, {useContext} from 'react';
import ModalContext from '../ModalContext';
import ExportModal from '../ExportModal';
import MSPOrderFormModal from './MSPOrderFormModal';
import AlertsContext from '../AlertsContext';
import Tooltip from '../Tooltip';
import ObjectTable from '../ObjectTable';
import ModalCancelOnly from '../ModalCancelOnly';
import ButtonOptionList from '../ButtonOptionList';
import ConvertTypeModal from './ConvertTypeModal';

/**
 * 
 * @param {Array} props.orders The list of orders to display
 * @param {Function} props.clickedOrder A function that takes the clicked order as an argument
 */
const MSPOrdersTable = props => {
    const modaling = useContext(ModalContext);
    const alerts = useContext(AlertsContext);

    const openNewOrderForm = (type) => {
        modaling.setModal(null);
        modaling.setModal(<MSPOrderFormModal type={type} parentBanners={props.parentBanners} modalContext={modaling} onClose={() => {props.setOrders(null); alerts.reload()}}/>)
    }

    const openNewOrderOptions = (_) => {
        const modal = <ModalCancelOnly context={modaling}>
            <ButtonOptionList options={[
                {
                    text: "New Customer",
                    icon: "fas fa-plus",
                    func: (e) => {
                        e.preventDefault();
                        openNewOrderForm('New Customer');
                    }
                }
            ]}/>
            <hr/>
            <div className="row mb-3 mt-3">
                <h4>
                    For existing customers:
                </h4>
            </div>
            <ButtonOptionList options={[
                {
                    text: "Modify",
                    icon: "fas fa-screwdriver-wrench",
                    func: (e) => {
                        e.preventDefault();
                        openNewOrderForm('Modify Service');
                    }
                },
                {
                    text: "Disconnect",
                    icon: "fas fa-minus",
                    func: (e) => {
                        e.preventDefault();
                        openNewOrderForm('Disconnect');
                    }
                }
            ]}/>
        </ModalCancelOnly>
        modaling.setModal(modal);
    }

    /** TODO: Implement exporting for MSPOrders */
    const openExportModal = (selectedObjects) => {
        modaling.setModal(<ExportModal objects={selectedObjects} model="mspOrder"/>);
    }

    const openConvertModal = (selectedObjects) => {
        const callback = () => {
            props.setOrders(null);
            modaling.setModal(null);
        }
        modaling.setModal(<ConvertTypeModal orderClass="msp" orders={selectedObjects} modalContext={modaling} bannerContext={props.parentBanners} callback={callback}/>);
    }

    const statusColor = (status) => {
        switch(status) {
            case "New":
                return "info";
            case "Proposal Requested":
                return "primary";
            case "Proposal Sent":
                return "warning";
            case "Ready to Bill":
            case "Proposal Accepted":
                return "success";
            case "Onboarded":
            case "Completed":
                return "secondary";
            case "Cancelled":
                return "danger";
            default:
                return "light";
        }
    }

    const typeColor = (type) => {
        switch(type) {
            case "New Customer":
                return "info";
            case "Modify Service":
                return "warning";
            case "Disconnect":
                return "danger";
            default:
                return "light";
        }
    }

    const abbreviateType = (type) => {
        switch(type) {
            case "New Customer":
                return "New";
            case "Modify Service":
                return "Mod.";
            case "Add Location":
                return "Order";
            case "Disconnect":
                return "Disco.";
            default:
                return "(None)";
        }
    }

    const renderFlag = (obj) => {
        const reason = alerts.orderReason(obj._id, 'msp');
        if(!reason) return null;
        else return <>&nbsp;<Tooltip text={reason}><i className="fas fa-flag text-danger"/></Tooltip></>
    }

    return (
        <ObjectTable 
            id="mspOrdersTable"
            cols={[
                {
                    label: 'SO #', 
                    sort: (a, b) => a.number < b.number ? -1 : 1,
                    render: (obj) => <>{obj.number}{renderFlag(obj)}</>
                },
                {
                    label: 'Type', 
                    sort: (a, b) => {
                        const values = {
                            'New Customer': 0,
                            'Modify Service': 1,
                            'Disconnect': 2,
                        }
                        return values[`${a.type}`] < values[`${b.type}`] ? -1 : 1;
                    },
                    render: (obj) => abbreviateType(obj.type),
                    color: (obj) => typeColor(obj.type)
                },
                {
                    label: 'Status',
                    sort: (a, b) => {
                        const values = {
                            'New': 1,
                            'Proposal Requested': 2,
                            'Proposal Sent': 3,
                            'Proposal Accepted': 4,
                        }
                        return values[`${a.status}`] < values[`${b.status}`] ? -1 : 1;
                    },
                    render: (obj) => obj.status,
                    color: (obj) => statusColor(obj.status)
                },
                {
                    label: 'Customer Name',
                    sort: (a, b) => a.customerName < b.customerName ? -1 : 1,
                    render: (obj) => obj.customerName
                },
                {
                    label: 'Workstation Qty',
                    sort: (a, b) => a.workstationQty < b.workstationQty ? -1 : 1,
                    render: (obj) => obj.workstationQty
                },
                {
                    label: 'Server Qty',
                    sort: (a, b) => a.serverQty < b.serverQty ? -1 : 1,
                    render: (obj) => obj.serverQty
                },
                {
                    label: 'O365?',
                    sort: (a, b) => {
                        const values = {
                            'NOT SET': 1,
                            'Yes': 2,
                            'No': 3,
                        }
                        return values[`${a.o365}`] < values[`${b.o365}`] ? -1 : 1;
                    },
                    render: (obj) => obj.o365
                },
                {
                    label: 'Bill Start Date',
                    sort: (a, b) => a.billStartDate < b.billStartDate ? -1 : 1,
                    render: (obj) => obj.billStartDate ? (new Date(`${obj.billStartDate}T13:00:00`)).toLocaleDateString() : '(None)'
                },
                {
                    label: 'FOC Date',
                    sort: (a, b) => a.focDate < b.focDate ? -1 : 1,
                    render: (obj) => obj.focDate ? new Date(`${obj.focDate}T13:00:00`).toLocaleDateString() : '(None)'
                },
            ]}
            objects={props.orders}
            actions={[
                {label: 'Export', func: openExportModal},
                {label: 'Convert Type...', func: openConvertModal}
            ]}
            filters={[
                {label: 'Default', value: 'Default', func: (order) => order.status !== "Completed" && order.status !== "Cancelled"},
                {label: 'New', value: 'New', func: (order) => order.status === "New"},
                {label: 'Proposal Requested', value: 'Proposal Requested', func: (order) => order.status === "Proposal Requested"},
                {label: 'Proposal Sent', value: 'Proposal Sent', func: (order) => order.status === "Proposal Sent"},
                {label: 'Proposal Accepted', value: 'Proposal Accepted', func: (order) => order.status === "Proposal Accepted"},
                {label: 'Ready to Bill', value: 'Ready to Bill', func: (order) => order.status === "Ready to Bill"},
                {label: 'Completed', value: 'Completed', func: (order) => order.status === "Completed"},
                {label: 'Cancelled', value: 'Cancelled', func: (order) => order.status === "Cancelled"},
                {label: 'Everything', value: 'Everything', func: (_) => true},
            ]}
            btns={[
                {label: 'MSP Order', func: openNewOrderOptions}
            ]}
            onClicked={props.clickedOrder}
            search
            paginated
            defaultSortByColName="Status"
            defaultFilterSetting="Default"
            defaultSortAscending={true}
        />
    )
}

export default MSPOrdersTable