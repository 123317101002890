import React, {useState, useEffect, useLayoutEffect, useContext} from 'react';

import LoadingSpinner from '../LoadingSpinner';
import ModalContext from '../ModalContext';
import ObjectTable from '../ObjectTable';
import chimera from '../../chimera';
import BannerContext from '../BannerLogContext';
import Modal, { choiceCancel } from '../Modal';
import ExportModal from '../ExportModal';
import FileUpload from '../FileUpload';
import ModalCancelOnly from '../ModalCancelOnly';
import FormField from '../FormField';
import UserContext from '../../UserContext';

const IPRowModal = ({listId, row, modalContext, onClose}) => {
    const [workingRow, setWorkingRow] = useState(row);
    const [isSaving, setIsSaving] = useState(false);

    const trimOnBlur = (event) => {
        handleChange({
            target: {
                type: "string",
                name: event.target.name,
                value: event.target.value.trim()
            },
            preventDefault: () => {}
        })
    }

    const handleChange = (e) => {
        e.preventDefault();
        const name = e.target.name;
        const value = e.target.value;
        let newWorkingRow = JSON.parse(JSON.stringify(workingRow));
        newWorkingRow[name] = value;
        setWorkingRow(newWorkingRow);
    }

    const handleSave = (e) => {
        e.preventDefault();
        setIsSaving(true);
        chimera.callAPI(undefined, `/api/iplists/${listId}/row/${row._id}`, 'PUT', workingRow)
        .then(_ => {
            setIsSaving(false);
            if(onClose) {
                onClose();
            }
            modalContext.setModal(null);
        })
        .catch(err => {
            console.error(err);
            alert("ERROR: Failed to save changes to IP");
            setIsSaving(false);
        })
    }

    return(
        <ModalCancelOnly context={modalContext} onClose={onClose}>
            <h1>Edit IP</h1>
            <FormField
                type="text"
                name="ip"
                label="IP"
                description=""
                value={workingRow.ip}
                handleChange={handleChange}
                onBlur={trimOnBlur}
                disabled={isSaving}
            />
            <FormField
                type="text"
                name="gateway"
                label="Gateway"
                description=""
                value={workingRow.gateway}
                handleChange={handleChange}
                onBlur={trimOnBlur}
                disabled={isSaving}
            />
            <FormField
                type="text"
                name="subnetMask"
                label="Subnet Mask"
                description=""
                value={workingRow.subnetMask}
                handleChange={handleChange}
                onBlur={trimOnBlur}
                disabled={isSaving}
            />
            <FormField
                type="textarea"
                name="notes"
                label="Notes"
                description=""
                value={workingRow.notes}
                handleChange={handleChange}
                onBlur={trimOnBlur}
                disabled={isSaving}
            />
            <button className="btn btn-primary" onClick={handleSave} disabled={isSaving}>
                <i className={isSaving ? "fas fa-spinner" : "fas fa-floppy-disk"}/>&nbsp;{isSaving ? "Saving..." : "Save & Close"}
            </button>
        </ModalCancelOnly>
    )
}

const ImportModal = ({modalContext, onClose, importCallback}) => {

    const callBack = (file, contents) => {
        modalContext.backtrack();
        onClose();
        importCallback(file, contents);
    }

    return (
        <ModalCancelOnly context={modalContext} onClose={onClose}>
            <div>
                <h1>Import IPs</h1>
                <FileUpload
                    label="Upload Document (.csv)"
                    description="Acceptable format: the first 5 columns must be the following in order: POP, IP, Gateway, Subnet Mask, Notes. The header names are irrelevant and their presence is not technically required, but this procedure will only consider rows 2 and onward."
                    callback={callBack}
                />
            </div>
        </ModalCancelOnly>
    );
}

const ClaimModal = ({selectedObjects, modalContext, callback}) => {
    const [claimantName, setClaimantName] = useState('');
    const [isSaving, setIsSaving] = useState(false);

    const handleChange = (event) => {
        if(event.target.name === 'claimantName') {
            setClaimantName(event.target.value);
        }
    }

    const trimOnBlur = (event) => {
        handleChange({
            target: {
                type: "string",
                name: event.target.name,
                value: event.target.value.trim()
            },
            preventDefault: () => {}
        })
    }

    const choices = [
        choiceCancel({backtrack: false, modalContext: modalContext}, false),
        {
            btnColor: 'primary',
            btnInner: <span><i className={isSaving ? 'fas fa-spinner' : 'fas fa-arrow-right'}/>&nbsp;Claim {selectedObjects.length} IP(s)</span>,
            func: (e) => {
                e.preventDefault();
                setIsSaving(true);
                callback(claimantName);
            }
        }
    ]

    return(
        <Modal choices={choices} dismiss={choices[0].func}>
            <FormField
                type="text"
                name="claimantName"
                label="Claimant Name"
                description="This value will be displayed in the 'Used By' column. An automatic prefix will be applied, such that the result will be: '(Admin): [Claimant Name]'"
                value={claimantName}
                handleChange={handleChange}
                onBlur={trimOnBlur}
                disabled={isSaving}
            />
        </Modal>
    )
}

const IPPanel = ({bannerContext}) => {
    const [ipLists, setIpLists] = useState(null);
    const [ips, setIps] = useState(null);
    const [loading, setLoading] = useState(false);
    const modaling = useContext(ModalContext);
    const banners = useContext(BannerContext);
    const userContext = useContext(UserContext);

    useEffect(() => {
        if(!ipLists) {
            setIps(null);
            chimera.callAPI(undefined, '/api/iplists')
            .then(newLists => setIpLists(newLists))
            .catch(err => {
                console.error(err);
                if(bannerContext) {
                    bannerContext.addBanner('danger', 'Failed to read IP Lists', 'IP Management Error');
                }
            })
        }
        else {
            let newIps = [];
            for(const ipList of ipLists) {
                for(const data of ipList.list) {
                    newIps.push({
                        pop: ipList.pop,
                        ip: data.ip,
                        gateway: data.gateway,
                        subnetMask: data.subnetMask,
                        claims: data.claims,
                        notes: data.notes,
                        _id: data._id
                    })
                }
            }
            setIps(newIps);
        }
    }, [ipLists]);

    const handleClickedRow = (row) => {
        let listId = null;
        for(const ipList of ipLists) {
            if(ipList.pop === row.pop) {
                listId = ipList._id;
                break;
            }
        }
        if(listId) {
            const onClose = () => {
                setIpLists(null);
            }
            modaling.setModal(<IPRowModal listId={listId} row={row} modalContext={modaling} onClose={onClose}/>);
        }
        else {
            alert("ERROR: Cannot open form because listId could not be found. Please notify the site administrator.");
        }
    }

    /** TODO: Implement exporting for IPs */
    const openExportModal = (selectedObjects) => {
        modaling.setModal(<ExportModal objects={selectedObjects} model="ip"/>);
    }

    const openImportModal = (_) => {
        modaling.setModal(null);
        const importCallback = async(_, contents) => {
            setLoading(true);
            const csv = atob(contents);
            let data = [];
            const lines = csv.split('\n');
            for(let i = 1; i < lines.length; i++) {
                const tokens = lines[i].split(',');
                if(tokens.length >= 4) {
                    const pop = tokens[0].trim();
                    const ip = tokens[1].trim();
                    const gateway = tokens[2].trim();
                    const subnetMask = tokens[3].trim();
                    let notes = '';
                    if(tokens.length >= 5) {
                        notes = tokens[4].trim();
                    }
                    if(!data.find(row => row.pop === pop && row.ip === ip && row.gateway === gateway && row.subnetMask === subnetMask)) {
                        data.push({
                            pop: pop,
                            ip: ip,
                            gateway: gateway,
                            subnetMask: subnetMask,
                            notes: notes
                        })
                    }
                }
            }
            const dataByPop = {};
            for(const row of data) {
                if(!dataByPop[row.pop]) {
                    dataByPop[row.pop] = [{ip: row.ip, gateway: row.gateway, subnetMask: row.subnetMask, notes: row.notes}];
                }
                else {
                    dataByPop[row.pop].push({ip: row.ip, gateway: row.gateway, subnetMask: row.subnetMask, notes: row.notes});
                }
            }
            let nAdded = 0;
            for(const pop in dataByPop) {
                let done = false;
                for(const ipList of ipLists) {
                    if(ipList.pop === pop) {
                        // See if there are any new rows to add
                        let addRows = [];
                        for(const row of dataByPop[pop]) {
                            if(!ipList.list.find(el => el.ip === row.ip && el.gateway === row.gateway && el.subnetMask === row.subnetMask) && !addRows.find(el => el.ip === row.ip && el.gateway === row.gateway && el.subnetMask === row.subnetMask)) {
                                addRows.push({
                                    ip: row.ip,
                                    gateway: row.gateway,
                                    subnetMask: row.subnetMask,
                                    notes: row.notes
                                });
                            }
                        }
                        if(addRows.length > 0) {
                            const newList = JSON.parse(JSON.stringify(ipList));
                            for(const addRow of addRows) {
                                newList.list.push(addRow);
                            }
                            try {
                                await chimera.callAPI(undefined, `/api/iplists/${newList._id}`, 'PUT', newList);
                                nAdded += addRows.length;
                            }
                            catch(err) {
                                console.error(err);
                                if(err.name !== "AbortError") {
                                    if(err.status === 403) {
                                        banners.addBanner('danger', 'Admin write privileges are required to update IP Lists.', 'Forbidden');
                                    }
                                    else {
                                        banners.addBanner('danger', `Failed to update IP List for POP '${pop}'`, 'Error');
                                    }
                                }
                            }
                        }
                        done = true;
                    }
                }
                if(!done) {
                    // Need to add the whole list
                    try {
                        let ips = [];
                        for(const row of dataByPop[pop]) {
                            if(!ips.find(el => el.ip === row.ip && el.gateway === row.gateway && el.subnetMask === row.subnetMask)) {
                                ips.push(row);
                            }
                        }
                        await chimera.callAPI(undefined, '/api/iplists', 'POST', {pop: pop, list: ips});
                        nAdded += ips.length;
                    }
                    catch(err) {
                        console.error(err);
                        if(err.name !== "AbortError") {
                            if(err.status === 403) {
                                banners.addBanner('danger', 'Admin write privileges are required to create IP Lists.', 'Forbidden');
                            }
                            else {
                                banners.addBanner('danger', `Failed to create IP List for POP ${pop}`, 'Error');
                            }
                        }
                    }
                }
            }
            setIpLists(null);
            setLoading(false);
            banners.addBanner('info', `Imported ${nAdded} new IPs`, 'Success');
        }
        modaling.setModal(<ImportModal modalContext={modaling} onClose={() => {setIpLists(null)}} importCallback={importCallback}/>)
    }

    const confirmDelete = (selectedObjects) => {
        const choices = [
            {
                btnColor: 'secondary',
                btnInner: <span><i className="fas fa-arrow-left"/>&nbsp;Cancel</span>,
                func: (e) => {
                    e.preventDefault();
                    modaling.backtrack();
                }
            },
            {
                btnColor: 'danger',
                btnInner: <span><i className="fas fa-times"/>&nbsp;Delete {selectedObjects.length} IPs</span>,
                func: async(e) => {
                    e.preventDefault();
                    setLoading(true);
                    modaling.backtrack();

                    let ipsByPop = {};
                    for(const ip of selectedObjects) {
                        if(!ipsByPop[ip.pop]) {
                            ipsByPop[ip.pop] = [ip]
                        }
                        else {
                            ipsByPop[ip.pop].push(ip);
                        }
                    }

                    for(const ipList of ipLists) {
                        if(ipsByPop[ipList.pop]) {
                            let newList = JSON.parse(JSON.stringify(ipList));
                            newList.list = ipList.list.filter(ip => !ipsByPop[ipList.pop].find(deletedIp => deletedIp.ip === ip.ip && deletedIp.gateway === ip.gateway && deletedIp.subnetMask === ip.subnetMask));
                            if(newList.list.length > 0) {
                                try {
                                    await chimera.callAPI(undefined, `/api/iplists/${newList._id}`, 'PUT', newList);
                                }
                                catch(err) {
                                    console.error(err);
                                    if(err.name !== 'AbortError') {
                                        if(err.status === 403) {
                                            banners.addBanner('danger', 'Admin write privileges are required to delete IPs.', 'Forbidden');
                                        }
                                        else {
                                            banners.addBanner('danger', 'Failed to delete IPs', 'Error');
                                        }
                                    }
                                }
                            }
                            else {
                                try {
                                    await chimera.callAPI(undefined, `/api/iplists/${ipList._id}`, 'DELETE');
                                }
                                catch(err) {
                                    console.error(err);
                                    if(err.name !== 'AbortError') {
                                        if(err.status === 403) {
                                            banners.addBanner('danger', 'Admin write privileges are required to delete IPs.', 'Forbidden');
                                        }
                                        else {
                                            banners.addBanner('danger', 'Failed to delete IPs', 'Error');
                                        }
                                    }
                                }
                            }
                        }
                    }
                    setIpLists(null);
                    setLoading(false);
                }
            }
        ]
        const modal = <Modal choices={choices} dismiss={choices[0].func}>
            <h3>Are you sure?</h3>
            <p>You are about to delete {selectedObjects.length} IPs permanently from Chimera. <strong>Doing this will remove the IP(s) from any associated Customers.</strong></p>
        </Modal>
        modaling.setModal(modal);
    }

    const openClaimModal = (selectedObjects) => {
        const callback = (claimantName) => {
            chimera.callAPI(undefined, '/api/iplists/claim', 'POST', {
                ids: selectedObjects.map(object => object._id),
                stamp: {
                    objectType: 'Admin',
                    objectId: userContext.user.email,
                    name: `(Admin): ${claimantName}`
                },
                inclusive: true
            })
            .then(_ => {
                banners.addBanner('info', 'IPs claimed successfully', 'Success');
            })
            .catch(err => {
                if(err.name !== "AbortError") {
                    console.error(err);
                    banners.addBanner('danger', 'Failed to claim IPs', 'Error');
                }
            })
            .finally(() => {
                modaling.setModal(null);
                setIpLists(null);
            })
        }
        modaling.setModal(<ClaimModal modalContext={modaling} selectedObjects={selectedObjects} callback={callback}/>);
    }

    const confirmRemoveClaims = (selectedObjects) => {
        console.log(selectedObjects);
        const choices = [
            choiceCancel({backtrack: false, modalContext: modaling}, false),
            {
                btnColor: 'danger',
                btnInner: <span><i className="fas fa-times"/>&nbsp;Remove Claims on {selectedObjects.length} IP(s)</span>,
                func: (e) => {
                    e.preventDefault();
                    setLoading(true);
                    chimera.callAPI(undefined, '/api/iplists/removeclaims', 'POST', {
                        ids: selectedObjects.map(object => object._id),
                    })
                    .then(_ => {
                        banners.addBanner('info', 'Removed claims from IPs', 'Success');
                    })
                    .catch(err => {
                        if(err.name !== "AbortError") {
                            console.error(err);
                            banners.addBanner('danger', 'Failed to remove claims from IPs', 'Error');
                        }
                    })
                    .finally(() => {
                        modaling.setModal(null);
                        setIpLists(null);
                        setLoading(false);
                    })
                }
            }
        ]

        const modal = <Modal choices={choices} dismiss={choices[0].func}>
            <h3>Are you sure?</h3>
            <p>Are you sure you want to remove ALL claims on ALL selected IPs? <strong>This operation cannot be undone.</strong></p>
        </Modal>

        modaling.setModal(modal);
    }

    return (
        <>
        {ipLists === null || ips === null || loading ? 
            <LoadingSpinner size={75}/>
        :
        <ObjectTable 
            id="ipManagementTable"
            cols={[
                {
                    label: 'POP', 
                    sort: (a, b) => a.pop < b.pop ? -1 : 1,
                    render: (obj) => obj.pop
                },
                {
                    label: 'IP',
                    sort: (a, b) => a.ip < b.ip ? -1 : 1,
                    render: (obj) => obj.ip
                },
                {
                    label: 'Gateway',
                    sort: (a, b) => a.gateway < b.gateway ? -1 : 1,
                    render: (obj) => obj.gateway
                },
                {
                    label: 'Subnet Mask',
                    sort: (a, b) => a.subnetMask < b.subnetMask ? -1 : 1,
                    render: (obj) => obj.subnetMask
                },
                {
                    label: 'Used By',
                    sort: (a, b) => a.claims.map(claim => claim.name).join('; ') < b.claims.map(claim => claim.name).join('; ') ? -1 : 1,
                    render: (obj) => obj.claims.length > 0 ? obj.claims.map(claim => claim.name).join('; ') : '(Not Used)'
                },
                {
                    label: 'Notes',
                    sort: (a, b) => a.notes < b.notes ? -1 : 1,
                    render: (obj) => chimera.renderTextWithNewlines(obj.notes)
                }
            ]}
            objects={ips}
            actions={[
                {label: 'Export', func: openExportModal},
                {label: 'Claim for Admin', func: openClaimModal},
                {label: 'Remove Claims', func: confirmRemoveClaims, classes: 'text-danger'},
                {label: 'Delete', func: confirmDelete, classes: 'text-danger'},
            ]}
            filters={[
                {label: 'Show All', value: 'All', func: (_) => true},
            ].concat(ipLists.map(list => {return {label: list.pop, value: list.pop, func: (ip) => ip.pop === list.pop}}))}
            btns={[
                {label: 'Add IPs', func: openImportModal}
            ]}
            onClicked={handleClickedRow}
            search
            paginated
            defaultSortByColName="POP"
            defaultSortAscending={true}
        />
        }
        </>
    )
}

export default IPPanel;