import { useContext, useEffect, useState } from "react";
import chimera from "../chimera";
import BannerContext from "../components/BannerLogContext";
import React from "react";

// opts:
// - includeInactive
/**
 * 
 * @param {*} apiPath 
 * @param {*} noun 
 * @param {*} opts 
 * @returns {[Object, React.Dispatch<React.SetStateAction<null>>]}
 */
function useGet(apiPath, noun, opts) {
    const [objs, setObjs] = useState(null);
    const banners = useContext(BannerContext);

    useEffect(() => {
        if(objs === null) {
            if(!opts) opts = {};
            chimera.callAPI(undefined, apiPath, 'GET', undefined, 'json', opts.includeInactive ? {'x-include-inactive': 'true'} : undefined)
            .then(newObjs => setObjs(newObjs))
            .catch(err => {
                console.error(err);
                if(banners) {
                    banners.addBanner('danger', `Failed to read ${noun}s: ${err.toString()}`, 'Error');
                }
                else {
                    alert(`Error: Failed to read ${noun}s`);
                }
                setObjs([]);
            })
        }
    }, [objs]);

    return [
        objs,
        setObjs
    ]
}

export default useGet;