import React, {useState, useEffect, useLayoutEffect, useContext} from 'react';

import chimera from '../../chimera';
import LoadingSpinner from '../LoadingSpinner';
import ReportTable from '../ReportTable';
import BannerContext, {BannerLog} from '../BannerLogContext';

const Win11CompatibilityBody = props => {
    const [report, setReport] = useState(null);
    const [loading, setLoading] = useState(true);
    const [label, setLabel] = useState("");
    const [saveBtnLabel, setSaveBtnLabel] = useState("Save to Chimera");
    const [saveBtnIcon, setSaveBtnIcon] = useState("fas fa-floppy-disk");
    const [saveBtnDisabled, setSaveBtnDisabled] = useState(false);
    const [controller] = useState(new AbortController());
    const [signal] = useState(controller.signal);
    const banners = useContext(BannerContext);

    useLayoutEffect(() => {
        return () => {
            controller.abort();
        }
    }, []);

    useEffect(() => {
        setLabel("Reading Syncro Assets...");
        chimera.callAPI(signal, '/api/syncro/assets')
        .then(assets => {
            setLabel("Reading Chimera Customers...");
            chimera.callAPI(signal, '/api/customers')
            .then(customers => {
                const cols = ["Customer", "Device Name", "Link", "OS", "Win 11 Compatible", "Manufacturer", "Model", "RAM", "CPU Name"];
                let rows = [];
                const isWin10 = (asset) => {
                    let os = chimera.getAttr(asset, 'properties.kabuto_information.os.name');
                    if(!os) return false;
                    return os.indexOf('Windows 10') > -1;
                }
                const customerName = (asset) => {
                    const customer = customers.find(c => c.integrationIds.syncro === asset.customer_id.toString());
                    if(!customer) return "(No match in Chimera)";
                    return customer.displayName;
                }
                const blankIfUndefined = (str) => {
                    if(str === undefined) return "";
                    else return str;
                }
                const sortRows = (a,b) => {
                    if(a['Customer'] === '(No match in Chimera)') return 1;
                    if(b['Customer'] === '(No match in Chimera)') return -1;
                    return a['Customer'] < b['Customer'] ? -1 : 1
                }
                const win10Assets = assets.filter(asset => isWin10(asset));
                for(const asset of win10Assets) {
                    rows.push({
                        "Customer": customerName(asset),
                        "Device Name": blankIfUndefined(chimera.getAttr(asset, 'properties.device_name')),
                        "Link": `https://gocbit.syncromsp.com/customer_assets/${asset.id}`,
                        "OS": blankIfUndefined(chimera.getAttr(asset, 'properties.kabuto_information.os.name')),
                        "Win 11 Compatible": blankIfUndefined(chimera.getAttr(asset, 'properties.Win 11 Compatible')),
                        "Manufacturer": blankIfUndefined(chimera.getAttr(asset, 'properties.manufacturer')),
                        "Model": blankIfUndefined(chimera.getAttr(asset, 'properties.model')),
                        "RAM": chimera.getAttr(asset, 'properties.kabuto_information.ram_gb') ? `${chimera.getAttr(asset, 'properties.kabuto_information.ram_gb')} GB` : '',
                        "CPU Name": blankIfUndefined(chimera.getAttr(asset, 'properties.kabuto_information.cpu[0].name'))
                    })
                }
                setReport({type: "Win11Compatibility", cols: cols, rows: rows.sort(sortRows)});
                setLoading(false);
            })
            .catch(err => {
                if(err.name !== "AbortError") {
                    if(err.details) {
                        console.error(err.details);
                    }
                    else {
                        console.error(err);
                    }
                    banners.addBanner('danger', 'Could not read Chimera Customers. The job will not continue.', 'Error');
                }
            })
        })
        .catch(err => {
            if(err.name !== "AbortError") {
                if(err.details) {
                    console.error(err.details);
                }
                else {
                    console.error(err);
                }
                banners.addBanner('danger', 'Could not read Syncro Assets. The job will not continue.', 'Error');
            }
        })
    }, []);

    const saveReport = (event) => {
        event.preventDefault();
        setSaveBtnDisabled(true);
        setSaveBtnIcon("fas fa-spinner");
        setSaveBtnLabel("Saving...");
        chimera.callAPI(signal, '/api/reporttables', 'POST', report)
        .then(_ => {
            setSaveBtnIcon("fas fa-check");
            setSaveBtnLabel("Saved!");
        })
        .catch(err => {
            if(err.name !== "AbortError") {
                console.error(err);
                banners.addBanner('danger', 'An error occurred and the report could not be saved', 'Error');
                setSaveBtnDisabled(false);
                setSaveBtnIcon("fas fa-floppy-disk");
                setSaveBtnLabel("Save to Chimera");
            }
        })
    }

    return (
        <>
        {loading ?
            <LoadingSpinner size={75} label={label}/>
        :
            <>
            <button className="btn btn-primary mb-2" onClick={saveReport} disabled={saveBtnDisabled}>
                <i className={saveBtnIcon}/>
                &nbsp;{saveBtnLabel}
            </button>
            <ReportTable report={report}/>
            </>
        }
        </>
    )
}

const Win11Compatibility = props => {
    return (
        <BannerLog>
            <Win11CompatibilityBody/>
        </BannerLog>
    )
}

export default Win11Compatibility;