import Modal from "./components/Modal";

const DEFAULT_ADDRESS = {
    street1: '',
    street2: '',
    city: '',
    county: '',
    state: 'WA',
    zip: ''
}

const DEFAULT_POC = {
    firstName: '',
    lastName: '',
    email: '',
    phone: ''
}

const DEFAULT_LOCATION = {
    nickname: '',
    poc: DEFAULT_POC,
    notificationEmails: [],
    businessPhone: '',
    serviceAddress: DEFAULT_ADDRESS,
    shippingAddress: DEFAULT_ADDRESS,
    serviceTypes: {
        msp: false,
        internet: false,
        voip: false,
        video: false
    },
    technical: {
        networkSummary: '',
        managementIp: '',
        cids: [],
        ips: [],
        gateways: [],
        subnetMasks: [],
        dhcp: false,
        pocs: [],
        pops: [],
        idrac: false,
        voipLines: 0,
        equipmentLocation: '',
        servicePort: '',
        emailSentDate: null,
        service: 'NOT SET',
        provider: 'NOT SET',
        general: {
            isps: [],
            ispSupportNumber: '',
            ispSupportEmail: '',
            wanIp: '',
            wanSubnet: '',
            wanGateway: '',
            emailProvider: '',
            cloudHostingProvider: '',
            voipProvider: '',
            registrar: '',
            domianName: '',
            dental: false
        },
        dental: {
            pms: '',
            pmsAccountNumber: '',
            pmsSupportNumber: '',
            xrayVendor2D: '',
            xrayVendor2DSupportNumber: '',
            xrayVendor2DAccountNumber: '',
            xrayVendor3D: '',
            xrayVendor3DSupportNumber: '',
            xrayVendor3DAccountNumber: ''
        },
        softwareVendors: []
    }
}

const DEFAULT_CUSTOMER = {
    accountNumber: 0,
    standing: 'good',
    businessName: '',
    displayName: '',
    formerlyKnownAs: [],
    billingPhone: '',
    accountsPayableEmails: [""],
    billingAddress: DEFAULT_ADDRESS,
    endpointPackages: {
        servers: 0,
        workstations: {
            nPlat: 0,
            nGold: 0,
            nSilv: 0
        }
    },
    billingPocs: [],
    taxExempt: false,
    integrationIds: {
        quickbooks: '',
        syncro: '',
        bitdefender: '',
        unity: '',
        pax8: '',
        duo: ''
    },
    laborCreditHours: 0,
    onCallAfterHours: 'Business Hours Only',
    standardRate: 80.0,
    afterHoursRate: 80.0,
    technicianId: '',
    assignedTechnicians: {
        primary: {
            first: '',
            last: '',
            email: ''
        },
        backup: {
            first: '',
            last: '',
            email: ''
        }
    },
    locations: [DEFAULT_LOCATION],
    tags: [],
    createdBy: '',
    modifiedBy: '',
    _id: '',
    __v: 0,
    active: true,
    createdAt: new Date(),
    updatedAt: new Date()
}


/**
 * Sleep for a given amount of time
 * @param {number} ms The number of milliseconds to sleep for
 * @returns An awaitable Promise that resolves when `ms` milliseconds have elapsed
 */
function sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
}

/**
 * An easy way to handle Syncro-specific API calls
 * @param {string} method The HTTP method
 * @param {string} path The endpoint path minus its prefix (`/api/syncro`)
 * @param {object} body (optional) the HTTPS request body
 * @param {function} startSleepingCallback (optional) A parameterless function called in the event that this function begins sleeping to wait out Syncro API limitations
 * @param {function} stopSleepingCallback (optional) A parameterless function called in the event that this function finishes sleeping after waiting out Syncro API limitations
 * @returns An awaitable Promise that resolves with the response body
 */
function callSyncroAPI(signal, method, path, body, startSleepingCallback, stopSleepingCallback) {
    return new Promise(async(resolve, reject) => {
        let opts = {
            method: method,
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'X-From-Page': window.location.pathname
            }
        }
        if(body) {
            opts.body = JSON.stringify(body);
        }
        if(signal) {
            opts.signal = signal;
        }
        const requestPath = `/api/syncro${path.startsWith('/') ? path : `/${path}`}`;
        try {
            let res = await fetch(requestPath, opts);
            while(res.status === 429) {
                if(startSleepingCallback) {
                    startSleepingCallback();
                }
                await sleep(60000); // 1 minute
                res = await fetch(requestPath, opts);
                if(res.status !== 429 && stopSleepingCallback) {
                    stopSleepingCallback();
                }
            }
            if(res.status === 200) {
                const response = await res.text();
                try {
                    const json = JSON.parse(response);
                    resolve(json);
                }
                catch(e) {
                    resolve(response);
                }
            }
            else {
                const response = await res.text();
                let details = null;
                try {
                    details = JSON.parse(response);
                }
                catch (e) {
                    details = response;
                }
                finally {
                    if(res.status === 401) {
                        const url = window.location.toString();
                        window.open(url, '_self');
                    }
                    reject({status: res.status, details: details});
                }
            }
        }
        catch(err) {
            reject(err);
        }
    })
}

/**
 * A generic function for accessing the Chimera API
 * @param {string} endpoint The full API endpoint path
 * @param {string} method (optional) The HTTP method (defaults to `'GET'`)
 * @param {object} body (optional) The HTTPS request body
 * @param {string} parseAs (optional) The name of the function the response object should be parsed as (`'text'`, `'json'`, `'blob'`, etc.)
 * @returns An awaitable Promise that resolves with the response body
 */
function callAPI(signal, endpoint, method, body, parseAs, headers) {
    return new Promise((resolve, reject) => {
        if(!method)
            method = 'GET';
        if(!parseAs)
            parseAs = 'json';
        let opts = {
            method: method,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'X-From-Page': window.location.pathname
            }
        }
        for(const key in headers) {
            opts.headers[key] = headers[key];
        }
        if(body)
            opts.body = JSON.stringify(body);
        if(signal)
            opts.signal = signal;
        
        fetch(endpoint, opts)
        .then(res => {
            if(res.status === 200 || res.status === 302) {
                resolve(res[parseAs]());
            }
            else if(res.status === 401) {
                const url = window.location.toString();
                window.open(url, '_self');
                res.text()
                .then(text => {
                    try {
                        const json = JSON.parse(text);
                        reject({status: res.status, details: json});
                    }
                    catch(e) {
                        reject({status: res.status, details: text});
                    }
                })
            }
            else {
                res.text()
                .then(text => {
                    try {
                        const json = JSON.parse(text);
                        reject({status: res.status, details: json});
                    }
                    catch(e) {
                        reject({status: res.status, details: text});
                    }
                })
            }
        })
        .catch(e => {
            if(e.name === "AbortError") {
                //console.log("Aborted");
            }
            reject(e);
        })
    })
}

const phoneNumberStr = (n) => {
    if(!n) return "";
    let _n = n.replace(/\D/g, '');
    if(_n.length === 10) {
        const first3 = _n.substring(0,3);
        const second3 = _n.substring(3,6);
        const last4 = _n.substring(6);
        return `(${first3}) ${second3}-${last4}`;
    }
    else {
        const countryCode = _n.substring(0,1);
        const first3 = _n.substring(1,4);
        const second3 = _n.substring(4,7);
        const last4 = _n.substring(7);
        return `+${countryCode} (${first3}) ${second3}-${last4}`;
    }
}

const addressStr = (address) => {
    return `${address.street1}${address.street2 ? ` ${address.street2}` : null}, ${address.city}, ${address.county} County, ${address.state} ${address.zip}`;
}

const customerToSyncroFields = (customerInput, residential) => {
    return new Promise(async(resolve, reject) => {
        let techNotes = [];
        const customer = residential ? customerInput : CommercialCustomer.copy(customerInput, true);
        if(customer._id) {
            try {
                const notes = await callAPI(undefined, `/api/notes/refId/${customer._id}`);
                const categories = await callAPI(undefined, '/api/notecategories');
                const techCatIds = categories.filter(cat => cat.technical).map(cat => cat._id);
                for(const note of notes) {
                    for(const id of note.categories) {
                        if(techCatIds.includes(id)) {
                            techNotes.push(note);
                        }
                    }
                }
            }
            catch(e) {
                reject(e);
            }
        }

        let customerType = '';
        const internet = customer.serviceTypes.internet;
        const voip = customer.serviceTypes.voip;
        const msp = customer.serviceTypes.msp;
        if(internet && !voip && !msp) {
            customerType = '39134';
        }
        else if(!internet && voip && !msp) {
            customerType = '39135';
        }
        else if(!internet && !voip && msp) {
            customerType = '39136';
        }
        else if(internet && voip && !msp) {
            customerType = '39137';
        }
        else if(internet && !voip && msp) {
            customerType = '39138';
        }
        else if(!internet && voip && msp) {
            customerType = '39139';
        }
        else if(internet && voip && msp) {
            customerType = '39140';
        }
        
        let onCallAfterHours = '39141';
        if(!residential) {
            switch(customer.onCallAfterHours) {
                case 'Extended Weekdays':
                    onCallAfterHours = '39142';
                    break;
                case 'Extended Weekdays + Weekends':
                    onCallAfterHours = '39143';
                    break;
                default:
                    onCallAfterHours = '39141'; // Business Hours Only
                    break;
            }
        }

        const properties = {
            "Network Summary": residential ? "" : customer.technical.networkSummary,
            "POP": residential ? "" : customer.technical.pops.join('\n\n'),
            "IDRAC": residential ? "" : customer.technical.idrac ? '39368' : '39369', // Using dropdown values from Syncro
            "Gateway": customer.technical.gateways.join('\n\n'),
            "Circuit ID": residential ? "" : customer.technical.cids.join('\n\n'),
            "IP Address": customer.technical.ips.join('\n\n'),
            "Subnet Mask": customer.technical.subnetMasks.join('\n\n'),
            "Technical POC": residential ? "" : customer.technical.pocs.map(poc => `${poc.firstName}${poc.lastName ? ' ' : ''}${poc.lastName}${poc.firstName || poc.lastName ? '\n' : ''}${poc.email}${poc.phone ? `\n${phoneNumberStr(poc.phone)}` : ''}`).join('\n\n'),
            "CPE IP Address": residential ? "" : customer.technical.managementIp,
            "On-Call/After Hours": onCallAfterHours
        }

        if(customerType !== '') {
            properties['Customer Type '] = customerType; // Extra space in key is Syncro quirk (technically it is in the field's name)
        }

        if(techNotes) {
            properties['Notes'] = techNotes.map(note => `${note.title}:\n${note.text}`).join('\n\n\n');
        }
        else {
            properties['Notes'] = '';
        }

        console.log(customer);
        
        resolve({
            business_name: residential ? null : customer.displayName,
            email: residential ? customer.email : customer.poc.email,
            firstname: residential ? customer.firstName : customer.poc.firstName,
            lastname: residential ? customer.lastName : customer.poc.lastName,
            phone: residential ? customer.phone : customer.poc.phone,
            address: residential ? customer.homeAddress.street1 : customer.serviceAddress.street1,
            address_2: residential ? customer.homeAddress.street2 : customer.serviceAddress.street2,
            city: residential ? customer.homeAddress.city : customer.serviceAddress.city,
            state: residential ? customer.homeAddress.state : customer.serviceAddress.state,
            zip: residential ? customer.homeAddress.zip : customer.serviceAddress.zip,
            properties: properties
        });
    })
}

function dollarStr(num) {
    return `${num < 0 ? '- ' : ''}$${Math.abs(num).toFixed(2)}`;
}

function dollarStrToNum(str) {
    if(str === '') return 0;
    let _str = str.trim();
    if(_str.startsWith('$')) _str = _str.substring(1);
    let num = parseFloat(_str);
    if(num.toString() === 'NaN') return 0;
    return num;
}

const traverse = (obj, path, value)  => {
    const delimRegex = /(\.|(\[[0-9]+\]))/g;
    const indexRegex = /(\[[0-9]+\])/g;
    const delims = path.match(delimRegex);
    const pathFrags = path.split(delimRegex).filter(term => term !== undefined && term !== "" && !term.match(delimRegex));

    let parent = obj;
    let indexAtEnd = delims !== null && delims[delims.length-1].match(indexRegex);

    try {
        for(let i = 0; i < pathFrags.length - (value === undefined ? 0 : 1); i++) {
            if(delims !== null && delims[i] && delims[i].match(indexRegex)) {
                // Access the index
                parent = parent[pathFrags[i]];
                const index = parseInt(delims[i].replace('[', '').replace(']', ''));
                parent = parent[index];
            }
            else {
                parent = parent[pathFrags[i]];
            }
        }
    
        if(value !== undefined) {
            if(indexAtEnd) {
                parent = parent[pathFrags[pathFrags.length-1]];
                const index = parseInt(delims[delims.length - 1].replace('[', '').replace(']', ''));
                parent[index] = value;
            }
            else {
                parent[pathFrags[pathFrags.length-1]] = value;
            }
            return parent;
        }
        else {
            return parent;
        }
    }
    catch(e) {
        if(value) {
            console.warn("setAttr: destination path is undefined, no value set.");
        }
        return undefined;
    }
}

const getAttr = (obj, path) => {
    return traverse(obj, path);
}

const setAttr = (obj, path, value) => {    
    return traverse(obj, path, value);
}

const deepEqual = (a,b) => {
    if( (typeof a == 'object' && a != null) &&
        (typeof b == 'object' && b != null) )
    {
        if(JSON.stringify(a).startsWith('[') && JSON.stringify(b).startsWith('[')) {
            // Both are arrays
            if(a.length !== b.length) { 
                return false;
            }
            for(let i = 0; i < a.length; i++) {
                if(!deepEqual(a[i], b[i])) {
                    return false;
                }
            }
        }
        else if(JSON.stringify(a).startsWith('[') || JSON.stringify(b).startsWith('[')) {
            // Just one is an array
            return false;
        }
        else {
            // Both are normal objects
            let count = [0,0];
            for(let key in a) count[0]++;
            for(let key in b) count[1]++;
            if(count[0] - count[1] !== 0) { 
                return false;
            }
            for(const key in a) {
                if(!(key in b) || !deepEqual(a[key],b[key])) {
                    return false;
                }
            }
            for(const key in b) {
                if(!(key in a) || !deepEqual(b[key],a[key])) {
                    return false;
                }
            }
        }
        return true;
    }
    else {
        return a === b;
    }
}

const deleteCustomer = async(customer, options, bannersContext, bannersCopy, signal) => {
    // NOTE: JSX expressions MUST be avoided for banners destined for sessionStorage, such as those that go into bannersCopy.
    const customerName = options.residential ? `${customer.firstName} ${customer.lastName}` : customer.displayName;
    if((options.everywhere || options.syncro) && customer.integrationIds.syncro) {
        try {
            await chimera.callSyncroAPI(signal, 'DELETE', `/customers/${customer.integrationIds.syncro}`); // TODO: Include wait indicators
        }
        catch(e) {
            console.error(e);
            const banner = {
                type: 'danger',
                message: `Could not delete ${customerName} in Syncro.`,
                header: 'Syncro Error'
            }
            if (bannersContext) bannersContext.addBanner(banner.type, banner.message, banner.header);
            if (bannersCopy !== null && bannersCopy !== undefined) bannersCopy.push(banner);
        }
    }
    if((options.everywhere || options.quickbooks) && customer.integrationIds.quickbooks) {
        try {
            await chimera.callQuickBooksAPI(signal, `/api/qb/customer/${customer.integrationIds.quickbooks}`, 'DELETE');
        }
        catch(e) {
            console.error(e);
            const response = e.body;
            if(response.Fault && response.Fault.type === "ValidationFault" && response.Fault.Error.length > 0 && response.Fault.Error[0].Message === "Delete List Has Balance Error") {
                const banner = {
                    type: 'danger',
                    message: `Could not deactive ${customerName} in QuickBooks because the customer has a non-zero balance.`,
                    header: 'QuickBooks Error'
                }
                if (bannersContext) bannersContext.addBanner(banner.type, banner.message, banner.header);
                if (bannersCopy !== null && bannersCopy !== undefined) bannersCopy.push(banner);
            }
            else {
                const banner = {
                    type: 'danger',
                    message: `Could not deactivate ${customerName} in QuickBooks.`,
                    header: 'QuickBooks Error'
                }
                if (bannersContext) bannersContext.addBanner(banner.type, banner.message, banner.header);
                if (bannersCopy !== null && bannersCopy !== undefined) bannersCopy.push(banner);
            }
        }
    }
    if((options.everywhere || options.unity) && customer.integrationIds.unity) {
        try {
            await chimera.callAPI(signal, '/api/unity/domains/delete', 'POST', {domain: customer.integrationIds.unity});
        }
        catch(e) {
            console.error(e);
            const banner = {
                type: 'danger',
                message: `Could not delete domain ${customer.integrationIds.unity} in Unity.`,
                header: 'Unity Error'
            }
            if (bannersContext) bannersContext.addBanner(banner.type, banner.message, banner.header);
            if (bannersCopy !== null && bannersCopy !== undefined) bannersCopy.push(banner);
        }
    }
    if((options.everywhere || options.pax8) && customer.integrationIds.pax8) {
        // TODO: Create a ticket instead of an email
        // Pax8 has no "Delete Company" API route, so this is the best we can do.
        try {
            await chimera.callAPI(signal, '/api/sendmail', 'POST', {
                email: "jaden@gocbit.com",
                subject: `Chimera Customer ${customerName} has been deleted`,
                text: `The Chimera Customer "${customerName}" has been deleted. Their record still persists in Pax8. If appropriate, please delete the Pax8 company manually.`
            })
        }
        catch(e) {
            console.error(e);
            const banner = {
                type: 'danger',
                message: `${customerName} cannot be deleted from Pax8 automatically, and the email alerting the manager(s) to do it manually failed to send. Please contact your IT Manager(s).`,
                header: 'Pax8 Error'
            }
            if (bannersContext) bannersContext.addBanner(banner.type, banner.message, banner.header);
            if (bannersCopy !== null && bannersCopy !== undefined) bannersCopy.push(banner);
        }
    }
    if((options.everywhere || options.duo) && customer.integrationIds.duo) {
        try {
            await chimera.callAPI(signal, `/api/duo/accounts/${customer.integrationIds.duo}`, 'DELETE');
        }
        catch(e) {
            console.error(e);
            const banner = {
                type: 'danger',
                message: `Could not delete ${customerName} in Duo.`,
                header: 'Duo Error',
            }
            if (bannersContext) bannersContext.addBanner(banner.type, banner.message, banner.header);
            if (bannersCopy !== null && bannersCopy !== undefined) bannersCopy.push(banner);
        }
    }
    if((options.everywhere || options.chimera)) {
        try {
            await chimera.callAPI(signal, `/api/${options.residential ? 'residential' : ''}customers/${customer._id}`, 'DELETE');
            return true;
        }
        catch(e) {
            console.error(e);
            const banner = {
                type: 'danger',
                message: `Failed to delete customer # ${customer.accountNumber} (${customerName})`,
                header: 'Error'
            }
            if (bannersContext) bannersContext.addBanner(banner.type, banner.message, banner.header);
            if (bannersCopy !== null && bannersCopy !== undefined) bannersCopy.push(banner);
        }
    }
    return false;
}

const encodeObj = (obj) => {
    return window.btoa(JSON.stringify(obj));
}

const decodeObj = (str) => {
    return JSON.parse(window.atob(str));
}

const parseParams = (str) => {
    let s = str.substring(1); // trim '?'
    let obj = {};
    const terms = s.split('&');
    for(const term of terms) {
        const [key, value] = term.split('=');
        obj[key] = value;
    }
    return obj;
}

const pushToSyncro = (customer, startSleepingCallback, stopSleepingCallback, signal, residential) => {
    return new Promise((resolve, reject) => {
        customerToSyncroFields(customer, residential)
        .then(syncroBody => {
            chimera.callSyncroAPI(signal, 'PUT', `/customers/${customer.integrationIds.syncro}`, syncroBody, startSleepingCallback, stopSleepingCallback)
            .then(response => resolve(response))
            .catch(e => reject(e))
        })
        .catch(err => {
            reject(err);
        })
    })
}

const pushToQuickBooks = (customer, startSleepingCallback, stopSleepingCallback, signal, residential) => {
    return new Promise((resolve, reject) => {
        const body = {
            DisplayName: residential ? `${customer.firstName} ${customer.lastName}` : customer.displayName,
            BillAddr: {
                CountrySubDivisionCode: residential ? customer.mailAddress.state : customer.billingAddress.state,
                City: residential ? customer.mailAddress.city : customer.billingAddress.city,
                PostalCode: residential ? customer.mailAddress.zip : customer.billingAddress.zip,
                Line1: residential ? customer.mailAddress.street1 : customer.billingAddress.street1,
                Country: "USA"
            },
            ShipAddr: {
                CountrySubDivisionCode: residential ? customer.mailAddress.state : customer.shippingAddress.state,
                City: residential ? customer.mailAddress.state : customer.shippingAddress.city,
                PostalCode: residential ? customer.mailAddress.zip : customer.shippingAddress.zip,
                Line1: residential ? customer.mailAddress.street1 : customer.shippingAddress.street1,
                Country: "USA"
            },
            PrimaryEmailAddr: {
                Address: residential ? customer.email : customer.accountsPayableEmails.join(', ')
            },
            PrimaryPhone: {
                FreeFormNumber: chimera.phoneNumberStr(residential ? customer.phone : customer.billingPhone)
            }
        }

        if(residential) {
            body.GivenName = customer.firstName;
            body.FamilyName = customer.lastName;
            if (customer.mailAddress.street2) {
                body.BillAddr.Line2 = customer.mailAddress.street2;
                body.ShipAddr.Line2 = customer.mailAddress.street2;
            }
        }
        else {
            body.CompanyName = customer.businessName;
            if (customer.billingAddress.street2) body.BillAddr.Line2 = customer.billingAddress.street2;
            if (customer.shippingAddress.street2) body.ShipAddr.Line2 = customer.shippingAddress.street2;
        }

        chimera.callAPI(signal, `/api/qb/customer/${customer.integrationIds.quickbooks}`, 'PUT', body)
        .then(response => resolve(response))
        .catch(e => reject(e));
    })
}

const pushToUnity = (customer) => {
    return new Promise((resolve, reject) => {
        // Nothing goes to Unity at the moment.
        resolve("ok")
    })
}

const pushToPax8 = (customer) => {
    return new Promise((resolve, reject) => {
        // Cannot automate and don't want to spam emails when this function is called in mass.
        resolve("ok")
    })
}

const pushToDuo = (customer) => {
    return new Promise((resolve, reject) => {
        // Cannot automate and don't want to spam emails when this function is called in mass.
        resolve("ok")
    })
}

/**
 * A function for accessing the QuickBooks API
 * @param {string} endpoint The full API endpoint path
 * @param {string} method (optional) The HTTP method (defaults to `'GET'`)
 * @param {object} body (optional) The HTTPS request body
 * @param {string} parseAs (optional) The name of the function the response object should be parsed as (`'text'`, `'json'`, `'blob'`, etc.)
 * @returns An awaitable Promise that resolves with the response body
 */
function callQuickBooksAPI(signal, endpoint, method, body, opts, parseAs) {
    if(!endpoint.startsWith('/api/qb')) {
        endpoint = `/api/qb/${endpoint.startsWith('/') ? endpoint.substring(1) : endpoint}`;
    }
    return new Promise((resolve, reject) => {
        let headers = {
            "x-qb-account": opts && opts.residential ? "3RIVERS" : "CBIT"
        };
        callAPI(signal, endpoint, method, body, parseAs, headers)
        .then(response => resolve(response))
        .catch(err => {
            if(err.name !== "AbortError") {
                if(err.status === 409) {
                    const authUri = err.details.authUri;
                    console.log(authUri);
                    alert("QuickBooks integration has expired. Please notify the site administrator.");
                    reject({status: err.status, details: err.details});
                }
                else {
                    reject({status: err.status, details: err.details});
                }
            }
            else {
                reject(err);
            }
        })
    })
}

const validateCustomer = async(signal, customer, banners, residential) => {
    try {
        const validationCheck = await chimera.callAPI(signal, `/api/${residential ? 'residential' : ''}customers/validate`, 'POST', residential ? customer : customer.rawCustomerData);
        if(!validationCheck.valid) {
            let bannerContents = [];
            for(const key in validationCheck.reason.errors) {
                const error = validationCheck.reason.errors[key];
                if(error.name === "ValidatorError") {
                    if(error.kind === "required") {
                        bannerContents.push(<span><strong>Invalid Field Value ({key})</strong>&nbsp;This field is required.</span>);
                    }
                    else if(error.kind === "user defined") {
                        bannerContents.push(<span><strong>Invalid Field Value ({key})</strong>&nbsp;{error.message}</span>);
                    }
                }
            }
            if (banners) banners.addBanners('danger', bannerContents);
        }
        return(validationCheck.valid);
    }
    catch(e) {
        if(e.name !== "AbortError") {
            console.error(e);
            banners.addBanner('danger', 'Failed to perform customer validation', 'Error');
        }
        return false;
    }
}

const createCustomer = async(signal, customer, banners, opts) => {
    const customerName = opts.residential ? `${customer.firstName} ${customer.lastName}` : customer.displayName;
    const willSyncro = opts.willSyncro;
    const willQb = opts.willQb;
    const willUnity = opts.willUnity;
    const willPax8 = opts.willPax8;
    const willDuo = opts.willDuo;
    const integrationIds = {
        syncro: "",
        quickbooks: "",
        unity: opts.unityDomain ? opts.unityDomain : "",
        pax8: "",
        duo: ""
    }

    const cleanup = async() => {
        if(willSyncro && integrationIds.syncro) {
            try {
                await callSyncroAPI(
                    signal,
                    'DELETE',
                    `/customers/${integrationIds.syncro}`,
                    null,
                    opts.startSleepingCallback,
                    opts.stopSleepingCallback);
            }
            catch(e) {
                if(e.name !== "AbortError") {
                    console.error(e);
                    banners.addBanner('warning', 'Could not clean up the created record for this platform. You may want to delete it manually or uncheck this platform in the "Create Records" section to avoid attempting to create a duplicate when you resubmit.', 'Desync Warning (Syncro)');
                }
            }
        }
        if(willQb && integrationIds.quickbooks) {
            try {
                await callQuickBooksAPI(signal, `/api/qb/customer/${integrationIds.quickbooks}`, 'DELETE', null, {residential: opts.residential});
            }
            catch(e) {
                if(e.name !== "AbortError") {
                    console.error(e);
                    banners.addBanner('warning', 'Could not clean up the created record for this platform. You may want to delete it manually or uncheck this platform in the "Create Records" section to avoid attempting to create a duplicate when you resubmit.', 'Desync Warning (QuickBooks)');
                }
            }
        }
        if(willUnity && integrationIds.unity) {
            try {
                await callAPI(signal, '/api/unity/domains/delete', 'POST', {domain: integrationIds.unity});
            }
            catch(e) {
                if(e.name !== "AbortError") {
                    console.error(e);
                    banners.addBanner('warning', 'Could not clean up the created record for this platform. You may want to delete it manually or uncheck this platform in the "Create Records" section to avoid attempting to create a duplicate when you resubmit.', 'Desync Warning (Unity)');
                }
            }
        }
        if(willPax8 && integrationIds.pax8) {
            banners.addBanner('warning', 'Could not clean up the created record for this platform. You may want to delete it manually or uncheck this platform in the "Create Records" section to avoid attempting to create a duplicate when you resubmit.', 'Desync Warning (Pax8)');
        }
        if(willDuo && integrationIds.duo) {
            try {
                await callAPI(signal, `/api/duo/accounts/${integrationIds.duo}`, 'DELETE');
            }
            catch(e) {
                if(e.name !== "AbortError") {
                    console.error(e);
                    banners.addBanner('warning', 'Could not clean up the created record for this platform. You may want to delete it manually or uncheck this platform in the "Create Records" section to avoid attempting to create a duplicate when you resubmit.', 'Desync Warning (Duo)');
                }
            }
        }
        if(opts.cleanupCallback) opts.cleanupCallback();
    }

    if(willSyncro) {
        try {
            const syncroBody = await customerToSyncroFields(customer, opts.residential);
            const syncroCustomer = (await callSyncroAPI(
                signal,
                'POST',
                '/customers',
                syncroBody,
                opts.startSleepingCallback,
                opts.stopSleepingCallback)
            ).customer;
            integrationIds.syncro = syncroCustomer.id;
        }
        catch(e) {
            if(e.name !== "AbortError") {
                console.error(e);
                if(e.status === 422 && e.details && e.details.data.message && e.details.data.message[0] === "Email has already been taken") {
                    banners.addBanner('danger', <span><b>Syncro Error:</b> You can't use that POC Email since it is already taken for another Syncro customer. The customer creation failed for Syncro and will not continue for Chimera.</span>);
                }
                else {
                    banners.addBanner('danger', <span><b>Syncro Error:</b> The customer could not be created in Syncro. It will not be created in Chimera.</span>);
                }
                await cleanup();
            }
            throw e;
        }
    }

    /**
    if(willUnity) {
        try {
            await callAPI(signal, '/api/unity/domains', 'POST', {
                domain: opts.unityDomain,
                territory: "CBIT",
                description: customerName
            }, 'text');
            integrationIds.unity = opts.unityDomain;
        }
        catch(e) {
            if(e.name !== "AbortError") {
                console.error(e);
                banners.addBanner('danger', <span><b>Unity Error:</b> The domain could not be created in Unity. The customer will not be created in Chimera.</span>);
                await cleanup();
            }
            throw e;
        }
    }
    */

    if(willDuo) {
        try {
            const duoAccount = await callAPI(signal, '/api/duo/accounts', 'POST', {name: customerName});
            integrationIds.duo = duoAccount.account_id;
        }
        catch(e) {
            if(e.name !== "AbortError") {
                console.error(e);
                banners.addBanner('danger', <span><b>Duo Error:</b> The account could not be created in Duo. The customer will not be created in Chimera.</span>);
                await cleanup();
            }
            throw e;
        }
    }

    // Pax8 is second to last since it cannot be cleaned up automatically, although it can be manually, unlike QB.
    if(willPax8) {
        try {
            const body = {
                name: customerName,
                address: {
                    street: opts.residential ? customer.homeAddress.street1 : customer.billingAddress.street1,
                    city: opts.residential ? customer.homeAddress.street1 : customer.billingAddress.city,
                    postalCode: opts.residential ? customer.homeAddress.street1 : customer.billingAddress.zip,
                    country: "US",
                    stateOrProvince: opts.residential ? customer.homeAddress.street1 : customer.billingAddress.state
                },
                phone: opts.residential ? customer.phone : customer.businessPhone,
                website: opts.pax8Domain,
                billOnBehalfOfEnabled: false,
                selfServiceAllowed: false,
                orderApprovalRequired: false
            }
            body.address.street2 = opts.residential ? customer.homeAddress.street2 : customer.billingAddress.street2;
            const pax8Company = await callAPI(signal, '/api/pax8/companies', 'POST', body);
            integrationIds.pax8 = pax8Company.id;
        }
        catch(e) {
            if(e.name !== "AbortError") {
                console.error(e);
                if(e.status === 422) {
                    const response = e.details;
                    if(response.data && response.data.errors && response.data.errors.length > 0 && response.data.errors[0].includes("This domain already exists in our system.")) {
                        banners.addBanner('danger', <span><b>Pax8 Error:</b> The company could not be created in Pax8 because <strong>the given Pax8 Domain is already in use.</strong> The customer will not be created in Chimera.</span>);
                    }
                    else {
                        banners.addBanner('danger', <span><b>Pax8 Error:</b> The company could not be created in Pax8. The customer will not be created in Chimera.</span>);
                    }
                }
                else {
                    banners.addBanner('danger', <span><b>Pax8 Error:</b> The company could not be created in Pax8. The customer will not be created in Chimera.</span>);
                }
                await cleanup();
            }
            throw e;
        }
    }
    
    // QuickBooks goes last because its records are not deleted, only made "inactive". The names of 'deleted' (inactivated) Customers cannot be reused.
    if(willQb) {
        try {
            // TODO: Set up a recurring invoice for endpointPackages if any are greater than 0.
            const qbBody = {
                DisplayName: customerName,
                PrimaryPhone: {
                    FreeFormNumber: phoneNumberStr(opts.residential ? customer.phone : customer.billingPhone)
                },
                PrimaryEmailAddr: {
                    Address: opts.residential ? customer.email : customer.accountsPayableEmails.join(', ')
                },
                BillAddr: {
                    CountrySubDivisionCode: opts.residential ? customer.mailAddress.state : customer.billingAddress.state,
                    City: opts.residential ? customer.mailAddress.city : customer.billingAddress.city,
                    PostalCode: opts.residential ? customer.mailAddress.zip : customer.billingAddress.zip,
                    Line1: opts.residential ? customer.mailAddress.street1 : customer.billingAddress.street1,
                    Country: "USA"
                },
                ShipAddr: {
                    CountrySubDivisionCode: opts.residential ? customer.mailAddress.state : customer.shippingAddress.state,
                    City: opts.residential ? customer.mailAddress.city : customer.shippingAddress.city,
                    PostalCode: opts.residential ? customer.mailAddress.zip : customer.shippingAddress.zip,
                    Line1: opts.residential ? customer.mailAddress.street1 : customer.shippingAddress.street1,
                    Country: "USA"
                },
                GivenName: opts.residential ? customer.firstName : customer.poc.firstName,
                FamilyName: opts.residential ? customer.lastName : customer.poc.lastName
            }

            if(opts.residential) {
                if(customer.mailAddress.street2) {
                    qbBody.BillAddr.Line2 = customer.mailAddress.street2;
                    qbBody.ShipAddr.Line2 = customer.mailAddress.street2;
                }
            }
            else {
                qbBody.CompanyName = customer.businessName;
                if(customer.billingAddress.street2) qbBody.BillAddr.Line2 = customer.billingAddress.street2;
                if(customer.shippingAddress.street2) qbBody.ShipAddr.Line2 = customer.shippingAddress.street2;
            }

            const qbCustomer = await callQuickBooksAPI(signal, '/api/qb/customer', 'POST', qbBody, {residential: opts.residential});
            integrationIds.quickbooks = qbCustomer.Id;
        }
        catch(e) {
            if(e.name !== "AbortError") {
                console.error(e);
                banners.addBanner('danger', <span><b>QuickBooks Error:</b> The customer could not be created in QuickBooks. It will not be created in Chimera.</span>);
                await cleanup();
            }
            throw e;
        }
    }

    // Create in Chimera
    if(!opts.integrationsOnly) {
        let body = {};

        // A CommercialCustomer has this rawCustomerData attribute, but a residential customer does not.
        // Running this for loop for a Residential customer causes a bug where the body then only contains the integrationIds and thus creation will fail.
        if(!opts.residential) {
            for(const key in customer.rawCustomerData) {
                body[key] = customer[key];
            }
        }
        else {
            body = customer;
        }
    
        body.integrationIds = integrationIds;
    
        try {
            const createdCustomer = !opts.residential ? new CommercialCustomer(await callAPI(signal, `/api/customers`, "POST", body)) : await callAPI(signal, `/api/residentialcustomers`, "POST", body);
            if(willQb && createdCustomer.taxExempt) {
                try {
                    await callAPI(signal, '/api/sendmail', 'POST', {
                        email: "accounting@gocbit.com",
                        subject: `Chimera Customer Tax Exemption for ${createdCustomer.displayName}`,
                        text: `The newly-created Chimera Customer "${createdCustomer.displayName}" has had their Tax Exempt status set to ${customer.taxExempt}. This setting cannot be applied to QuickBooks automatically due to a limitation of their API. Please ensure that this new status is reflected in the corresponding QuickBooks customer. You can access that customer by going to https://app.qbo.intuit.com/app/customerdetail?nameId=${createdCustomer.integrationIds.quickbooks}`
                    })
                }
                catch(e) {
                    if(e.name !== "AbortError") {
                        console.error(e);
                        alert("Chimera attempted to alert Accounting that the Tax Exemption status was set to true for this customer, but the email failed to send. Please inform accounting@gocbit.com that this customer is tax exempt.");
                    }
                }
            }
            return createdCustomer;
        }
        catch(e) {
            if(e.name !== "AbortError") {
                console.error(e);
                banners.addBanner('danger', <span><strong>Error:</strong>&nbsp;An unknown error occurred and the customer could not be created.</span>);
                await cleanup();
            }
            throw e;
        }
    }
    else {
        return integrationIds;
    }
}

function uniqueArray(arr) {
    let newArr = [];
    for(const item of arr) {
        if(!newArr.includes(item)) {
            newArr.push(item);
        }
    }
    return newArr;
}

function ipInArray(arr, ip) {
    try {
        let range = 0;
        if(ip.length >= 3) {
            let lastThree = ip.substring(ip.length-3);
            if(lastThree.includes('/')) {
                range = parseInt(lastThree.split('/')[1]);
            }
        }
        let workingIp = ip;
        if(range > 0) {
            if(range === 24 || range === 16 || range === 8) {
                const nKeepSegments = range / 8;
                const segments = ip.split('.');
                let newIp = segments.slice(0, nKeepSegments).join('.');
                let nWildcards = 4 - nKeepSegments;
                for(let i = 0; i < nWildcards; i++) {
                    newIp += '.*'
                }
                workingIp = newIp;
            }
            else {
                // Not all ranges are supported yet.
                return false;
            }
        }
        
        const regexStr = workingIp.replace(/\*/g, '([0-9\.]{1,3})+').replace(/\./g, '\\.');
        const regex = new RegExp(`\\b${regexStr}\\b`, 'g');
        for(const item of arr) {
            if(item.match(regex)) {
                return true;
            }
        }
        return false;
    }
    catch(e) {
        console.error(e);
        return false;
    }
}

function digestQuery(query) {
    const keywords = {
        ip: ""
    }
    const tokens = query.split(/\s/g);
    const keywordTerms = tokens.filter(token => {
        for(const keyword in keywords) {
            if(token.toLowerCase().startsWith(`${keyword}:`)) {
                return true;
            }
        }
        return false;
    });
    for(const term of keywordTerms) {
        const parts = term.split(':');
        const keyword = parts[0];
        const value = parts[1];
        keywords[keyword] = value;
    }
    let searchTerm = tokens.filter(token => !keywordTerms.includes(token)).join(' ');
    return {
        keywords: keywords,
        keywordTerms: keywordTerms,
        searchTerm: searchTerm.trim()
    }
}

function getRecurringTransactions(signal, qbCustomerId, type, tag, residential) {
    if(!type) type = 'all';
    if(!tag) tag = 'all';
    return callQuickBooksAPI(signal, `/api/qb/recurringtransaction/${qbCustomerId}/type/${type}/tag/${tag}`, 'GET', null, {residential: residential});
}

function getRecurringTransactionsBatch(signal, qbCustomerIds, tag, type) {
    if(!type) type = 'all';
    if(!tag) tag = 'all';
    return callQuickBooksAPI(signal, `/api/qb/recurringtransactionbatch`, 'POST', {qbCustomerIds, type, tag});
}

function formatBytes(bytes, decimals = 2) {
    if (!+bytes) return '0 Bytes'

    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

    const i = Math.floor(Math.log(bytes) / Math.log(k))

    return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`
}

function isDeveloperMode() {
    return window.location.href.startsWith("http://localhost");
}

/**
 * Recursively search an object and check all of its string fields for a match against `str`.
 * @param {Object} obj The object to search
 * @param {String} str The string to search for
 * @returns {Boolean} If a match was found
 */
function searchObjectForString(obj, str) {
    const query = str.toLowerCase().trim();
    if(typeof(obj) === "object") {
        for(const key in obj) {
            if(typeof(obj[key]) !== "object") {
                if(query.startsWith('#') && typeof(obj[key]) === 'number') {
                    // Only check Numbers
                    if(obj[key].toString().toLowerCase().includes(query.substring(1))) {
                        return true;
                    }
                }
                else if(obj[key].toString().toLowerCase().includes(query)) {
                    return true;
                }
            }
            else if(typeof(obj[key]) === "object") {
                if(JSON.stringify(obj[key]).startsWith('[')) {
                    // Is an array
                    for(const element of obj[key]) {
                        if(searchObjectForString(element, query)) {
                            return true;
                        }
                    }
                }
                else {
                    // Is an object
                    if(searchObjectForString(obj[key], query)) {
                        return true;
                    }
                }
            }
        }
        return false;
    }
    else {
        if(query.startsWith('#') && typeof(obj) === 'number') {
            return obj.toString().toLowerCase().includes(query.substring(1))
        }
        else if(query.startsWith('#') && typeof(obj) !== 'number') {
            return false;
        }
        else {
            return obj.toString().toLowerCase().includes(query);
        }
    }
}

function getExpandableDropdownOptions(name, signal) {
    return new Promise((resolve, reject) => {
        callAPI(signal, '/api/attributes/EXPANDABLE_DROPDOWNS')
        .then(attr => {
            if(attr.value[name] !== undefined) {
                resolve(attr.value[name])
            }
            else {
                reject(new Error(`No Expandable Dropdown with name ${name}`))
            }
        })
        .catch(err => reject(err));
    })
}

function setExpandableDropdownOptions(name, options, signal) {
    return new Promise((resolve, reject) => {
        callAPI(signal, '/api/attributes/EXPANDABLE_DROPDOWNS')
        .then(attr => {
            if(attr.value[name] !== undefined) {
                attr.value[name] = options;
                callAPI(signal, '/api/attributes', 'PUT', attr)
                .then(result => resolve(result))
                .catch(err => reject(err))
            }
            else {
                reject(new Error(`No Expandable Dropdown with name ${name}`));
            }
        })
    })
}

class CommercialCustomer {
    constructor(rawCustomerData, locationIndex) {
        // `_rawCustomerData` is the Javascript object returned from the API
        if(rawCustomerData) {
            this._rawCustomerData = JSON.parse(JSON.stringify(rawCustomerData));
        }
        else {
            // apply defaults
            this._rawCustomerData = JSON.parse(JSON.stringify(DEFAULT_CUSTOMER));
        }
        this.locationIndex = locationIndex ? locationIndex : 0;
    }

    static copy(customer, resetLocation) {
        // Expect `customer` to be a CommercialCustomer
        return new CommercialCustomer(customer.rawCustomerData, resetLocation ? 0 : customer.locationIndex);
    }

    static getAll(signal, includeInactive) {
        return new Promise((resolve, reject) => {
            let headers = {};
            if(includeInactive) {
                headers['x-include-inactive'] = 'true';
            }
            callAPI(signal, '/api/customers', 'GET', null, 'json', headers)
            .then(customers => {
                resolve(customers.map(customer => new CommercialCustomer(customer)));
            })
            .catch(err => reject(err));
        })
    }

    static getByAccountNumber(accountNumber, signal) {
        return new Promise((resolve, reject) => {
            callAPI(signal, `/api/customers/accountnumber/${accountNumber}`)
            .then(customer => resolve(new CommercialCustomer(customer)))
            .catch(err => reject(err));
        })
    }

    static getByCreationDateRange(startDate, endDate, signal) {
        return new Promise((resolve, reject) => {
            callAPI(signal, `/api/customers/created/${startDate}/${endDate}`)
            .then(customers => {
                resolve(customers.map(customer => new CommercialCustomer(customer)));
            })
            .catch(err => reject(err));
        })
    }

    static getByServiceType(serviceType, signal) {
        return new Promise((resolve, reject) => {
            callAPI(signal, `/api/customers/servicetype/${serviceType}`)
            .then(customers => {
                // Break up the list and serve a copy of the customer per location with that service type
                let finalCustomers = [];
                for(const customer of customers) {
                    for(let i = 0; i < customer.locations.length; i++) {
                        if(customer.locations[i].serviceTypes[serviceType]) {
                            finalCustomers.push(new CommercialCustomer(customer, i));
                        }
                    }
                }
                resolve(finalCustomers);
            })
            .catch(err => reject(err));
        })
    }

    /**
     * Use _locationIndex to return the location-specific information.
     * This may allow for minimal code changes.
     */

    get locationIndex() {
        return this._locationIndex;
    }

    set locationIndex(value) {
        this._locationIndex = Math.min(Math.max(value, 0), this._rawCustomerData.locations.length - 1); // Clamp between 0 and the maximum allowed index.
    }

    get rawCustomerData() {
        return this._rawCustomerData;
    }

    set rawCustomerData(data) {
        this._rawCustomerData = data;
    }

    get accountNumber() {
        return this._rawCustomerData.accountNumber;
    }

    set accountNumber(n) {
        this._rawCustomerData.accountNumber = n;
    }

    get standing() {
        return this._rawCustomerData.standing;
    }

    set standing(value) {
        this._rawCustomerData.standing = value;
    }

    get businessName() {
        return this._rawCustomerData.businessName;
    }

    set businessName(value) {
        this._rawCustomerData.businessName = value;
    }

    get displayName() {
        return this._rawCustomerData.displayName;
    }

    set displayName(value) {
        this._rawCustomerData.displayName = value;
    }

    get formerlyKnownAs() {
        return this._rawCustomerData.formerlyKnownAs;
    }

    set formerlyKnownAs(value) {
        this._rawCustomerData.formerlyKnownAs = value;
    }

    get poc() {
        return this._rawCustomerData.locations[this._locationIndex].poc;
    }

    set poc(value) {
        this._rawCustomerData.locations[this._locationIndex].poc = value;
    }

    get notificationEmails() {
        return this._rawCustomerData.locations[this._locationIndex].notificationEmails;
    }

    set notificationEmails(value) {
        this._rawCustomerData.locations[this._locationIndex].notificationEmails = value;
    }

    get businessPhone() {
        return this._rawCustomerData.locations[this._locationIndex].businessPhone;
    }

    set businessPhone(value) {
        this._rawCustomerData.locations[this._locationIndex].businessPhone = value;
    }

    get billingPhone() {
        return this._rawCustomerData.billingPhone;
    }

    set billingPhone(value) {
        this._rawCustomerData.billingPhone = value;
    }

    get accountsPayableEmails() {
        return this._rawCustomerData.accountsPayableEmails;
    }
    
    set accountsPayableEmails(value) {
        this._rawCustomerData.accountsPayableEmails = value;
    }

    get billingAddress() {
        return this._rawCustomerData.billingAddress;
    }

    set billingAddress(value) {
        this._rawCustomerData.billingAddress = value;
    }

    get serviceAddress() {
        return this._rawCustomerData.locations[this._locationIndex].serviceAddress;
    }

    set serviceAddress(value) {
        this._rawCustomerData.locations[this._locationIndex].serviceAddress = value;
    }

    get shippingAddress() {
        return this._rawCustomerData.locations[this._locationIndex].shippingAddress;
    }

    set shippingAddress(value) {
        this._rawCustomerData.locations[this._locationIndex].shippingAddress = value;
    }

    get serviceTypes() {
        return this._rawCustomerData.locations[this._locationIndex].serviceTypes;
    }

    set serviceTypes(value) {
        this._rawCustomerData.locations[this._locationIndex].serviceTypes = value;
    }

    get isMspCustomer() {
        return this._rawCustomerData.serviceTypes.msp;
    }

    set isMspCustomer(value) {
        this._rawCustomerData.serviceTypes.msp = value;
    }

    get isInternetCustomer() {
        return this._rawCustomerData.serviceTypes.internet;
    }

    set isInternetCustomer(value) {
        this._rawCustomerData.serviceTypes.internet = value;
    }

    get isVoipCustomer() {
        return this._rawCustomerData.serviceTypes.voip;
    }

    set isVoipCustomer(value) {
        this._rawCustomerData.serviceTypes.voip = value;
    }

    get isVideoCustomer() {
        return this._rawCustomerData.serviceTypes.video;
    }

    set isVideoCustomer(value) {
        this._rawCustomerData.serviceTypes.video = value;
    }

    get endpointPackages() {
        return this._rawCustomerData.endpointPackages;
    }

    set endpointPackages(value) {
        this._rawCustomerData.endpointPackages = value;
    }

    get billingPocs() {
        return this._rawCustomerData.billingPocs;
    }

    set billingPocs(value) {
        this._rawCustomerData.billingPocs = value;
    }

    get taxExempt() {
        return this._rawCustomerData.taxExempt;
    }

    set taxExempt(value) {
        this._rawCustomerData.taxExempt = value;
    }

    get integrationIds() {
        return this._rawCustomerData.integrationIds;
    }

    set integrationIds(value) {
        this._rawCustomerData.integrationIds = value;
    }

    get isQuickbooksIntegrated() {
        // explicitly convert string to boolean
        return this._rawCustomerData.integrationIds.quickbooks ? true : false;
    }

    get isSyncroIntegrated() {
        // explicitly convert string to boolean
        return this._rawCustomerData.integrationIds.syncro ? true : false;
    }

    get isBitdefenderIntegrated() {
        // explicitly convert string to boolean
        return this._rawCustomerData.integrationIds.bitdefender ? true : false;
    }

    get isUnityIntegrated() {
        // explicitly convert string to boolean
        return this._rawCustomerData.integrationIds.unity ? true : false;
    }

    get isPax8Integrated() {
        // explicitly convert string to boolean
        return this._rawCustomerData.integrationIds.pax8 ? true : false;
    }

    get isDuoIntegrated() {
        return this._rawCustomerData.integrationIds.duo ? true : false;
    }

    get technical() {
        return this._rawCustomerData.locations[this._locationIndex].technical;
    }

    set technical(value) {
        this._rawCustomerData.locations[this._locationIndex].technical = value;
    }

    get laborCreditHours() {
        return this._rawCustomerData.laborCreditHours;
    }

    set laborCreditHours(value) {
        this._rawCustomerData.laborCreditHours = value;
    }

    get onCallAfterHours() {
        return this._rawCustomerData.onCallAfterHours;
    }

    set onCallAfterHours(value) {
        this._rawCustomerData.onCallAfterHours = value;
    }

    get standardRate() {
        return this._rawCustomerData.standardRate;
    }

    set standardRate(value) {
        this._rawCustomerData.standardRate = value;
    }

    get afterHoursRate() {
        return this._rawCustomerData.afterHoursRate;
    }

    set afterHoursRate(value) {
        this._rawCustomerData.afterHoursRate = value;
    }

    get technicianId() {
        return this._rawCustomerData.technicianId;
    }

    set technicianId(value) {
        this._rawCustomerData.technicianId = value;
    }

    get assignedTechnicians() {
        return this._rawCustomerData.assignedTechnicians;
    }

    set assignedTechnicians(value) {
        this._rawCustomerData.assignedTechnicians = value;
    }

    get locations() {
        return this._rawCustomerData.locations;
    }

    set locations(value) {
        this._rawCustomerData.locations = value;
    }

    get createdBy() {
        return this._rawCustomerData.createdBy;
    }

    set createdBy(value) {
        this._rawCustomerData.createdBy = value;
    }

    get modifiedBy() {
        return this._rawCustomerData.modifiedBy;
    }

    set modifiedBy(value) {
        this._rawCustomerData.modifiedBy = value;
    }

    get createdAt() {
        return this._rawCustomerData.createdAt;
    }

    get updatedAt() {
        return this._rawCustomerData.updatedAt;
    }

    get tags() {
        return this._rawCustomerData.tags;
    }

    set tags(value) {
        this._rawCustomerData.tags = value;
    }

    get provider() {
        return this._rawCustomerData.locations[this._locationIndex].technical.provider;
    }

    set provider(value) {
        this._rawCustomerData.locations[this._locationIndex].technical.provider = value;
    }

    get service() {
        return this._rawCustomerData.locations[this._locationIndex].technical.service;
    }

    set service(value) {
        this._rawCustomerData.locations[this._locationIndex].technical.service = value;
    }

    get active() {
        return this._rawCustomerData.active;
    }

    set active(value) {
        this._rawCustomerData.active = value;
    }

    get _id() {
        return this._rawCustomerData._id;
    }
}

/**
 * @param {string} iso a YYYY-MM-DD formatted string
 * @returns {Date} a Date object
 */
function dateFromISOFragment(iso) {
    return new Date(`${iso}T13:00:00`); // time helps ensure the date stays as the intended date
}

/**
 * @param {Date} date 
 * @returns {string} a YYYY-MM-DD format string of the date
 */
function dateToISOFragment(date) {
    return date.toISOString().substring(0,10);
}

/**
 * Get all days within the given range as strings in YYYY-MM-DD format
 * @param {string} startDateStr A date string in YYYY-MM-DD format
 * @param {string} endDateStr A date string in YYYY-MM-DD format
 * @returns {Array<string>} An array of dates between `startDateStr` and `endDateStr` (inclusive) in YYYY-MM-DD format
 */
function extrapolateDays(startDateStr, endDateStr) {
    let dayStrs = [];
    for(let day = dateFromISOFragment(startDateStr); dateToISOFragment(day) <= endDateStr; day.setDate(day.getDate() + 1)) {
        dayStrs.push(dateToISOFragment(day));
    }
    return dayStrs;
}

/**
 * 
 * @param {number} yearNum 4-digit year
 * @param {number} monthNum 0-indexed month number
 * @returns 
 */
function extrapolateDaysInMonth(yearNum, monthNum) {
    let monthStr = `${monthNum+1 <= 9 ? '0' : ''}${monthNum+1}`;
    let startDateStr = `${yearNum}-${monthStr}-01`;
    let endDateStr = `${yearNum}-${monthStr}-31`;
    return extrapolateDays(startDateStr, endDateStr);
}

/**
 * Splits the text and maps it to a series of `<span>`s and `<br/>`s
 * @param {String} text
 * @returns {JSX}  
 */
function renderTextWithNewlines(text) {
    return text.split('\n').map((p, i) => <div key={i}><span>{p}</span><br/></div>);
}

function customerHasNoActiveServiceTypes(customer) {
    const serviceTypes = ['internet', 'voip', 'msp', 'video'];
    for(const location of customer.locations) {
        for(const serviceType of serviceTypes) {
            if(location.serviceTypes[serviceType]) {
                return false;
            }
        }
    }
    return true;
}

function promptToggleInactive(customer, modaling) {
    return new Promise((resolve, _) => {
        const choices = [
            {
                btnColor: 'secondary',
                btnInner: <span><i className="fas fa-arrow-right"/>&nbsp;No, DO NOT deactivate</span>,
                func: (e) => {
                    e.preventDefault();
                    modaling.backtrack();
                    resolve(customer);
                }
            },
            {
                btnColor: 'danger',
                btnInner: <span><i className="fas fa-times"/>&nbsp;Yes, DEACTIVATE!</span>,
                func: (e) => {
                    e.preventDefault();
                    const newCustomer = JSON.parse(JSON.stringify(customer));
                    newCustomer.active = false;
                    modaling.backtrack();
                    resolve(newCustomer);
                }
            }
        ]

        const modal = <Modal choices={choices} dismiss={choices[0].func}>
            <h3>Deactivate this Customer?</h3>
            <p>
                With this action, <b>{customer.displayName}</b> will have no enabled Service Types. Would you like to set them as Inactive?
            </p>
        </Modal>
        modaling.setModal(modal);
    })
}

const chimera = {
    sleep,
    callSyncroAPI,
    callQuickBooksAPI,
    callAPI,
    dollarStr,
    dollarStrToNum,
    phoneNumberStr,
    getAttr,
    setAttr,
    deepEqual,
    deleteCustomer,
    encodeObj,
    decodeObj,
    parseParams,
    pushToSyncro,
    pushToQuickBooks,
    pushToUnity,
    pushToPax8,
    pushToDuo,
    customerToSyncroFields,
    validateCustomer,
    createCustomer,
    uniqueArray,
    ipInArray,
    digestQuery,
    getRecurringTransactions,
    getRecurringTransactionsBatch,
    formatBytes,
    addressStr,
    isDeveloperMode,
    searchObjectForString,
    CommercialCustomer,
    extrapolateDays,
    extrapolateDaysInMonth,
    dateFromISOFragment,
    dateToISOFragment,
    DEFAULT_ADDRESS,
    DEFAULT_POC,
    DEFAULT_LOCATION,
    DEFAULT_CUSTOMER,
    getExpandableDropdownOptions,
    setExpandableDropdownOptions,
    renderTextWithNewlines,
    customerHasNoActiveServiceTypes,
    promptToggleInactive
}

export default chimera;