import React, {useState, useEffect, useContext, useLayoutEffect} from 'react';

import TimelineForm from './TimelineForm';
import AddressDisplay from './AddressDisplay';
import PocDisplay from './PocDisplay';
import chimera from '../chimera';
import BannerContext, {BannerLog} from './BannerLogContext';
import LoadingSpinner from './LoadingSpinner';
import NotesSection from './CustomerPage/NotesSection';
import ModalContext from './ModalContext';
import Modal from './Modal';
import { NewCustomerBody } from './NewCustomer';
import { NewResidentialCustomerBody } from './NewResidentialCustomer';

const PocList = props => {
    return (
        <div>
            {props.pocs.map((poc, i) => 
                <div className={i !== props.pocs.length - 1 ? "section-outline mb-4" : "section-outline"}>
                    <PocDisplay poc={poc} basePath={`${props.basePath}[${i}]`} baseValue={props.baseValue} onChange={props.handleChange} disabled={props.disabled} isEditing handleDelete={(event) => {event.preventDefault(); props.handleDelete(i)}} onBlur={props.onBlur} series/>
                </div>
            )}
            <button className="btn btn-success mt-2 mb-2" onClick={props.addPoc} disabled={props.disabled}>
                <i className="fas fa-plus"/>
            </button>
        </div>
    )
}

const StrInterface = props => {
    return(
        <div className="row justify-content-center">
            <div className="col">
                {props.strings.map((str, i) => 
                    <div className="mb-1 d-flex flex-row">
                        <input className="form-control" type="text" name={`${props.basePath}[${i}]`} value={str} placeholder={props.placeholder} onChange={props.handleChange} disabled={props.disabled} onBlur={props.onBlur}/>
                        <button className="btn btn-danger ms-2" onClick={(event) => {event.preventDefault(); props.deleteString(props.basePath, i)}} disabled={props.disabled}><i className="fas fa-minus"/></button>
                    </div>
                )}
                <button className="btn btn-success" onClick={(event) => {event.preventDefault(); props.addString(props.basePath)}} disabled={props.disabled}>
                    <i className="fas fa-plus"/>
                </button>
            </div>
        </div>
    );
}

const BillingCheck = props => {
    const [status, setStatus] = useState("NONE");
    const [nTxn, setNTxn] = useState(0);
    const banners = useContext(BannerContext);

    useEffect(() => {
        if(status === "NONE") {
            chimera.callAPI(undefined, `/api/residentialcustomers/accountNumber/${props.accountNumber}`)
            .then(customer => {
                if(customer.integrationIds.quickbooks) {
                    chimera.getRecurringTransactions(undefined, customer.integrationIds.quickbooks, 'all', 'all', true)
                    .then(txns => {
                        setNTxn(txns.length);
                        setStatus(txns.length > 0 ? "GOOD" : "BAD");
                    })
                    .catch(err => {
                        console.error(err);
                        banners.addBanner('danger', 'Failed to read recurring transactions, cannot perform billing check', 'Error');
                    })
                }
                else {
                    banners.addBanner('warning', `Billing check cannot be performed because the customer with account number ${props.accountNumber} lacks QuickBooks integration.`, 'Alert');
                }
            })
            .catch(err => {
                console.error(err);
                banners.addBanner('danger', 'Failed to read created customer, cannot perform billing check', 'Error');
            })
        }
    }, [status]);

    return (
        <>
        <br/>
        {status === "NONE" ? <i className="fas fa-spinner"/> : null}
        {status === "BAD" ? 
            <span className="text-danger">There are no recurring transactions for the associated customer.</span>
        :null}
        {status === "GOOD" ? 
            <span className="text-success">There {nTxn > 1 ? "are" : "is"} {nTxn} recurring transaction{nTxn > 1 ? "s" : ""} set up for this customer.</span>
        : null}
        <br/>
        <div className="form-check">
            <input id={props.name} name={props.name}
                className="form-check-input float-none" type="checkbox"
                checked={props.value}
                onChange={props.handleChange}
                onBlur={typeof(props.onBlur) === "function" ? props.onBlur : () => {}}
                required={props.required}
                disabled={props.disabled || status !== "GOOD"}/>&nbsp;
            <label className="form-check-label" htmlFor={props.name}>
                {props.checkLabel ? props.checkLabel : `${props.value}`.charAt(0).toUpperCase() + `${props.value}`.slice(1)}
            </label>
        </div>
        </>
    )
}

const ResidentialLeadForm = props => {
    const [lead, setLead] = useState(props.lead);
    const [saveBtnLabel, setSaveBtnLabel] = useState("Save SO");
    const [saveBtnIcon, setSaveBtnIcon] = useState("fas fa-floppy-disk");
    const [saveBtnDisabled, setSaveBtnDisabled] = useState(true);
    const [notes, setNotes] = useState(null);
    const [wasAlreadyCompleted, setWasAlreadyCompleted] = useState(props.lead ? props.lead.status === "Completed" : false);
    const banners = useContext(BannerContext);
    const modaling = useContext(ModalContext);

    useEffect(() => {
        const controller = new AbortController();
        const signal = controller.signal;
        if(lead === null) {
            chimera.callAPI(signal, '/api/leads/type/residential', 'POST', {})
            .then(newLead => {
                setLead(newLead);
            })
            .catch(err => {
                console.error(err);
                banners.addBanner('danger', 'Could not open new Lead', 'Error');
            })
        }
        return () => {
            controller.abort();
        }
    }, [lead]);

    useEffect(() => {
        const controller = new AbortController();
        const signal = controller.signal;
        if(lead && notes === null) {
            const sortByStarred = (arr) => {
                let starredNotes = [];
                let unstarredNotes = [];
                for(const note of arr) {
                    if(note.starred) starredNotes.push(note);
                    else unstarredNotes.push(note);
                }
                return [].concat(starredNotes, unstarredNotes);
            }
            chimera.callAPI(signal, `/api/notes/refId/${lead._id}`)
            .then(newNotes => {setNotes(sortByStarred(newNotes))})
            .catch(e => {
                if(e.name !== "AbortError") {
                    banners.addBanner('danger', 'Could not read notes', 'Error');
                    console.error(e);
                }
            });
        }
        return () => {
            controller.abort();
        }
    }, [lead, notes]);

    const handleChange = (event) => {
        if(event.target.type !== "checkbox") event.preventDefault();
        const name = event.target.name;
        const value = event.target.type === "checkbox" ? event.target.checked : event.target.value;

        let newFormFields = JSON.parse(JSON.stringify(lead));

        if(name === "attrs.mailSameAsHome" && value) {
            newFormFields.attrs.mailAddress = newFormFields.attrs.homeAddress;
        }
        if(newFormFields.attrs.mailSameAsHome) {
            newFormFields.attrs.mailAddress = JSON.parse(JSON.stringify(newFormFields.attrs.homeAddress));
        }

        if(name === "currentStep" && value === 1) {
            newFormFields.status = "In-Progress";
        }
        else if(name === "currentStep" && value === 8) {
            newFormFields.status = "Completed";
        }

        if(name === "attrs.plan") {
            newFormFields.attrs.technical.ipType = value === "Plan A" ? "DHCP" : "Static IP";
        }

        chimera.setAttr(newFormFields, name, value);

        // Complex step completion checking
        newFormFields.steps.collectedCustomerInfo = 
            newFormFields.attrs.firstName !== ""           &&
            newFormFields.attrs.lastName !== ""            &&
            newFormFields.attrs.phone !== ""               &&
            emailIsValid(newFormFields.attrs.email)        &&
            newFormFields.attrs.homeAddress.street1 !== "" &&
            newFormFields.attrs.homeAddress.city !== ""    &&
            newFormFields.attrs.homeAddress.state !== ""   &&
            newFormFields.attrs.homeAddress.county !== ""  &&
            newFormFields.attrs.homeAddress.zip !== ""     &&
            newFormFields.attrs.mailAddress.street1 !== "" &&
            newFormFields.attrs.mailAddress.city !== ""    &&
            newFormFields.attrs.mailAddress.state !== ""   &&
            newFormFields.attrs.mailAddress.county !== ""  &&
            newFormFields.attrs.mailAddress.zip !== ""     &&
            newFormFields.attrs.type !== "NOT SET"         &&
            newFormFields.attrs.plan !== "NOT SET";
        
        newFormFields.steps.documentedCircuit = 
            newFormFields.attrs.technical.switchPort !== "" &&
            newFormFields.attrs.technical.ipType === "Static IP" ?
                newFormFields.attrs.technical.ips.length > 0 && newFormFields.attrs.technical.ips[0] !== "" &&
                newFormFields.attrs.technical.subnetMasks.length > 0 && newFormFields.attrs.technical.subnetMasks[0] !== "" &&
                newFormFields.attrs.technical.gateways.length > 0 && newFormFields.attrs.technical.gateways[0] !== ""
            :
                true;

        newFormFields.steps.scheduledInstallDate = newFormFields.attrs.installDate !== "";

        if(newFormFields.attrs.technical.ipType !== "Static IP") {
            newFormFields.attrs.technical.ips = [];
            newFormFields.attrs.technical.gateways = [];
            newFormFields.attrs.technical.subnetMasks = [];
        }
        
        setLead(newFormFields);
        setSaveBtnDisabled(false);
        setSaveBtnIcon("fas fa-floppy-disk");
        setSaveBtnLabel("Save SO");
    }

    const trimOnBlur = (event) => {
        handleChange({
            target: {
                type: "string",
                name: event.target.name,
                value: event.target.value.trim()
            },
            preventDefault: () => {}
        })
    }

    const handleSubmit = async(event) => {
        event.preventDefault();

        // Validate lead as customer first
        const customer = {
            type: lead.attrs.type,
            plan: lead.attrs.plan,
            firstName: lead.attrs.firstName,
            lastName: lead.attrs.lastName,
            email: lead.attrs.email,
            phone: lead.attrs.phone,
            homeAddress: lead.attrs.homeAddress,
            mailAddress: lead.attrs.mailAddress,
            mailSameAsHome: lead.attrs.mailSameAsHome,
            authorizedUsers: lead.attrs.authorizedUsers,
            serviceTypes: {internet: true, voip: false},
            technical: lead.attrs.technical,
            standing: "good"
        }

        const createdCustomerCallback = async(createdCustomer) => {
            modaling.setModal(null);
            // Show loading modal
            const controller = new AbortController();
            const signal = controller.signal;
            const dismiss = (event) => {
                event.preventDefault();
                controller.abort();
                modaling.setModal(null);
            }
            const loadingModal = <Modal choices={[]} dismiss={dismiss}>
                <LoadingSpinner size={75}/>
            </Modal>
            modaling.setModal(loadingModal);

            try {
                await chimera.callAPI(signal, '/api/notes/copy', 'POST', {
                    from: lead._id,
                    to: createdCustomer._id
                });
            }
            catch(e) {
                console.error(e);
                alert('Failed to copy notes over... sorry!');
            }
            
            // Save the lead
            try {
                const body = JSON.parse(JSON.stringify(lead));
                body.status = "Provisioned";
                body.attrs.createdCustomerAccountNumber = createdCustomer.accountNumber;
                await chimera.callAPI(signal, `/api/leads/type/residential/${lead._id}`, 'PUT', body);
            }
            catch(e) {
                console.error(e);
                alert('Failed to save service order. Customer has still been successfully created.');
            }

            // Send the email
            /** TODO: When should this email be sent? When is the Lead considered "Provisioned" ?
            try {
                await chimera.callAPI(signal, '/api/sendmail', 'POST', {
                    email: ["accounting@gocbit.com"],
                    subject: `Lead set to Provisioned: ${lead.attrs.customerName}`,
                    text: `The Lead for ${lead.attrs.customerName} has been set to Provisioned. Please find it at https://bms.gocbit.com/leads to set up billing.`
                });
            }
            catch(e) {
                console.error(e);
                alert('Failed to email Accounting');
            }
            */

            modaling.setModal(null);
            const choices = [
                {
                    btnInner: <span><i className="fas fa-check"/>&nbsp;Close</span>,
                    btnColor: "secondary",
                    func: (event) => {
                        event.preventDefault();
                        modaling.setModal(null);
                        if(props.setLeads) props.setLeads(null);
                    }
                },
                {
                    btnInner: <span><i className="fas fa-arrow-right"/>&nbsp;Go to Customer</span>,
                    btnColor: "success",
                    func: (event) => {
                        event.preventDefault();
                        window.open(`/residential/${createdCustomer.accountNumber}`, '_self');
                    }
                }
            ]
            const modal = <Modal choices={choices} dismiss={choices[0].func}>
                <h3>Success!</h3>
                <p>The new customer <strong>{createdCustomer.displayName}</strong> has been created.</p>
            </Modal>
            modaling.setModal(modal);
        }

        // Load New Customer form with Lead info pre-loaded
        // TODO: Highlighted fields are hard-coded. That will probably need to change for future leads.
        const controller = new AbortController();
        const signal = controller.signal;
        const dismiss = (event) => {
            event.preventDefault();
            controller.abort();
            modaling.setModal(null);
        }
        const modal = <Modal choices={[]} dismiss={dismiss}>
            <BannerLog>
                <NewResidentialCustomerBody customer={customer} controller={controller} signal={signal} createdCustomerCallback={createdCustomerCallback}/>
            </BannerLog>
        </Modal>
        modaling.setModal(modal);
    }
    
    const handleSave = (event) => {
        event.preventDefault();
        setSaveBtnDisabled(true);
        setSaveBtnIcon("fas fa-spinner");
        setSaveBtnLabel("Saving...");
        chimera.callAPI(undefined, `/api/leads/type/residential/${lead._id}`, 'PUT', lead)
        .then(newLead => {
            banners.addBanner('info', 'Successfully saved changes!');
            setLead(newLead);
            setSaveBtnIcon('fas fa-check');
            setSaveBtnLabel("Saved!");
        })
        .catch(err => {
            console.log(err);
            banners.addBanner('danger', 'Failed to save changes', 'Error');
            setSaveBtnDisabled(false);
            setSaveBtnIcon("fas fa-floppy-disk");
            setSaveBtnLabel("Save SO");
        })
    }

    const confirmCancel = (event) => {
        event.preventDefault();
        const choices = [
            {
                btnInner: <span><i className="fas fa-arrow-left"/>&nbsp;No, go back</span>,
                btnColor: "secondary",
                func: (event) => {
                    event.preventDefault();
                    modaling.backtrack();
                }
            },
            {
                btnInner: <span><i className="fas fa-times"/>&nbsp;Yes, cancel this lead</span>,
                btnColor: "danger",
                func: (event) => {
                    event.preventDefault();
                    const controller = new AbortController();
                    const signal = controller.signal;
                    const dismiss = (event) => {
                        event.preventDefault();
                        controller.abort();
                        modaling.setModal(null);
                    }
                    const loadingModal = <Modal choices={[]} dismiss={dismiss}>
                        <BannerLog>
                            <LoadingSpinner size={75}/>
                        </BannerLog>
                    </Modal>
                    modaling.setModal(loadingModal);
                    const body = {};
                    for(const key in lead) {
                        body[key] = lead[key];
                    }
                    body.status = "Cancelled";
                    chimera.callAPI(signal, `/api/leads/type/residential/${lead._id}`, 'PUT', body)
                    .then(newLead => {
                        setLead(newLead);
                        modaling.setModal(null);
                        if(props.setLeads) props.setLeads(null);
                    })
                    .catch(err => {
                        console.error(err);
                        banners.addBanner('danger', 'Failed to cancel Service Order', 'Error');
                    })
                }
            }
        ]
        const modal = <Modal choices={choices} dismiss={choices[0].func}>
            <h3>Are you sure you want to cancel this lead?</h3>
        </Modal>
        modaling.setModal(modal);
    }

    const confirmReopen = (event) => {
        event.preventDefault();
        const choices = [
            {
                btnInner: <span><i className="fas fa-arrow-left"/>&nbsp;No, go back</span>,
                btnColor: "secondary",
                func: (event) => {
                    event.preventDefault();
                    modaling.backtrack();
                }
            },
            {
                btnInner: <span><i className="fas fa-plus"/>&nbsp;Yes, reopen this lead</span>,
                btnColor: "danger",
                func: (event) => {
                    event.preventDefault();
                    const controller = new AbortController();
                    const signal = controller.signal;
                    const dismiss = (event) => {
                        event.preventDefault();
                        controller.abort();
                        modaling.setModal(null);
                    }
                    const loadingModal = <Modal choices={[]} dismiss={dismiss}>
                        <BannerLog>
                            <LoadingSpinner size={75}/>
                        </BannerLog>
                    </Modal>
                    modaling.setModal(loadingModal);
                    const body = {};
                    for(const key in lead) {
                        body[key] = lead[key];
                    }
                    body.status = lead.currentStep >= 5 ? "In-Progress" : "New"; // TODO: New threshold?
                    chimera.callAPI(signal, `/api/leads/type/residential/${lead._id}`, 'PUT', body)
                    .then(newLead => {
                        setLead(newLead);
                        modaling.setModal(null);
                        if(props.setLeads) props.setLeads(null);
                    })
                    .catch(err => {
                        console.error(err);
                        banners.addBanner('danger', 'Failed to reopen Lead', 'Error');
                    })
                }
            }
        ]
        const modal = <Modal choices={choices} dismiss={choices[0].func}>
            <h3>Are you sure you want to reopen this lead?</h3>
        </Modal>
        modaling.setModal(modal);
    }

    const stepCompletedCallback = (stepIndex) => {
        const stepNum = stepIndex + 1;
        handleChange({
            preventDefault: () => {},
            target: {
                type: "number",
                name: "currentStep",
                value: stepNum
            }
        })
    }

    const statusColor = (status) => {
        switch(status) {
            case "New":
                return "info";
            case "In-Progress":
                return "warning";
            case "Provisioned":
                return "primary";
            case "Completed":
                return "success";
            case "Cancelled":
                return "danger";
        }
    }

    const deleteString = (basePath, index) => {
        let newStrings = [];
        const strings = chimera.getAttr(lead, basePath);
        for(let i = 0; i < strings.length; i++) {
            if(i !== index)
                newStrings.push(strings[i]);
        }

        handleChange({
            target: {
                type: "Array",
                name: basePath,
                value: newStrings
            },
            preventDefault: () => {}
        });
    }

    const addString = (basePath) => {
        let newStrings = [];
        const strings = chimera.getAttr(lead, basePath);
        for(const str of strings) {
            newStrings.push(str);
        }
        newStrings.push("");

        handleChange({
            target: {
                type: "Array",
                name: basePath,
                value: newStrings
            },
            preventDefault: () => {}
        });
    }

    const addAuthorizedUser = (event) => {
        event.preventDefault();
        let authorizedUsers = JSON.parse(JSON.stringify(lead.attrs.authorizedUsers));
        authorizedUsers.push({
            firstName: "",
            lastName: "",
            email: "",
            phone: ""
        });
        handleChange({
            preventDefault: () => {},
            target: {
                type: "array",
                name: 'attrs.authorizedUsers',
                value: authorizedUsers
            }
        });
    }

    const deleteAuthorizedUser = (index) => {
        let newPocs = [];
        const oldUsers = JSON.parse(JSON.stringify(lead.attrs.authorizedUsers));
        for(let i = 0; i < oldUsers.length; i++) {
            if(i !== index) {
                newPocs.push(oldUsers[i]);
            }
        }
        handleChange({
            preventDefault: () => {},
            target: {
                type: "array",
                name: "attrs.authorizedUsers",
                value: newPocs
            }
        })
    }

    const emailIsValid = (email) => {
        return email.match(/^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/g) ? true : false
    }

    return (
        <>
        {lead === null ?
        <LoadingSpinner size={75}/>
        :
        <TimelineForm timeline={{currentStep: lead.currentStep}} stepCompletedCallback={stepCompletedCallback}>
            <TimelineForm.Main>
                <TimelineForm.Section label="Step 1" canContinue={lead.steps.collectedCustomerInfo}>
                    <TimelineForm.Field
                        type="component"
                        name="poc"
                        label="Primary Resident"
                        description="Name and contact details for the primary resident."
                        disabled={lead.status === "Cancelled"}
                        classes="my-0"
                        required>
                            <PocDisplay poc={{firstName: lead.attrs.firstName, lastName: lead.attrs.lastName, phone: lead.attrs.phone, email: lead.attrs.email}} basePath="attrs" baseValue={lead} onChange={handleChange} isEditing onBlur={trimOnBlur}/>
                    </TimelineForm.Field>
                    <TimelineForm.Field
                        type="component"
                        name="attrs.homeAddress"
                        label="Home Address"
                        description="The physical address of the residence."
                        classes="mt-4"
                        disabled={lead.status === "Cancelled"}
                        required>
                            <AddressDisplay addr={lead.attrs.homeAddress} basePath="attrs.homeAddress" baseValue={lead} onChange={handleChange} isEditing onBlur={trimOnBlur}/>
                    </TimelineForm.Field>
                    <TimelineForm.Field
                        type="component"
                        name="attrs.mailAddress"
                        label="Mail Address"
                        description="The mailing address for the residence."
                        classes="mt-4"
                        disabled={lead.status === "Cancelled"}
                        required>
                            <AddressDisplay addr={lead.attrs.mailAddress} basePath="attrs.mailAddress" baseValue={lead} onChange={handleChange} isEditing onBlur={trimOnBlur} sameAsLabel="Same As Home Address" sameAsPath="attrs.mailSameAsHome" sameAs={lead.attrs.mailSameAsHome}/>
                    </TimelineForm.Field>
                    <TimelineForm.Field
                        type="select"
                        options={[
                            {id: "NOT SET", value: "NOT SET"},
                            {id: "Ederra", value: "Ederra"},
                            {id: "Timberline", value: "Timberline"},
                            {id: "Skyline Ridge", value: "Skyline Ridge"},
                        ]}
                        name="attrs.type"
                        label="Zone"
                        value={lead.attrs.type}
                        description="The residential zone the home is within."
                        handleChange={handleChange}
                        classes="mt-4"
                        excludeNoneSelected
                        disabled={lead.status === "Cancelled"}
                        required
                        />
                    <TimelineForm.Field
                        type="select"
                        options={[
                            {id: "500 Mbps", value: "500 Mbps"},
                            {id: "1 Gbps", value: "1 Gbps"},
                        ]}
                        name="attrs.technical.bandwidth"
                        label="Bandwidth"
                        value={lead.attrs.technical.bandwidth}
                        description="The service the customer is requesting."
                        handleChange={handleChange}
                        classes="mt-4"
                        excludeNoneSelected
                        disabled={lead.status === "Cancelled"}
                        required
                        />
                    <TimelineForm.Field
                        type="select"
                        options={[
                            {id: "Plan A", value: "Plan A (Dynamic IP)"},
                            {id: "Plan B", value: "Plan B (Static IP)"},
                        ]}
                        name="attrs.plan"
                        label="Plan"
                        value={lead.attrs.plan}
                        description="The plan the customer is requesting."
                        handleChange={handleChange}
                        classes="mt-4"
                        disabled={lead.status === "Cancelled"}
                        required
                        />
                    <TimelineForm.Field
                        type="component"
                        name="attrs.authorizedUsers"
                        label="(Optional) Additional Authorized Users"
                        description="Other residents able to make requests on behalf of the primary resident."
                        classes="mt-4"
                        disabled={lead.status === "Cancelled"}>
                            <PocList pocs={lead.attrs.authorizedUsers} basePath="attrs.authorizedUsers" baseValue={lead} handleChange={handleChange} handleDelete={deleteAuthorizedUser} addPoc={addAuthorizedUser} onBlur={trimOnBlur}/>
                    </TimelineForm.Field>
                </TimelineForm.Section>
                <TimelineForm.Section label="Step 2" canContinue={lead.steps.scheduledFiberMeasurement && lead.steps.updatedCustomer && (lead.attrs.conduitInstalled || lead.attrs.conduitInstallDate !== "")}>
                    <TimelineForm.Field
                        type="checkbox"
                        name="attrs.conduitInstalled"
                        label="Conduit Installed?"
                        value={lead.attrs.conduitInstalled}
                        description="Has the conduit already been installed?"
                        handleChange={handleChange}
                        checkLabel="Yes"
                        classes="my-0"
                        disabled={lead.status === "Cancelled"}
                        />
                    <TimelineForm.Field
                        type="date"
                        name="attrs.conduitInstallDate"
                        label="(Conditional) Conduit Install Date"
                        value={lead.attrs.conduitInstallDate}
                        description="Required only if conduit has not already been installed, otherwise ignored."
                        handleChange={handleChange}
                        classes="mt-4"
                        disabled={lead.status === "Cancelled" || lead.attrs.conduitInstalled}
                        required={!lead.attrs.conduitInstalled}/>
                    <TimelineForm.Field
                        type="checkbox"
                        name="steps.scheduledFiberMeasurement"
                        label="Scheduled Fiber Measurement?"
                        value={lead.steps.scheduledFiberMeasurement}
                        description="Has the fiber measurement been scheduled?"
                        handleChange={handleChange}
                        checkLabel="Yes"
                        classes="mt-4"
                        disabled={lead.status === "Cancelled"}
                        required
                        />
                    <TimelineForm.Field
                        type="checkbox"
                        name="steps.updatedCustomer"
                        label="Updated Customer?"
                        value={lead.steps.updatedCustomer}
                        description="Has the customer been updated?"
                        handleChange={handleChange}
                        checkLabel="Yes"
                        classes="mt-4"
                        disabled={lead.status === "Cancelled"}
                        required
                        />
                </TimelineForm.Section>
                <TimelineForm.Section label="Step 3" canContinue={lead.steps.orderedDropCables}>
                    <TimelineForm.Field
                        type="checkbox"
                        name="steps.orderedDropCables"
                        label="Ordered Drop Cables?"
                        value={lead.steps.orderedDropCables}
                        description="Have the drop cables been ordered?"
                        handleChange={handleChange}
                        checkLabel="Yes"
                        classes="my-0"
                        disabled={lead.status === "Cancelled"}
                        />
                </TimelineForm.Section>
                <TimelineForm.Section label="Step 4" canContinue={lead.steps.verifiedEquipment}>
                    <TimelineForm.Field
                        type="checkbox"
                        name="steps.verifiedEquipment"
                        label="Verified Equipment?"
                        value={lead.steps.verifiedEquipment}
                        description="Has the equipment been verified?"
                        handleChange={handleChange}
                        checkLabel="Yes"
                        classes="my-0"
                        disabled={lead.status === "Cancelled"}
                        />
                </TimelineForm.Section>
                <TimelineForm.Section label="Step 5" canContinue={lead.steps.documentedCircuit}>
                    <TimelineForm.Field
                        type="text"
                        name="attrs.technical.switchPort"
                        label="Switch Port"
                        value={lead.attrs.technical.switchPort}
                        description="Please describe the switch port."
                        handleChange={handleChange}
                        onBlur={trimOnBlur}
                        classes="my-0"
                        disabled={lead.status === "Cancelled"}
                        required/>
                    <TimelineForm.Field
                        type="select"
                        options={[
                            {id: "DHCP", value: "DHCP"},
                            {id: "Static IP", value: "Static IP"},
                        ]}
                        name="attrs.technical.ipType"
                        label="IP Configuration"
                        value={lead.attrs.technical.ipType}
                        description="How does the customer get their IP information?"
                        handleChange={handleChange}
                        classes="mt-4"
                        excludeNoneSelected
                        disabled={lead.status === "Cancelled"}
                        required
                        />
                    <TimelineForm.Field
                        type="select"
                        options={[
                            {id: "DHCP Routable/Registered", value: "DHCP Routable/Registered"},
                            {id: "DHCP Nat'd", value: "DHCP Nat'd"},
                        ]}
                        name="attrs.technical.dhcp"
                        label={`${!lead.attrs.technical.dhcp ? "(N/A) " : ""}DHCP Distinction`}
                        value={lead.attrs.technical.dhcp}
                        description="How is the customer's DHCP configured?"
                        handleChange={handleChange}
                        classes="mt-4"
                        excludeNoneSelected
                        disabled={lead.status === "Cancelled" || lead.attrs.technical.ipType !== "DHCP"}
                        required={lead.attrs.ipType === "DHCP"}
                        />
                    <TimelineForm.Field
                        type="component"
                        name="attrs.technical.ips"
                        label="IP Addresses"
                        description={`The IP address(es) for the customer.${lead.attrs.technical.ipType === "Static IP" ? ' At least one is required.' : ' (Not needed with current IP Configuration)'}`}
                        classes="mt-4"
                        required={lead.attrs.technical.ipType === "Static IP"}
                        disabled={lead.status === "Cancelled" || lead.attrs.technical.ipType !== "Static IP"}>
                            <StrInterface strings={lead.attrs.technical.ips} basePath="attrs.technical.ips" placeholder="IP Address" addString={addString} deleteString={deleteString} handleChange={handleChange} onBlur={trimOnBlur}/>
                    </TimelineForm.Field>
                    <TimelineForm.Field
                        type="component"
                        name="attrs.technical.gateways"
                        label="Gateways"
                        description={`The gateway(s) for the customer.${lead.attrs.technical.ipType === "Static IP" ? ' At least one is required.' : ' (Not needed with current IP Configuration)'}`}
                        classes="mt-4"
                        required={lead.attrs.technical.ipType === "Static IP"}
                        disabled={lead.status === "Cancelled" || lead.attrs.technical.ipType !== "Static IP"}>
                            <StrInterface strings={lead.attrs.technical.gateways} basePath="attrs.technical.gateways" placeholder="Gateway" addString={addString} deleteString={deleteString} handleChange={handleChange} onBlur={trimOnBlur}/>
                    </TimelineForm.Field>
                    <TimelineForm.Field
                        type="component"
                        name="attrs.technical.subnetMasks"
                        label="Subnet Masks"
                        description={`The subnet mask(s) for the customer.${lead.attrs.technical.ipType === "Static IP" ? ' At least one is required.' : ' (Not needed with current IP Configuration)'}`}
                        classes="mt-4"
                        required={lead.attrs.technical.ipType === "Static IP"}
                        disabled={lead.status === "Cancelled" || lead.attrs.technical.ipType !== "Static IP"}>
                            <StrInterface strings={lead.attrs.technical.subnetMasks} basePath="attrs.technical.subnetMasks" placeholder="Subnet" addString={addString} deleteString={deleteString} handleChange={handleChange} onBlur={trimOnBlur}/>
                    </TimelineForm.Field>
                </TimelineForm.Section>
                <TimelineForm.Section label="Step 6" canContinue={lead.steps.scheduledInstallDate}>
                    <TimelineForm.Field
                        type="date"
                        name="attrs.installDate"
                        label="Fiber &amp; CPE Install Date"
                        value={lead.attrs.installDate}
                        description="The date on which the fiber &amp; and CPE will be installed."
                        handleChange={handleChange}
                        classes="my-0"
                        disabled={lead.status === "Cancelled"}
                        required/>
                </TimelineForm.Section>
                <TimelineForm.Section label="Step 7" canContinue={lead.steps.installed && lead.steps.tested}>
                    <TimelineForm.Field
                        type="checkbox"
                        name="steps.installed"
                        label="Installed?"
                        value={lead.steps.installed}
                        description="Has the fiber been installed?"
                        handleChange={handleChange}
                        checkLabel="Yes"
                        classes="my-0"
                        disabled={lead.status === "Cancelled"}
                        />
                    <TimelineForm.Field
                        type="checkbox"
                        name="steps.tested"
                        label="Tested?"
                        value={lead.steps.tested}
                        description="Has the fiber been tested?"
                        handleChange={handleChange}
                        checkLabel="Yes"
                        classes="mt-4"
                        disabled={lead.status === "Cancelled"}
                        />
                </TimelineForm.Section>
                <TimelineForm.Section label="Step 8" canContinue={lead.steps.billed} hidden={lead.status !== "Provisioned" && lead.status !== "Completed"}>
                    <TimelineForm.Field
                        type="component"
                        name="steps.billed"
                        label="Billed?"
                        description="Has the customer been billed?"
                        classes="my-0"
                        disabled={lead.status === "Cancelled"}>
                            <BillingCheck accountNumber={lead.attrs.createdCustomerAccountNumber} name="steps.billed" value={lead.steps.billed} handleChange={handleChange} checkLabel="Yes" required/>
                    </TimelineForm.Field>
                </TimelineForm.Section>
            </TimelineForm.Main>
            <TimelineForm.Sidebar>
                <div className="row">
                    <div className="col d-flex justify-content-center mx-2">
                        <div className={`alert alert-${statusColor(lead.status)} p-1 w-100`}>
                            {lead.status}
                        </div>
                    </div>
                </div>
                <div className="row mt-2">
                    <div className="col">
                        <button className="btn btn-primary w-75" onClick={handleSave} disabled={saveBtnDisabled || lead.status === "Cancelled"}>
                            <i className={saveBtnIcon}/>
                            &nbsp;{saveBtnLabel}
                        </button>
                    </div>
                </div>
                {lead.status !== "Provisioned" && lead.status !== "Completed" && lead.status !== "Cancelled" ? 
                <div className="mt-2">
                    <TimelineForm.Submit nSteps={7} label="Create Customer" icon="fas fa-plus" handleSubmit={handleSubmit} canSubmit={lead.status !== "Completed" && lead.status !== "Cancelled"}/>
                </div>
                :null}
                <div className="row mt-2">
                    <div className="col section-outline mx-2">
                        <NotesSection header="Notes" objectName="service order" refId={lead._id} notes={notes} setNotes={setNotes} noFilter/>
                    </div>
                </div>
                {lead.status !== "Cancelled" && lead.status !== "Completed" && lead.status !== "Provisioned" ? 
                <div className="row mt-2">
                    <div className="col">
                        <button className="btn btn-danger w-75" onClick={confirmCancel}>
                            <i className="fas fa-times"/>
                            &nbsp;Cancel SO
                        </button>
                    </div>
                </div>
                :null}
                {lead.status === "Cancelled" ?
                <div className="row mt-2">
                    <div className="col">
                        <button className="btn btn-danger w-75" onClick={confirmReopen}>
                            <i className="fas fa-plus"/>
                            &nbsp;Reopen Lead
                        </button>
                    </div>
                </div>
                :null}
            </TimelineForm.Sidebar>
        </TimelineForm>
        }
        </>
    )
}

export default ResidentialLeadForm;