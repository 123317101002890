import { useState, useEffect } from "react";

import useGet from "../hooks/useGet"
import { BannerLog } from "./BannerLogContext"
import ObjectTable from "./ObjectTable";

const RolodexBody = ({}) => {
    const [internetOrders] = useGet('/api/internetorders/query/rolodex', 'Internet Order');
    const [voipOrders] = useGet('/api/voiporders/query/rolodex', 'VoIP Order');
    const [mspOrders] = useGet('/api/msporders/query/rolodex', 'MSP Order');
    const [videoOrders] = useGet('/api/videoorders/query/rolodex', 'Video Order');
    const [customers] = useGet('/api/customers', 'Customer', {includeInactive: true});
    const [entries, setEntries] = useState(null);

    useEffect(() => {
        if(internetOrders !== null && voipOrders !== null && mspOrders !== null && videoOrders !== null && customers !== null) {
            let newEntries = [];
            const maps = [
                {arr: internetOrders, map: (order) => {return {number: order.number, id: order._id, type: 'Internet Order', customerName: order.customerName, status: order.status}}},
                {arr: voipOrders, map: (order) => {return {number: order.number, id: order._id, type: 'VoIP Order', customerName: order.customerName, status: order.status}}},
                {arr: mspOrders, map: (order) => {return {number: order.number, id: order._id, type: 'MSP Order', customerName: order.customerName, status: order.status}}},
                {arr: videoOrders, map: (order) => {return {number: order.number, id: order._id, type: 'Video Order', customerName: order.customer.name, status: order.status}}},
                {arr: customers, map: (customer) => {return {number: customer.accountNumber, id: customer._id, type: 'Customer', customerName: customer.displayName, status: customer.active ? 'Active' : 'Inactive'}}},
            ]
            for(const pair of maps) {
                newEntries = newEntries.concat(pair.arr.map(pair.map));
            }
            setEntries(newEntries);
        }
    }, [internetOrders, voipOrders, mspOrders, videoOrders, customers]);

    const onClick = (clickedEntry) => {
        const getLink = (type) => {
            switch(type) {
                case 'Internet Order':
                    return `/orders/internet/${clickedEntry.id}`;
                case 'VoIP Order':
                    return `/orders/voip/${clickedEntry.id}`;
                case 'MSP Order':
                    return `/orders/msp/${clickedEntry.id}`;
                case 'Video Order':
                    return `/orders/video/${clickedEntry.id}`;
                case 'Customer':
                    return `/customers/${clickedEntry.number}`;
                default:
                    return (_) => '';
            }
        } 
        window.open(getLink(clickedEntry.type), '_blank');
    }

    return (
        <ObjectTable 
            id="rolodexTable"
            cols={[
                {
                    label: '#',
                    sort: (a, b) => a.number < b.number ? -1 : 1,
                    render: (obj) => obj.number
                },
                {
                    label: 'Type', 
                    sort: (a, b) => {
                        const values = {
                            'Internet Order': 0,
                            'VoIP Order': 1,
                            'MSP Order': 2,
                            'Video Order': 3,
                            'Inactive Customer': 4,
                            'Active Customer': 5,
                        }
                        return values[`${a.type}`] < values[`${b.type}`] ? -1 : 1;
                    },
                    render: (obj) => obj.type,
                },
                {
                    label: 'Status', 
                    sort: (a, b) => {
                        const getValue = (obj) => {
                            switch(obj.status) {
                                case 'Active':
                                    return 0;
                                case 'Inactive':
                                    return 1;
                                case 'Completed':
                                    return 2;
                                case 'Cancelled':
                                    return 3;
                                case 'New':
                                    return 4;
                                case 'In-Progress':
                                    return 5;
                                case 'Delay':
                                case 'Delayed':
                                    return 6;
                                default:
                                    return 99;
                            }
                        }
                        if(getValue(a) < getValue(b)) return -1;
                        else if(getValue(a) > getValue(b)) return 1;
                        else return 0;
                    },
                    render: (obj) => obj.status,
                },
                {
                    label: 'Customer Name',
                    sort: (a, b) => a.customerName < b.customerName ? -1 : 1,
                    render: (obj) => obj.customerName
                },
            ]}
            objects={entries}
            filters={[
                {label: 'Everything', value: 'Default', func: (_) => true},
                {label: 'All Customers', value: 'All Customers', func: (obj) => obj.type === "Customer"},
                {label: 'Active Customers', value: 'Active Customers', func: (obj) => obj.type === "Customer" && obj.status === "Active"},
                {label: 'Inactive Customers', value: 'Inactive Customers', func: (obj) => obj.type === "Customer" && obj.status === "Inactive"},
                {label: 'All Orders', value: 'All Orders', func: (obj) => obj.type.endsWith('Order')},
                {label: 'Internet Orders', value: 'Internet Orders', func: (obj) => obj.type === "Internet Order"},
                {label: 'VoIP Orders', value: 'VoIP Orders', func: (obj) => obj.type === "VoIP Order"},
                {label: 'MSP Orders', value: 'MSP Orders', func: (obj) => obj.type === "MSP Order"},
                {label: 'Video Orders', value: 'Video Orders', func: (obj) => obj.type === "Video Order"},
            ]}
            onClicked={onClick}
            search
            paginated
            defaultSortByColName="Customer Name"
            defaultSortAscending={true}
        />
    )
}

const Rolodex = ({}) => {
    return (
        <div className="container pb-5">
            <div className="row">
                <div className="col">
                    <h1>Rolodex</h1>
                    <p>This page contains links to all Customer information ever entered (and not deleted) in Chimera, across all object types.</p>
                </div>
            </div>
            <BannerLog>
                <RolodexBody/>
            </BannerLog>
        </div>
    )
}

export default Rolodex;