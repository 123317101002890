import React, {useState, useEffect, useContext} from 'react';
import ModalContext from '../ModalContext';
import chimera from '../../chimera';
import InternetOrdersTable from './InternetOrdersTable';
import InternetOrderFormModal from './InternetOrderFormModal';
import BannerContext from '../BannerLogContext';
import { useParams, useNavigate } from 'react-router-dom';
import AlertsContext from '../AlertsContext';

const InternetTab = ({}) => {
    const [orders, setOrders] = useState(null);
    const [openOrderId, setOpenOrderId] = useState(null);
    const modaling = useContext(ModalContext);
    const banners = useContext(BannerContext);
    const alerts = useContext(AlertsContext);
    const navigate = useNavigate();
    const { id } = useParams();

    useEffect(() => {
        if(id) {
            setOpenOrderId(id);
        }
    }, [id]);

    useEffect(() => {
        if(openOrderId) {
            modaling.setModal(<InternetOrderFormModal orderId={openOrderId} modalContext={modaling} banners={banners} onClose={onModalClose}/>);
        }
    }, [openOrderId]);

    useEffect(() => {
        if(orders === null) {
            chimera.callAPI(undefined, '/api/internetorders')
            .then(newOrders => setOrders(newOrders))
            .catch(err => {
                console.error(err);
                if(banners) {
                    banners.addBanner('danger', 'Failed to read Internet Orders; they cannot be displayed.', 'Error');
                }
            })
        }
    }, [orders, banners])

    const onModalClose = () => {
        setOpenOrderId(null);
        setOrders(null);
        modaling.setModal(null);
        alerts.reload();
        navigate('/orders/internet');
    }

    return (<div>
        <div className="row">
            <div className="col">
                <InternetOrdersTable orders={orders} setOrders={setOrders} clickedOrder={(order) => navigate(`/orders/internet/${order._id}`)}/>
            </div>
        </div>
    </div>)
}

export default InternetTab;