import React, {useState, useLayoutEffect, useContext} from 'react';

import chimera from '../../chimera';
import LoadingSpinner from '../LoadingSpinner';
import BannerContext, {BannerLog} from '../BannerLogContext';
import ReportTable from '../ReportTable';
import FormField from '../FormField';

const CustomersAddedBody = ({}) => {
    const today = new Date();
    const todayStr = today.toISOString().substring(0, 10);
    const todayStrTokens = todayStr.split('-');
    const firstOfMonthStr = `${todayStrTokens[0]}-${todayStrTokens[1]}-01`;
    const [report, setReport] = useState(null);
    const [loading, setLoading] = useState(true);
    const [label, setLabel] = useState("");
    const [showForm, setShowForm] = useState(true);
    const [startDate, setStartDate] = useState(firstOfMonthStr);
    const [endDate, setEndDate] = useState(todayStr);
    const [saveBtnLabel, setSaveBtnLabel] = useState("Save to Chimera");
    const [saveBtnIcon, setSaveBtnIcon] = useState("fas fa-floppy-disk");
    const [saveBtnDisabled, setSaveBtnDisabled] = useState(false);
    const [controller] = useState(new AbortController());
    const [signal] = useState(controller.signal);
    const banners = useContext(BannerContext);

    useLayoutEffect(() => {
        return () => {
            controller.abort();
        }
    }, []);

    const handleChange = (event) => {
        event.preventDefault();
        const name = event.target.name;
        const value = event.target.value;
        if(name === "startDate") {
            setStartDate(value);
        }
        else if(name === "endDate") {
            if(value >= startDate) {
                setEndDate(value);
            }
            else {
                setEndDate(startDate);
            }
        }
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        setShowForm(false);
        setLabel("Reading Customers from Chimera...");
        chimera.CommercialCustomer.getByCreationDateRange(startDate, endDate, signal)
        .then(async customers => {
            const cols = [
                "Customer Name",
                "Date Created",
                "Monthly Estimated Revenue",
                "Annual Estimated Revenue",
            ]
            let rows = [];
            let etc = [];
            for(const customer of customers) {
                setLabel(`Reading QuickBooks data for ${customer.displayName}`);
                let estimated_monthly = 0.0;
                if(customer.integrationIds.quickbooks) {
                    try {
                        const recurringInvoices = await chimera.getRecurringTransactions(signal, customer.integrationIds.quickbooks, 'Invoice', 'all', false);
                        if(recurringInvoices.length === 0) {
                            etc.push(`${customer.displayName} has no recurring invoices in QuickBooks.`);
                        }
                        else {
                            for(const invoice of recurringInvoices) {
                                if(invoice.Invoice.RecurringInfo.Active || invoice.Invoice.RecurringInfo.Name.includes("BMS")) {
                                    estimated_monthly += invoice.Invoice.TotalAmt;
                                }
                            }
                        }
                    }
                    catch(err) {
                        if(err.name !== "AbortError") {
                            console.error(err);
                            banners.addBanner('danger', `An unhandled exception occurred while reading QuickBooks data for customer: ${customer.displayName}`, 'QuickBooks Error');
                            etc.push(`QuickBooks data for ${customer.displayName} could not be read due to an error. Their estimate revenue has been set to zero.`);
                        }
                        estimated_monthly = 0.0;
                    }
                }
                else {
                    etc.push(`${customer.displayName} has no QuickBooks integration. Their estimated revenue has been set to zero.`);
                }
                const createdDate = new Date(customer.createdAt);
                rows.push({
                    "Customer Name": customer.displayName,
                    "Date Created": createdDate,
                    "Monthly Estimated Revenue": chimera.dollarStr(estimated_monthly),
                    "Annual Estimated Revenue": chimera.dollarStr(estimated_monthly * 12),
                    "acctNum": customer.accountNumber
                });
            }
            if(etc.length > 0) {
                banners.addBanner('warning', 'Click on "More Info" to see warnings.', 'Warning');
            }
            setReport({type: "CustomersAdded", cols: cols, rows: rows, etc: etc});
            setLoading(false);
        })
        .catch(err => {
            if(err.name !== "AbortError") {
                console.error(err);
                banners.addBanner('danger', 'Failed to retrieve Customers from Chimera. The report cannot be generated.', 'Error');
            }
        })
    }

    const saveReport = (event) => {
        event.preventDefault();
        setSaveBtnDisabled(true);
        setSaveBtnIcon("fas fa-spinner");
        setSaveBtnLabel("Saving...");
        chimera.callAPI(signal, '/api/reporttables', 'POST', report)
        .then(_ => {
            setSaveBtnIcon("fas fa-check");
            setSaveBtnLabel("Saved!");
        })
        .catch(err => {
            if(err.name !== "AbortError") {
                console.error(err);
                banners.addBanner('danger', 'An error occurred and the report could not be saved', 'Error');
            }
            setSaveBtnDisabled(false);
            setSaveBtnIcon("fas fa-floppy-disk");
            setSaveBtnLabel("Save to Chimera");
        })
    }

    return (
        <>
        {showForm ?
            <form id="CustomersAddedForm" onSubmit={handleSubmit} noValidate>
                <FormField
                    name="startDate"
                    value={startDate}
                    handleChange={handleChange}
                    label="Start Date"
                    type="date"
                    description="The lower bound on the date range for the report (inclusive)."
                />
                <FormField
                    name="endDate"
                    value={endDate}
                    handleChange={handleChange}
                    label="End Date"
                    type="date"
                    description="The upper bound on the date range for the report (inclusive)."
                />
                <button className="btn btn-primary mb-3" type="submit">
                    <i className="fas fa-play"/>
                    &nbsp;Run Report
                </button>
            </form>
        :
            <>
            {loading ? 
                <>
                <LoadingSpinner size={75} label={label}/>
                </>
            :
                <>
                <button className="btn btn-primary mb-2" onClick={saveReport} disabled={saveBtnDisabled}>
                    <i className={saveBtnIcon}/>
                    &nbsp;{saveBtnLabel}
                </button>
                <ReportTable report={report}/>
                </>
            }
            </>
        }
        </>
    )
}

const CustomersAdded = props => {
    return (
        <BannerLog>
            <CustomersAddedBody/>
        </BannerLog>
    )
}

export default CustomersAdded;