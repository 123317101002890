import {useState, useEffect, useContext, useRef} from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import ModalContext from '../components/ModalContext';
import BannerContext from '../components/BannerLogContext';
import AlertsContext from '../components/AlertsContext';
import chimera from '../chimera';
import useSmoothFormModal from './useSmoothFormModal';

function useOrderTab({apiBase, noun, tabRoute, OrderFormModal}) {
    const [orders, setOrders] = useState(null);
    const modaling = useContext(ModalContext);
    const banners = useContext(BannerContext);
    const alerts = useContext(AlertsContext);
    const navigate = useNavigate();
    const { id } = useParams();
    const isModalOpen = useRef(false);
    const {setSavedObj, removeById} = useSmoothFormModal(orders, setOrders);

    useEffect(() => {
        if(orders === null) {
            chimera.callAPI(undefined, apiBase)
            .then(newOrders => setOrders(newOrders))
            .catch(err => {
                console.error(err);
                if(banners) {
                    banners.addBanner('danger', `Failed to read ${noun}s; they cannot be displayed.`, 'Error');
                }
                else {
                    alert(`ERROR: Failed to read ${noun}s; they cannot be displayed.`);
                }
            })
        }
    }, [orders, banners]);

    useEffect(() => {
        if(id && orders && !isModalOpen.current) {
            const order = orders.find(o => o._id === id);
            if(order) {
                // TODO: modalContext should be redundant, but it is needed for `choiceCancel()`. refactor pls
                modaling.setModal(<OrderFormModal order={order} modalContext={modaling} parentBanners={banners} onClose={onModalClose} parentSetSavedObj={setSavedObj} removeOrderById={removeById}/>);
                isModalOpen.current = true;
            }
            else {
                if(banners) {
                    banners.addBanner('danger', 'Could not open Order by URL', 'Error');
                }
                else {
                    alert('ERROR: Could not open Order by URL')
                }
                navigate(tabRoute);
            }
        }
    }, [id, orders]);

    const onModalClose = () => {
        //setOrders(null);
        modaling.setModal(null);
        alerts.reload();
        isModalOpen.current = false;
        navigate(tabRoute);
    }

    const onClick = (order) => {
        navigate(`${tabRoute}/${order._id}`);
    }

    return {
        orders,
        setOrders,
        onClick,
        banners
    }
}

export default useOrderTab;