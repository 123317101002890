import React, {useState, useContext, useRef} from 'react';
import Modal, {choiceCancel, choiceDelete} from '../components/Modal';
import ModalContext from '../components/ModalContext';
import LoadingSpinner from '../components/LoadingSpinner';
import chimera from '../chimera';

/**
 * Use of `setModified` and `setWorkingObj` are required for proper "Save & Close" functionality.
 */
function useFormModal({initialState, apiBase, noun, parentBanners, deleteNotifRule, removeById, onClose}) {
    const [workingObj, setWorkingObj] = useState(initialState);
    const [modified, setModified] = useState(false);
    const modaling = useContext(ModalContext);
    const ref = useRef();

    const handleSaveAndClose = (resolve, reject) => {
        const dismiss = (event) => {
            event.preventDefault();
            modaling.backtrack();
        }
        modaling.setModal(<Modal choices={[]} dismiss={dismiss}>
            <LoadingSpinner size={75} label="Saving..."/>
        </Modal>)

        if(ref.current) {
            ref.current.handleSaveAndClose()
            .then(_ => resolve())
            .catch(err => reject(err));
        }
        else {
            reject(new Error("ref not defined"));
        }
    }

    const saveAndCloseResolve = () => {
        if(onClose) onClose();
        modaling.setModal(null);
    }

    const saveAndCloseReject = (err) => {
        console.error(err);
        modaling.backtrack();
        modaling.backtrack();
    }

    const choices = [
        choiceCancel({modalContext: modaling, onClose}, modified, <>
            <h3>You have unsaved changes</h3>
            <p>Are you sure you want to close this form? Your changes will not be saved.</p>
        </>, 'Close', {func: handleSaveAndClose, resolve: saveAndCloseResolve, reject: saveAndCloseReject})
    ]

    if(workingObj && workingObj._id) {
        choices.push(choiceDelete({modalContext: modaling}, () => {
            modaling.setModal(<Modal choices={[]} dismiss={(e) => {e.preventDefault(); modaling.setModal(null)}}>
                <LoadingSpinner size={75}/>
            </Modal>);
            chimera.callAPI(undefined, `${apiBase}/${workingObj._id}`, 'DELETE')
            .then(_ => {
                if(parentBanners) {
                    // deleteNotifRule(workingObj) => 'Project #N has been deleted.'
                    parentBanners.addBanner('info', `${deleteNotifRule ? deleteNotifRule(workingObj) : `The ${noun} has been deleted.`}`, 'Success');
                }
            })
            .catch(err => {
                console.error(err);
                if(parentBanners) {
                    parentBanners.addBanner('danger', `Failed to delete ${noun}`, 'Error');
                }
                else {
                    alert(`Error: Failed to delete ${noun}`);
                }
            })
            .finally(() => {
                if(removeById && workingObj && workingObj._id) {
                    removeById(workingObj._id);
                }
                if(onClose) onClose();
                modaling.setModal(null);
            })
        }, {label: 'Delete Permanently'}))
    }

    return {
        workingObj,
        setWorkingObj,
        modified,
        setModified,
        choices,
        ref
    }
}

export default useFormModal;