import useOrderTab from '../../../hooks/useOrderTab';
import VideoOrdersTable from './VideoOrdersTable';
import VideoOrderFormModal from './VideoOrderFormModal';

const VideoTab = ({}) => {
    const {orders, setOrders, banners, onClick} = useOrderTab({
        apiBase: '/api/videoorders',
        noun: 'Video Order',
        tabRoute: '/orders/video',
        OrderFormModal: VideoOrderFormModal
    })

    return (<VideoOrdersTable orders={orders} setOrders={setOrders} clickedOrder={onClick} parentBanners={banners}/>);
}

export default VideoTab;