import { useState, useContext } from "react";

import ModalContext from "../components/ModalContext";
import BannerContext, {BannerLog} from "../components/BannerLogContext";
import Modal from "../components/Modal";
import LoadingSpinner from "../components/LoadingSpinner";
import { NewCustomerBody } from "../components/NewCustomer";
import chimera from "../chimera";

function usePushToCustomer(workingObj, handleSave) {
    const [selectedCustomer, setSelectedCustomer] = useState(null);
    const modaling = useContext(ModalContext);
    const banners = useContext(BannerContext);

    const trimCustomer = (cust, includeKeyFunc) => {
        let ret = {};
        const excludeKeys = [
            "_id", "__v", "createdAt", "createdBy",
            "modifiedBy", "updatedAt", "accountNumber"
        ];
        for(const key in cust) {
            if(!excludeKeys.includes(key) && (includeKeyFunc === undefined || includeKeyFunc(key))) {
                ret[key] = cust[key];
            }
        }
        return ret;
    }

    const pushToCustomer = (order, mapFunc) => {
        return () => new Promise(async(resolve, reject) => {
            const controller = new AbortController();
            const signal = controller.signal;
            const dismiss = (event) => {
                event.preventDefault();
                controller.abort();
                modaling.setModal(null);
            }

            let customer;
            let locationIndex;
            if(order.type === "New Customer") {
                locationIndex = 0;
                customer = JSON.parse(JSON.stringify(chimera.DEFAULT_CUSTOMER));
                customer.locations[0].nickname = '1';
            }
            else {
                if(selectedCustomer) {
                    customer = JSON.parse(JSON.stringify(selectedCustomer));
                }
                else {
                    try {
                        customer = await chimera.callAPI(signal, `/api/customers/accountNumber/${order.customer.ref}`);
                    }
                    catch(err) {
                        console.error(err);
                        if(err.name !== "AbortError") {
                            if(banners) {
                                banners.addBanner('danger', 'Failed to read Customer; cannot complete "Push to Customer"', 'Error');
                            }
                            else {
                                alert('ERROR: Failed to read Customer; cannot complete "Push to Customer"');
                            }
                        }
                        reject(err);
                    }
                }

                locationIndex = -1;
                if(order.customer.locationRef === 'ADD_NEW_LOCATION') {
                    locationIndex = customer.locations.length;
                    customer.locations = [...customer.locations, chimera.DEFAULT_LOCATION];
                    customer.locations[locationIndex].nickname = order.customer.newLocationNickname;
                    customer.locationIndex = locationIndex;
                }
                else {
                    locationIndex = customer.locations.findIndex(loc => loc._id === order.customer.locationRef);
                    if(locationIndex == -1) {
                        if(banners) {
                            banners.addBanner('danger', 'Invalid location reference. This is peculiar - please notify the developer.', 'Error');
                        }
                        else {
                            alert('ERROR: Invalid location reference. This is peculiar - please notify the developer.');
                        }
                        reject();
                    }
                }
            }

            mapFunc(customer, locationIndex);

            if(!order.customer.ref) {
                const createdCustomerCallback = (createdCustomer) => {
                    
                    const newWorkingObj = JSON.parse(JSON.stringify(workingObj));
                    newWorkingObj.customer.name = createdCustomer.displayName;
                    newWorkingObj.customer.ref = createdCustomer.accountNumber;
                    newWorkingObj.customer.qbId = createdCustomer.integrationIds.quickbooks;
                    
                    handleSave(newWorkingObj)
                    .then(_ => {modaling.backtrack(); resolve()})
                    .catch(e => {modaling.backtrack(); reject(e)})
                }
        
                const modal = <Modal choices={[]} dismiss={dismiss}>
                    <BannerLog>
                        <NewCustomerBody customer={new chimera.CommercialCustomer(customer, locationIndex)} controller={controller} signal={signal} createdCustomerCallback={createdCustomerCallback} hideIPTable/>
                    </BannerLog>
                </Modal>
                modaling.setModal(modal);
            }
            else {

                if(chimera.customerHasNoActiveServiceTypes(customer) && customer.active) {
                    try {
                        customer = await chimera.promptToggleInactive(customer, modaling);
                    }
                    catch(err) {
                        console.error(err);
                        reject(err);
                    }
                }

                const loadingModal = <Modal choices={[]} dismiss={dismiss}>
                    <BannerLog>
                        <LoadingSpinner size={50} label={`Pushing to ${customer.displayName}...`}/>
                    </BannerLog>
                </Modal>
                modaling.setModal(loadingModal);
    
                chimera.callAPI(signal, `/api/customers/${customer._id}`, 'PUT', trimCustomer(customer))
                .then(_ => {
                    modaling.backtrack();
                    if(banners) {
                        banners.addBanner('info', `Saved changes to location ${customer.locations[locationIndex].nickname} for ${customer.displayName} in Chimera.`, 'Success');
                    }
                    resolve();
                })
                .catch(err => {
                    console.error(err);
                    modaling.backtrack();
                    if(err.details.name === "ValidationError") {
                        if(banners) {
                            for(const key in err.details.errors) {
                                const error = err.details.errors[key];
                                if(error.path === "billingPhone") {
                                    banners.addBanner('danger', `The Billing POC's Phone Number is required because it is used as the location's Billing Phone, a required field.`, 'Validation Error');
                                }
                                else {
                                    banners.addBanner('danger', error.message, 'Validation Error');
                                }
                            }
                        }
                        else {
                            alert("ERROR: Validation error");
                        }
                    }
                    else {
                        if(banners) {
                            banners.addBanner('danger', 'Failed to update the customer.', 'Error');
                        }
                        else {
                            alert("ERROR: Failed to update the customer");
                        }
                    }
                    reject(err);
                })
            }
        })
    }

    return {
        pushToCustomer,
        selectedCustomer,
        setSelectedCustomer,
    }
}

export default usePushToCustomer;