import React, {useState, useEffect, useLayoutEffect, useContext} from 'react';

import chimera from '../chimera';
import BannerContext, { BannerLog } from './BannerLogContext';
import FormField from './FormField';
import PocDisplay from './PocDisplay';
import AddressDisplay from './AddressDisplay';
import ModalContext from './ModalContext';
import Modal from './Modal';

const PocList = props => {
    return (
        <div>
            {props.pocs.map((poc, i) => 
                <div className={i !== props.pocs.length - 1 ? "section-outline mb-4" : "section-outline"}>
                    <PocDisplay poc={poc} basePath={`${props.basePath}[${i}]`} baseValue={props.baseValue} onChange={props.handleChange} disabled={props.disabled} isEditing handleDelete={(event) => {event.preventDefault(); props.handleDelete(i)}} onBlur={props.onBlur} series/>
                </div>
            )}
            <button className="btn btn-success mt-2 mb-2" onClick={props.addPoc} disabled={props.disabled}>
                <i className="fas fa-plus"/>
            </button>
        </div>
    )
}

const NewResidentialCustomerBody = props => {
    const [customer, setCustomer] = useState(props.customer ? props.customer : {
        type: '',
        plan: 'NOT SET',
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        homeAddress: {
            street1: '',
            street2: '',
            city: '',
            state: 'WA',
            county: '',
            zip: ''
        },
        mailAddress: {
            street1: '',
            street2: '',
            city: '',
            state: 'WA',
            county: '',
            zip: ''
        },
        mailSameAsHome: false,
        authorizedUsers: [],
        serviceTypes: {
            internet: false,
            voip: false
        },
        technical: {
            switchPort: '',
            patchPanelPort: '',
            bandwidth: '',
            ipType: 'NOT SET',
            dhcp: 'DHCP Routable/Registered',
            ips: [],
            gateways: [],
            subnetMasks: [],
            cpe: '',
            cpeIp: '',
            demarc: '',
            voipLines: 0
        },
        standing: 'good'
    });
    const [isSaving, setIsSaving] = useState(false);
    const [saveBtnLabel, setSaveBtnLabel] = useState("Create Customer");
    const [willSyncro, setWillSyncro] = useState(true);
    const [willQb, setWillQb] = useState(true);
    const [willUnity, setWillUnity] = useState(false);
    const [unityDomain, setUnityDomain] = useState("");
    const [controller, setController] = useState(props.controller ? props.controller : new AbortController());
    const [signal, setSignal] = useState(props.signal ? props.signal : controller.signal);
    const banners = useContext(BannerContext);
    const modaling = useContext(ModalContext);

    useLayoutEffect(() => {
        return () => {
            controller.abort();
        }
    }, []);

    useEffect(() => {
        if(!isSaving) {
            setSaveBtnLabel("Create Customer");
        }
    }, [isSaving]);

    const handleChange = event => {
        if(event.target.type !== "checkbox") event.preventDefault();
        const name = event.target.name;
        const value = event.target.value;

        let newCustomer = JSON.parse(JSON.stringify(customer));

        if(customer.mailSameAsHome) {
            const newAddress = newCustomer.homeAddress;
            newCustomer.mailAddress = newAddress;
        }

        if(name === "platformCheckSyncro") {
            setWillSyncro(event.target.checked);
        }
        else if(name === "platformCheckQb") {
            setWillQb(event.target.checked);
        }
        else if(name === "platformCheckUnity") {
            setWillUnity(event.target.checked);
        }
        else if(name === "unityDomain") {
            setUnityDomain(value.replaceAll(/\s/g, ''));
        }
        else if(name === "mailSameAsHome") {
            newCustomer.mailSameAsHome = event.target.checked;
            if(event.target.checked) {
                const newAddress = newCustomer.homeAddress;
                newCustomer.mailAddress = newAddress;
            }
        }
        else if(event.target.type === "checkbox") {
            chimera.setAttr(newCustomer, name, event.target.checked);
        }
        else if(event.target.type === "number" && name.includes("voipLines")) {
            chimera.setAttr(newCustomer, name, parseInt(value));
        }
        else if(event.target.type === "number") {
            chimera.setAttr(newCustomer, name, parseFloat(value));
        }
        else {
            if(name.toLowerCase().includes("phone")) {
                chimera.setAttr(newCustomer, name, value.replace(/\D/g, ''));
            }
            else {
                chimera.setAttr(newCustomer, name, value);
            }
        }

        if(name === "serviceTypes.voip") {
            setWillUnity(event.target.checked);
        }

        if(newCustomer.technical.ipType !== "Static IP") {
            newCustomer.technical.ips = [];
            newCustomer.technical.gateways = [];
            newCustomer.technical.subnetMasks = [];
        }

        setCustomer(newCustomer);
    }

    const handleSubmit = async(event) => {
        event.preventDefault();
        setSaveBtnLabel("Creating...");
        setIsSaving(true);
        banners.clearBanners();

        // Validate the customer
        const valid = await chimera.validateCustomer(signal, customer, banners, true);
        if(!valid) {
            setIsSaving(false);
            return;
        }

        // Create the customer
        try {
            console.log(customer);
            const createdCustomer = await chimera.createCustomer(signal, customer, banners, {
                residential: true,
                willSyncro: willSyncro,
                willQb: willQb,
                willUnity: willUnity,
                unityDomain: unityDomain,
                startSleepingCallback: () => {setSaveBtnLabel("Waiting...")},
                stopSleepingCallback: () => {setSaveBtnLabel("Creating...")},
                cleanupCallback: () => {setIsSaving(false)}
            });
            props.createdCustomerCallback ? props.createdCustomerCallback(createdCustomer) : window.open(`/residential/${createdCustomer.accountNumber}`, '_self');
            return;
        }
        catch(e) {
            console.error(e);
            banners.addBanner('danger', 'Failed to create customer', 'Error');
            return;
        }
    }

    const addPoc = (event) => {
        event.preventDefault();
        let newPocs = [];
        const pocs = chimera.getAttr(customer, 'authorizedUsers');
        for(const poc of pocs) {
            newPocs.push(poc);
        }
        newPocs.push({
            firstName: "",
            lastName: "",
            email: "",
            phone: ""
        });

        handleChange({
            target: {
                type: "Array",
                name: 'authorizedUsers',
                value: newPocs
            },
            preventDefault: () => {}
        })
    }

    const deletePoc = (index) => {
        let newPocs = [];
        const pocs = chimera.getAttr(customer, 'authorizedUsers');
        for(let i = 0; i < pocs.length; i++) {
            if(i !== index) {
                newPocs.push(pocs[i]);
            }
        }

        handleChange({
            target: {
                type: "Array",
                name: 'authorizedUsers',
                value: newPocs
            },
            preventDefault: () => {}
        })
    }

    const addString = (basePath) => {
        let newStrings = [];
        const strings = chimera.getAttr(customer, basePath);
        for(const str of strings) {
            newStrings.push(str);
        }
        newStrings.push("");

        handleChange({
            target: {
                type: "Array",
                name: basePath,
                value: newStrings
            },
            preventDefault: () => {}
        });
    }

    const deleteString = (basePath, index) => {
        let newStrings = [];
        const strings = chimera.getAttr(customer, basePath);
        for(let i = 0; i < strings.length; i++) {
            if(i !== index)
                newStrings.push(strings[i]);
        }

        handleChange({
            target: {
                type: "Array",
                name: basePath,
                value: newStrings
            },
            preventDefault: () => {}
        });
    }

    const trimOnBlur = (event) => {
        handleChange({
            target: {
                type: "string",
                name: event.target.name,
                value: event.target.value.trim()
            },
            preventDefault: () => {}
        })
    }

    const strInterface = (basePath, label, placeholder, description) => {
        const strings = chimera.getAttr(customer, basePath);
        return(
            <div className="row justify-content-center">
                <div className="col-10 col-sm-8 col-md-6 col-lg-5 col-xl-4">
                    <div className="form-field">
                        <div className="form-label">{customer.technical.ipType === "Static IP" ? <><span className="red">*</span>&nbsp;</> : null}{label}:</div>
                        {strings.map((str, i) => 
                            <div className="mb-1 d-flex flex-row" key={i}>
                                <input className="form-control" type="text" name={`${basePath}[${i}]`} value={str} placeholder={placeholder} onChange={handleChange} disabled={isSaving || customer.technical.ipType !== "Static IP"} onBlur={trimOnBlur}/>
                                <button className="btn btn-danger ms-2" onClick={(event) => {event.preventDefault(); deleteString(basePath, i)}}><i className="fas fa-minus"/></button>
                            </div>
                        )}
                        <button className="btn btn-success" onClick={(event) => {event.preventDefault(); addString(basePath)}} disabled={customer.technical.ipType !== "Static IP"}>
                            <i className="fas fa-plus"/>
                        </button>
                        <div className="form-text">
                            {description}
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    return (
        <form id="newResidentialCustomerForm" onSubmit={handleSubmit} noValidate>
            <div className="row">
                <div className="col">
                    <FormField
                        type="component"
                        name="poc"
                        label="Primary Resident"
                        description="Name and contact details for the primary resident."
                        disabled={isSaving}
                        required>
                            <PocDisplay poc={{firstName: customer.firstName, lastName: customer.lastName, phone: customer.phone, email: customer.email}} basePath="" baseValue={customer} onChange={handleChange} isEditing onBlur={trimOnBlur}/>
                    </FormField>
                </div>
            </div>
            <div className="row=">
                <div className="col">
                    <FormField
                        type="select"
                        options={[
                            {id: "Ederra", value: "Ederra"},
                            {id: "Timberline", value: "Timberline"},
                            {id: "Skyline Ridge", value: "Skyline Ridge"}
                        ]}
                        name="type"
                        label="Zone"
                        value={customer.type}
                        description="The development, neighborhood, or area being serviced."
                        handleChange={handleChange}
                        disabled={isSaving}
                        required
                    />
                </div>
            </div>
            <div className="row=">
                <div className="col">
                    <FormField
                        type="select"
                        options={[
                            {id: "Plan A", value: "Plan A (Dynamic IP)"},
                            {id: "Plan B", value: "Plan B (Static IP)"},
                        ]}
                        name="plan"
                        label="Plan"
                        value={customer.plan}
                        description="The plan the customer is requesting."
                        handleChange={handleChange}
                        disabled={isSaving}
                        required
                    />
                </div>
            </div>
            <div className="row">
                <div className="col">
                    <FormField
                        type="component"
                        name="homeAddress"
                        label="Home Address"
                        description="The physical location of the residence."
                        disabled={isSaving}
                        required>
                            <AddressDisplay addr={customer.homeAddress} basePath="homeAddress" baseValue={customer} onChange={handleChange} isEditing onBlur={trimOnBlur}/>
                    </FormField>
                </div>
            </div>
            <div className="row">
                <div className="col">
                    <FormField
                        type="component"
                        name="mailAddress"
                        label="Mail Address"
                        description="The address for sending bills or other mail."
                        disabled={isSaving}
                        required>
                            <AddressDisplay addr={customer.mailAddress} basePath="mailAddress" baseValue={customer} onChange={handleChange} isEditing onBlur={trimOnBlur} sameAsLabel="Same As Home Address" sameAsPath="mailSameAsHome" sameAs={customer.mailSameAsHome}/>
                    </FormField>
                </div>
            </div>
            <div className="row">
                <div className="col">
                    <FormField
                        type="component"
                        name="authorizedUsers"
                        label="(Optional) Additional Authorized Users"
                        description="A list of people other than the primary resident that may request privileged actions for this customer. For any additional authorized users, only the first and last name are required."
                        disabled={isSaving}>
                            <PocList pocs={customer.authorizedUsers} basePath="authorizedUsers" baseValue={customer} handleChange={handleChange} handleDelete={deletePoc} addPoc={addPoc} onBlur={trimOnBlur}/>
                    </FormField>
                </div>
            </div>
            <div className="row">
                <div className="col">
                    <div className="form-field">
                        <span className="form-label"><span className="red">*</span>&nbsp;Service Types:</span>
                        <div>
                            <div className="form-check form-check-inline">
                                <input className="form-check-input" type="checkbox" name="serviceTypes.internet" id="internetService" checked={customer.serviceTypes.internet} onChange={handleChange} disabled={isSaving}/>
                                <label className="form-check-label" htmlFor="internetService">
                                    Internet
                                </label>
                            </div>
                            <div className="form-check form-check-inline">
                                <input className="form-check-input" type="checkbox" name="serviceTypes.voip" id="voipService" checked={customer.serviceTypes.voip} onChange={handleChange} disabled={isSaving}/>
                                <label className="form-check-label" htmlFor="voipService">
                                    VoIP
                                </label>
                            </div>
                        </div>
                        <span className="form-text">The services to be provided to the customer.</span>
                    </div>
                </div>
            </div>
            <div className="form-field">
                <div className="row">
                    <div className="col">
                        <div className="form-label">Create Records:</div>
                        <div className="form-check form-check-inline">
                            <input className="form-check-input" type="checkbox" name="platformCheckSyncro" id="syncroCheck" checked={willSyncro} onChange={handleChange}/>
                            <label className="form-check-label" htmlFor="syncroCheck">
                                Syncro
                            </label>
                        </div>
                        <div className="form-check form-check-inline">
                            <input className="form-check-input" type="checkbox" name="platformCheckQb" id="qbCheck" checked={willQb} onChange={handleChange}/>
                            <label className="form-check-label" htmlFor="qbCheck">
                                QuickBooks
                            </label>
                        </div>
                        <div className="form-check form-check-inline">
                            <input className="form-check-input" type="checkbox" name="platformCheckUnity" id="unityCheck" checked={willUnity && customer.serviceTypes.voip} onChange={handleChange} disabled={!customer.serviceTypes.voip}/>
                            <label className="form-check-label" htmlFor="unityCheck">
                                Unity
                            </label>
                        </div>
                    </div>
                </div>
                <div className="row justify-content-center">
                    <div className="col-10 col-sm-8 col-md-6 col-lg-5 col-xl-4">
                        <div className="form-text">
                            This section shows what records will be created in integrated platforms to
                            correspond with the new customer. You may toggle them off, but note that the customer
                            will fail to integrate with that platform unless you manually configure it later.
                        </div>
                    </div>
                </div>
            </div>
            {customer.serviceTypes.internet ? 
            <>
            <div className="row">
                <div className="col">
                    <FormField
                        type="text"
                        name="technical.switchPort"
                        label="Switch Port"
                        value={customer.technical.switchPort}
                        description="Anything that needs to be known about the switch port."
                        handleChange={handleChange}
                        onBlur={trimOnBlur}
                        disabled={isSaving}
                        required/>
                </div>
            </div>
            <div className="row">
                <div className="col">
                    <FormField
                        type="text"
                        name="technical.patchPanelPort"
                        label="Patch Panel Port"
                        value={customer.technical.patchPanelPort}
                        description="Anything that needs to be known about the patch panel port."
                        handleChange={handleChange}
                        onBlur={trimOnBlur}
                        disabled={isSaving}
                        required/>
                </div>
            </div>
            <div className="row=">
                <div className="col">
                    <FormField
                        type="select"
                        options={[
                            {id: "500 Mbps", value: "500 Mbps"},
                            {id: "1 Gbps", value: "1 Gbps"}
                        ]}
                        name="technical.bandwidth"
                        label="Bandwidth"
                        value={customer.technical.bandwidth}
                        description="The customer's Internet speed."
                        handleChange={handleChange}
                        disabled={isSaving}
                        required
                    />
                </div>
            </div>
            <div className="row=">
                <div className="col">
                    <FormField
                        type="select"
                        options={[
                            {id: "DHCP", value: "DHCP"},
                            {id: "Static IP", value: "Static IP"}
                        ]}
                        name="technical.ipType"
                        label="IP Configuration"
                        value={customer.technical.ipType}
                        description="How is the customer's IP determined?"
                        handleChange={handleChange}
                        disabled={isSaving}
                        required
                    />
                </div>
            </div>
            {customer.technical.ipType === "DHCP" ?
                <div className="row">
                    <div className="col">
                        <FormField
                            type="select"
                            options={[
                                {id: "DHCP Routable/Registered", value: "DHCP Routable/Registered"},
                                {id: "DHCP Nat'd", value: "DHCP Nat'd"}
                            ]}
                            name="technical.dhcp"
                            label="DHCP Distinction"
                            value={customer.technical.dhcp}
                            description="How is the customer's DHCP configured?"
                            handleChange={handleChange}
                            disabled={isSaving}
                            excludeNoneSelected
                            required
                        />
                    </div>
                </div>
            :null}
            {strInterface("technical.ips", "IPs", "IP Address", `A list of associated IP addresses.${customer.technical.ipType === "Static IP" ? ' At least one is required.' : ' (Not required with current IP Configuration)'}`)}
            {strInterface("technical.gateways", "Gateways", "Gateway", `A list of associated gateways.${customer.technical.ipType === "Static IP" ? ' At least one is required.' : ' (Not required with current IP Configuration)'}`)}
            {strInterface("technical.subnetMasks", "Subnet Masks", "Subnet", `A list of associated subnet masks.${customer.technical.ipType === "Static IP" ? ' At least one is required.' : ' (Not required with current IP Configuration)'}`)}
            <div className="row">
                <div className="col">
                    <FormField
                        type="text"
                        name="technical.cpe"
                        label="CPE"
                        value={customer.technical.cpe}
                        description="A description of the Customer Premise Equipment."
                        handleChange={handleChange}
                        onBlur={trimOnBlur}
                        disabled={isSaving}/>
                </div>
            </div>
            <div className="row">
                <div className="col">
                    <FormField
                        type="text"
                        name="technical.cpeIp"
                        label="CPE IP Address"
                        value={customer.technical.cpeIp}
                        description="The IP address of the CPE."
                        handleChange={handleChange}
                        onBlur={trimOnBlur}
                        disabled={isSaving}/>
                </div>
            </div>
            <div className="row">
                <div className="col">
                    <FormField
                        type="text"
                        name="technical.demarc"
                        label="Demarc Location"
                        value={customer.technical.demarc}
                        description="The point of demarcation."
                        handleChange={handleChange}
                        onBlur={trimOnBlur}
                        disabled={isSaving}/>
                </div>
            </div>
            </>
            :null}
            {customer.serviceTypes.voip ?
            <>
            {willUnity ?
                <div className="row">
                    <div className="col">
                        <FormField
                            type="text"
                            name="unityDomain"
                            label="Unity Domain"
                            value={unityDomain}
                            description="The new Domain in Unity to be tied to this customer."
                            handleChange={handleChange}
                            onBlur={trimOnBlur}
                            disabled={isSaving}
                            required/>
                    </div>
                </div>
            :null}
            <div className="row">
                <div className="col">
                    <FormField
                        type="number"
                        name="technical.voipLines"
                        label="VoIP Lines"
                        value={customer.technical.voipLines}
                        description="The maximum number of concurrent calls the customer can have."
                        handleChange={handleChange}
                        min={0}
                        step={1}
                        disabled={isSaving}
                        required/>
                </div>
            </div>
            </>
            :null}
            <div className="row">
                <div className="col">
                    <button className="btn btn-primary" type="submit" disabled={isSaving || (!customer.serviceTypes.internet && !customer.serviceTypes.voip)}>
                        <i className={isSaving ? "fas fa-spinner" : "fas fa-plus"}/>
                        &nbsp;{saveBtnLabel}
                    </button>
                </div>
            </div>
        </form>
    )
}

const NewResidentialCustomer = props => {
    return (
        <div className="container pb-5">
            <h1>Add Residential Customer</h1>
            <BannerLog>
                <NewResidentialCustomerBody/>
            </BannerLog>
        </div>
    )
}

export { NewResidentialCustomer as default, NewResidentialCustomerBody };